const SERVER_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SERVER_URL : 'http://127.0.0.1:5000'

/********************
* Areas' functions
*/ 

export const getAllAreas = async () => {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }
    const response = await fetch(`${SERVER_URL}/admin/areas`, requestOptions);
    return await response.json();
}

export const addNewArea = async (data) => {
    const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    }
    const response = await fetch(`${SERVER_URL}/admin/areas/`, requestOptions);
    return await response.json();
}

/********************
* Users' functions
*/ 

export const getAllUsers = async () => {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }
    const response = await fetch(`${SERVER_URL}/admin/users`, requestOptions);
    return await response.json();
}

export const addNewUser = async (data) => {
    const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    }
    const response = await fetch(`${SERVER_URL}/admin/users/`, requestOptions);
    return await response.json();
}

/********************
* User Roles' functions
*/ 

export const getAllUserRoles = async () => {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }
    const response = await fetch(`${SERVER_URL}/admin/roles`, requestOptions);
    return await response.json();
}

export const addNewUserRole = async (data) => {
    const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    }
    const response = await fetch(`${SERVER_URL}/admin/roles/`, requestOptions);
    return await response.json();
}