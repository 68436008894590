import React, { useContext } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
// Import contexts
import { CurrentUserContext } from 'containers/App'

const PrivateRoute = ({children, ...props}) => {
    // Load current user state from context
    const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
    // Get current location
    const location = useLocation();
    
    if (!currentUser) {
        // If current user state doesn't exist, redirect to login page
        return (
            <Redirect 
                to={{
                    pathname: '/auth/login',
                    state: { from: location }
                }}
            />
        )
    } else {
        return (
            <Route {...props}>
                {children}
            </Route>
        )
    }

}

export default PrivateRoute;