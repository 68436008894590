/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button,
  Modal
} from "reactstrap";

function AuthNavbar({
    requestAccessModal, setRequestAccessModal
}) {

    // Handlers
    const handleRequestAccessModal = (e) => {
        e.preventDefault();
        setRequestAccessModal(true);
    }

    return (
    <>
        <Navbar
            className="navbar-horizontal navbar-main navbar-dark navbar-transparent"
            expand="lg"
            id="navbar-main"
        >
            <Container>
                <NavbarBrand to="/" tag={Link}>
                <img
                    alt="..."
                    src={require("assets/img/brand/hypatia-logo-white.png")}
                />
                </NavbarBrand>
                <button
                    aria-controls="navbar-collapse"
                    aria-expanded={false}
                    aria-label="Toggle navigation"
                    className="navbar-toggler"
                    data-target="#navbar-collapse"
                    data-toggle="collapse"
                    id="navbar-collapse"
                    type="button"
                >
                    <span className="navbar-toggler-icon" />
                </button>
                <UncontrolledCollapse
                    className="navbar-custom-collapse"
                    navbar
                    toggler="#navbar-collapse"
                >
                    <div className="navbar-collapse-header">
                        <Row>
                        <Col className="collapse-brand" xs="6">
                            <Link to="/admin/dashboard">
                                <img alt="..." src={require("assets/img/brand/hypatia-logo.png")} />
                            </Link>
                        </Col>
                        <Col className="collapse-close" xs="6">
                            <button
                                aria-controls="navbar-collapse"
                                aria-expanded={false}
                                aria-label="Toggle navigation"
                                className="navbar-toggler"
                                data-target="#navbar-collapse"
                                data-toggle="collapse"
                                id="navbar-collapse"
                                type="button"
                            >
                                <span />
                                <span />
                            </button>
                        </Col>
                        </Row>
                    </div>
                    <Nav className="mr-auto" navbar>
                        {/* <NavItem>
                            <NavLink to="/auth/login" tag={Link}>
                                <span className="nav-link-inner--text">Ingresar</span>
                            </NavLink>
                        </NavItem> */}
                        {/* <NavItem>
                            <NavLink to="/auth/register" tag={Link}>
                                <span className="nav-link-inner--text">Registrarse</span>
                            </NavLink>
                        </NavItem> */}
                    </Nav>
                    <hr className="d-lg-none" />
                    <Nav className="align-items-lg-center ml-lg-auto" navbar>
                        <NavItem>
                            <NavLink
                                className="nav-link-icon"
                                href="https://www.facebook.com/HYPATIAPERU"
                                id="tooltipFB"
                                target="_blank"
                            >
                                <i className="fab fa-facebook-square" />
                                <span className="nav-link-inner--text d-lg-none">
                                    Facebook
                                </span>
                            </NavLink>
                            <UncontrolledTooltip delay={0} target="tooltipFB">
                                Hypatia S.A. en Facebook
                            </UncontrolledTooltip>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className="nav-link-icon"
                                href="https://www.linkedin.com/company/hypatia-s-a/"
                                id="tooltipLI"
                                target="_blank"
                            >
                                <i className="fab fa-linkedin" />
                                <span className="nav-link-inner--text d-lg-none">
                                    LinkedIn
                                </span>
                            </NavLink>
                            <UncontrolledTooltip delay={0} target="tooltipLI">
                                Hypatia S.A. en LinkedIn
                            </UncontrolledTooltip>
                        </NavItem>
                        <NavItem className="d-lg-block ml-lg-4">
                            <Button
                                className="btn-neutral btn-icon"
                                color="default"
                                target="_blank"
                                onClick={handleRequestAccessModal}
                            >
                                <span className="btn-inner--icon">
                                    <i className="ni ni-building mr-2" />
                                </span>
                                <span className="nav-link-inner--text">Solicitar acceso</span>
                            </Button>
                        </NavItem>
                    </Nav>
                </UncontrolledCollapse>
            </Container>
        </Navbar>
    </>
    );
}

AuthNavbar.propTypes = {
    requestAccessModal: PropTypes.bool,
    setRequestAccessModal: PropTypes.func,
}

export default AuthNavbar;