import React from "react";
// react library for routing
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// My components
import DashboardFooter from "../components/Footers/DashboardFooter"
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Sidebar from "components/Sidebars/Sidebar";

import routes, { dashboardRoutes } from "routes";

// Define the routes that will be rendered in the React Router Switch
const allRoutes = Array(...routes, ...dashboardRoutes);

const Dashboard = () => {
    const [sidenavOpen, setSidenavOpen] = React.useState(true);
    const location = useLocation();
    const mainContentRef = React.useRef(null);

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContentRef.current.scrollTop = 0;

        // Remove pinned sidenav on mobile as default
        if (window.innerWidth < 1200) {
            document.body.classList.remove("g-sidenav-pinned");
            // document.body.classList.remove("g-sidenav-show");
            document.body.classList.add("g-sidenav-hidden");
            setSidenavOpen(false);
        }
    }, [location]);

    // Get routes function
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.layout === "/") {
                return (
                    <Route
                    path={prop.layout + prop.path}
                    component={prop.component}
                    key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };

    // Get brand text function
    const getBrandText = (path) => {
        for (let i = 0; i < routes.length; i++) {
            if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
                return routes[i].name;
            }
        }
        return "Brand";
      };

    // Toggles collapse between mini sidenav and normal
    const toggleSidenav = (e) => {
        if (document.body.classList.contains("g-sidenav-pinned")) {
            document.body.classList.remove("g-sidenav-pinned");
            document.body.classList.add("g-sidenav-hidden");
        } else {
            document.body.classList.add("g-sidenav-pinned");
            document.body.classList.remove("g-sidenav-hidden");
        }
        setSidenavOpen(!sidenavOpen);
    };

    const getNavbarTheme = () => {
        return location.pathname.indexOf("admin/alternative-dashboard") === -1
          ? "dark"
          : "light";
      };
        
    return (
        <>
            <Sidebar
                routes={routes}
                toggleSidenav={toggleSidenav}
                sidenavOpen={sidenavOpen}
                logo={{
                    innerLink: "/",
                    imgSrc: require("assets/img/brand/hypatia-logo.png"),
                    imgAlt: "...",
                }}
            />
            <div className="main-content" ref={mainContentRef}>
                <DashboardNavbar
                    theme={getNavbarTheme()}
                    toggleSidenav={toggleSidenav}
                    sidenavOpen={sidenavOpen}
                    brandText={getBrandText(location.pathname)}
                />
                <Switch>
                    {getRoutes(allRoutes)}
                    <Redirect from="*" to="/dashboard" />
                </Switch>
                <DashboardFooter />
            </div>
            {sidenavOpen ? (
                <div className="backdrop d-xl-none" onClick={toggleSidenav} />
            ) : null}
        </>
    );
}

export default Dashboard;
