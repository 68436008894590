import React, { useEffect, useContext, useState } from "react";

// Import reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Form,
    Input,
    Container,
    Row,
    Col,
    FormGroup,
} from "reactstrap";

// React plugin that creates an input with badges
import TagsInput from "components/Inputs/TagsInput";

// Core components
import SimpleHeader from "components/Headers/SimpleHeader";
import RegisterTable from "components/Tables/RegisterTable";
// Import API services
import { getAllAreas, addNewArea } from "services/admin";


const areasTableMapping = {
    name: { tableHeading: 'Nombre' },
    shortname: { tableHeading: 'Abreviatura' },
    positions: { tableHeading: 'Cargos en el área' },
}    

const AreasSettings = () => {
    // Declare states
    const [areas, setAreas] = useState([]);
    const [newArea, setNewArea] = useState({});
    const [columnKeys, setColumnKeys] = useState([]);

    const [tagsInput, setTagsInput] = useState([]);

    useEffect(() => {
        // Fetch areas from server at first render
        fetchAreas();
    }, [])

    // Utility functions
    const fetchAreas = () => {
        getAllAreas()
            .then((data) => {
                if (data.success) {
                    setAreas(data.areas);
                    // Get column keys
                    let colKeys = data.table.column_keys;
                    colKeys.splice(colKeys.indexOf('id'), 1);
                    setColumnKeys(colKeys);
                }
            });
    }

    // Handlers
    const handleFormChange = (e) => {
        // Update newArea state
        setNewArea({ ...newArea, [e.target.name]: e.target.value });
    }
    
    const handleAreaSubmit = () => {
        // POST newArea to server
        addNewArea(newArea)
            .then((data) => {
                if (data.success) {
                        console.log(data.message);
                        // Fetch areas from server to update table
                        fetchAreas();
                    }
                })
            .catch(error => alert('Ocurrió un error.'));
        }
        
        // Customization variables
        const headerButtons = [
            { className: 'btn-neutral', color: 'default', content: 'Nuevo', link: '#add-new' },
        ];
        
        // Render component
        return (
            <>
            <SimpleHeader 
                name="Areas" 
                parentName="Bases de datos"
                buttons={headerButtons}
            />
            <Container className="mt--6" fluid>
                {/* Render table to display all areas */}
                <RegisterTable
                    columnKeys={columnKeys}
                    records={areas}
                    tableMapping={areasTableMapping}
                    tableTitle="Registro de áreas"
                />
                {/* Render form for adding new areas */}
                <Card className="mb-4">
                    <CardHeader>
                        <h3>Agregar nueva área</h3>
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <Row>
                                <Col sm="6">
                                    <FormGroup>
                                        <Input
                                            id="name"
                                            placeholder="Nombre de área"
                                            type="text"
                                            name='name'
                                            onChange={handleFormChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Input
                                            id="shortname"
                                            placeholder="Abreviatura"
                                            type="text"
                                            name='shortname'
                                            onChange={handleFormChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <TagsInput
                                            onlyUnique
                                            className="bootstrap-tagsinput"
                                            onChange={(value) => {
                                                setTagsInput(value);
                                                // Simulate an event object to add it to state through handler
                                                const e = { target: { value: value, name: 'positions' } }
                                                handleFormChange(e)
                                            }}
                                            value={tagsInput}
                                            tagProps={{ className: "tag badge mr-1" }}
                                            inputProps={{
                                                className: "",
                                                placeholder: "Cargos del área",
                                            }}
                                        />
                                    </FormGroup>                                     
                                </Col>
                            </Row>
                            <div className="text-left">
                                <Button
                                    color="primary"
                                    type="button"
                                    onClick={handleAreaSubmit}
                                    >
                                    Registrar
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </Container>
        </>
    );
}

export default AreasSettings;