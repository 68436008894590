import React, { useEffect, useContext, useState } from "react";

// Import reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Form,
    Input,
    Container,
    Row,
    Col,
    FormGroup,
} from "reactstrap";

// React plugin that creates an input with badges
import TagsInput from "components/Inputs/TagsInput";

// Core components
import SimpleHeader from "components/Headers/SimpleHeader";
import RegisterTable from "components/Tables/RegisterTable";
// Import API services
import { getAllUserRoles, addNewUserRole } from "services/admin";


const rolesTableMapping = {
    name: { tableHeading: 'Nombre' },
    permissions: { tableHeading: 'Permisos del rol' },
}    

const UserRolesSettings = () => {
    // Declare states
    const [roles, setRoles] = useState([]);
    const [newRole, setNewRole] = useState({});
    const [columnKeys, setColumnKeys] = useState([]);

    const [tagsInput, setTagsInput] = useState([]);

    useEffect(() => {
        // Fetch roles from server at first render
        fetchRoles();
    }, [])

    // Utility functions
    const fetchRoles = () => {
        getAllUserRoles()
            .then((data) => {
                if (data.success) {
                    setRoles(data.roles);
                    // Get column keys
                    let colKeys = data.table.column_keys;
                    colKeys.splice(colKeys.indexOf('id'), 1);
                    setColumnKeys(colKeys);
                }
            });
    }

    // Handlers
    const handleFormChange = (e) => {
        // Update newRol state
        setNewRole({ ...newRole, [e.target.name]: e.target.value });
    }
    
    const handleRolSubmit = () => {
        // Add new Role to server
        addNewUserRole(newRole)
            .then((data) => {
                if (data.success) {
                        console.log(data.message);
                        // Fetch roles from server to update table
                        fetchRoles();
                    }
                })
            .catch(error => alert('Ocurrió un error.'));
        }
        
        // Customization variables
        const headerButtons = [
            { className: 'btn-neutral', color: 'default', content: 'Nuevo', link: '#add-new' },
        ];
        
        // Render component
        return (
            <>
            <SimpleHeader 
                name="Roles" 
                parentName="Bases de datos"
                buttons={headerButtons}
            />
            <Container className="mt--6" fluid>
                {/* Render table to display all roles */}
                <RegisterTable
                    columnKeys={columnKeys}
                    records={roles}
                    tableMapping={rolesTableMapping}
                    tableTitle="Registro de Roles"
                />
                {/* Render form for adding new roles */}
                <Card className="mb-4">
                    <CardHeader>
                        <h3>Agregar nuevo rol</h3>
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <Row>
                                <Col sm="6">
                                    <FormGroup>
                                        <Input
                                            id="name"
                                            placeholder="Nombre de rol"
                                            type="text"
                                            name='name'
                                            onChange={handleFormChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>

                                    <FormGroup>
                                        <TagsInput
                                            onlyUnique
                                            className="bootstrap-tagsinput"
                                            onChange={(value) => {
                                                setTagsInput(value);
                                                // Simulate an event object to add it to state through handler
                                                const e = { target: { value: value, name: 'permissions' } }
                                                handleFormChange(e)
                                            }}
                                            value={tagsInput}
                                            tagProps={{ className: "tag badge mr-1" }}
                                            inputProps={{
                                                className: "",
                                                placeholder: "Permisos del rol",
                                            }}
                                        />
                                    </FormGroup>                                     
                                </Col>
                            </Row>
                            <div className="text-left">
                                <Button
                                    color="primary"
                                    type="button"
                                    onClick={handleRolSubmit}
                                    >
                                    Registrar
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </Container>
        </>
    );
}

export default UserRolesSettings;