const SERVER_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SERVER_URL : 'http://127.0.0.1:5000'

/********************
* Reagents' functions
*/ 
export const getAllReagents = async () => {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }
    const response = await fetch(`${SERVER_URL}/reagents/register/`, requestOptions);
    return await response.json();
}

export const addNewReagent = async (data) => {
    const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    }
    const response = await fetch(`${SERVER_URL}/reagents/register/`, requestOptions);
    return await response.json();
}


export const addNewReagentsBatch = async (formData) => {
    const requestOptions = {
        method: 'POST',
        credentials: 'include',
        body: formData
    }
    const response = await fetch(`${SERVER_URL}/reagents/register/`, requestOptions);
    return await response.json();
}

export const getReagentById = async (id) => {
    const requestOptions = {
        method: 'GET',
        credentials: 'include',
    }
    const response = await fetch(`${SERVER_URL}/reagent/${id}`, requestOptions);
    return await response.json();
}

export const getReagentByAccountingCode = async (accounting_code) => {
    const requestOptions = {
        method: 'GET',
        credentials: 'include',
    }
    const response = await fetch(`${SERVER_URL}/reagent/ac-${accounting_code}`, requestOptions);
    return await response.json();
}

export const updateReagentById = async (id, updatedData) => {
    const requestOptions = {
        method: 'PATCH',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedData)
    }
    const response = await fetch(`${SERVER_URL}/reagent/${id}`, requestOptions);
    return await response.json();
}

export const deleteReagentById = async (id) => {
    const requestOptions = {
        method: 'DELETE',
        credentials: 'include',
    }
    const response = await fetch(`${SERVER_URL}/reagent/${id}`, requestOptions);
    return await response.json();
}

/**************************
* Reagent's Usage functions
*/
export const getAllReagentsUsages = async (queryParameters) => {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }
    const query = new URLSearchParams(queryParameters);
    const response = await fetch(`${SERVER_URL}/reagents/usage-log?${query}`, requestOptions);
    return await response.json();
}

export const addNewReagentUsage = async (data) => {
    const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    }
    const response = await fetch(`${SERVER_URL}/reagents/usage-log/`, requestOptions);
    return await response.json();
}

export const getReagentUsageById = async (id) => {
    const requestOptions = {
        method: 'GET',
        credentials: 'include',
    }
    const response = await fetch(`${SERVER_URL}/reagents/usage-log/${id}`, requestOptions);
    return await response.json();
}

export const updateReagentUsageById = async (id, updatedData) => {
    const requestOptions = {
        method: 'PATCH',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedData)
    }
    const response = await fetch(`${SERVER_URL}/reagents/usage-log/${id}`, requestOptions);
    return await response.json();
}

export const deleteReagentUsageById = async (id) => {
    const requestOptions = {
        method: 'DELETE',
        credentials: 'include',
    }
    const response = await fetch(`${SERVER_URL}/reagents/usage-log/${id}`, requestOptions);
    return await response.json();
}

/*****************************
 * Reagent's Entries functions
 */
export const getAllReagentsEntries = async (queryParameters) => {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }
    const query = new URLSearchParams(queryParameters);
    const response = await fetch(`${SERVER_URL}/reagents/entries?${query}`, requestOptions);
    return await response.json();
}

export const addNewReagentEntry = async (data) => {
    const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    }
    const response = await fetch(`${SERVER_URL}/reagents/entries/`, requestOptions);
    return await response.json();
}

export const getReagentEntryById = async (id) => {
    const requestOptions = {
        method: 'GET',
        credentials: 'include',
    }
    const response = await fetch(`${SERVER_URL}/reagents/entry/${id}`, requestOptions);
    return await response.json();
}

export const updateReagentEntryById = async (id, updatedData) => {
    const requestOptions = {
        method: 'PATCH',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedData)
    }
    const response = await fetch(`${SERVER_URL}/reagents/entry/${id}`, requestOptions);
    return await response.json();
}

export const deleteReagentEntryById = async (id) => {
    const requestOptions = {
        method: 'DELETE',
        credentials: 'include',
    }
    const response = await fetch(`${SERVER_URL}/reagents/entry/${id}`, requestOptions);
    return await response.json();
}

/**
 * Reagent's Initial Balances functions
 */

export const getAllInitialBalances = async () => {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }
    const response = await fetch(`${SERVER_URL}/reagents/initial-balances`, requestOptions);
    return await response.json();
}

export const addNewInitialBalance = async (data) => {
    const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    }
    const response = await fetch(`${SERVER_URL}/reagents/initial-balances/`, requestOptions);
    return await response.json();
}

export const getInitialBalanceByReagentId = async (reagentId) => {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }
    const response = await fetch(`${SERVER_URL}/reagents/initial-balances/reagent-id-${reagentId}`, requestOptions);
    return await response.json();
}

export const deleteInitialBalanceByReagentId = async (reagentId) => {
    const requestOptions = {
        method: 'DELETE',
        credentials: 'include'
    }
    const response = await fetch(`${SERVER_URL}/reagents/initial-balances/reagent-id-${reagentId}`, requestOptions);
    return await response.json();
}

/*****************************
 * Reagent's Accounting related functions
 */

export const getAllReagentsAccountingSummaryByYear = async (year) => {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }
    let query = new URLSearchParams({year})
    const response = await fetch(`${SERVER_URL}/reagents/annual-summary?${query}`, requestOptions);
    return await response.json();
}

/**
 * Additional endpoints related to reagents
 */

export const getAllMeasurementUnits = async () => {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }
    const response = await fetch(`${SERVER_URL}/reagents/measurement-units`, requestOptions);
    return await response.json();
}