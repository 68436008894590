import React, { useEffect, useContext, useState, useRef } from "react";
import { Link } from "react-router-dom";
// Import reactstrap components
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    Collapse,
    Form,
    FormFeedback,
    Input,
    Label,
    Table,
    Container,
    Row,
    Col,
    FormGroup,
    Modal,
} from "reactstrap";
// React plugin used to create dropdowns
import Select from "react-select"; // TODO: Improve dropdown for reagent selection
// React Sweet Alert plugin
import ReactBSAlert from "react-bootstrap-sweetalert";
// Core components
import SimpleHeader from "components/Headers/SimpleHeader";
// import RegisterTable from "components/Tables/RegisterTable";
import RegisterTableFull from "components/Tables/RegisterTableFull";
// Import utility functions
import { dateToISOFormat, RCFdateToISOFormat } from "utilities/datetime";
import { roundNumber } from "utilities/math";
// Import API services
import {
    getAllReagents,
    getAllReagentsEntries, addNewReagentEntry, getReagentEntryById, updateReagentEntryById, deleteReagentEntryById,
} from "services/reagents";
import {
    addNewPurchaseOrder, getPurchaseOrderByNumber, putPurchaseOrderByNumber,
    addNewQuotation, getQuotationByCode
} from 'services/logistics';
import { getAllAreas } from "services/admin";

const tableMapping = {
    purchase_order_number: { tableHeading: 'Nº OC' },
    purchase_order_request_date: { tableHeading: 'Fecha Ped.' },
    purchase_order_sent_date: { tableHeading: 'Fecha Env.' },
    provider: { tableHeading: 'Proveedor' },
    quotation_code: { tableHeading: 'Cotización' },
    reagent: { tableHeading: 'Reactivo' },
    lot_serialnum: { tableHeading: 'Lote/Serie' },
    exp_date: { tableHeading: 'Fec. Vencimiento' },
    quantity: { tableHeading: 'Cantidad' },
    unit_price_pen: { tableHeading: 'P. unit. (S/)' },
    unit_price_usd: { tableHeading: 'P. unit. ($)' },
    usd_pen_day: { tableHeading: 'T.Cambio' },
    total_cost: { tableHeading: 'Costo Total' },
    date: { tableHeading: 'Fecha de ingreso' },
    invoice: { tableHeading: 'Factura' },
    invoice_date: { tableHeading: 'Fecha facturación' },
    area_shortname: { tableHeading: 'Area' },
    status: { tableHeading: 'Estado del Pedido' },
    ed_date: { tableHeading: 'Fecha entrega est.' },
    notes: { tableHeading: 'Notas' },
}

const PURCHASE_ORDER_STATUSES = ['PENDIENTE', 'SOLICITADO', 'ENTREGADO']

// Define initial state for filtering
const FILTER_INITIAL_STATE = {  
    requestStartDate: `${new Date().getFullYear()}-01-01`, 
    requestEndDate: dateToISOFormat(new Date()),
}
// Define initial states for form input validations
const EDIT_FORM_VALIDATION_INITIAL_STATE = {
    // true value means the input is valid
    po_number: true,
    po_request_date: true
}

const ReagentsEntries = () => {
    // Declare references
    const addNewRef = useRef(null);
    // Declare states
    const [reagentEntries, setReagentEntries] = useState([]);
    const [modifiedReagentEntries, setModifiedReagentEntries] = useState([]);
    const [newReagentEntry, setNewReagentEntry] = useState({});
    const [columnKeys, setColumnKeys] = useState([]);
    // Complementary data
    const [areas, setAreas] = useState([]);
    const [reagents, setReagents] = useState([]);
    // State for related purchase order and quotation data
    const [newReagentEntryLogisticsData, setNewReagentEntryLogisticsData] = useState({});
    // Filter states
    const [filterCollapseOpen, setFilterCollapseOpen] = useState(false);
    const [filterState, setFilterState] = useState(FILTER_INITIAL_STATE);
    // Modal states
        // Delete Record Modal states
    const [modalDeleteRecord, setModalDeleteRecord] = useState(false);
    const [recordToDeleteData, setRecordToDeleteData] = useState({});
        // Edit Record Modal states
    const [modalEditRecord, setModalEditRecord] = useState(false);
    const [recordToEditData, setRecordToEditData] = useState({});    
    const [updatedData, setUpdatedData] = useState({});
    const [updatedLogisticsData, setUpdatedLogisticsData] = useState({});
    // Form validation states
    const [editFormValidationStates, setEditFormValidationStates] = useState(EDIT_FORM_VALIDATION_INITIAL_STATE);
    // Alert states
    const [alertContent, setAlertContent] = useState(null);

    useEffect(() => {
        // Fetch records from server at first render
        fetchReagentEntries(filterState);
        // Fetch complementary data
        fetchAreas();
        fetchReagents();
    }, []);
    
    useEffect(() => {
        // Add a 'area_shortname' and 'reagent' property to each record
        const modifiedReagentEntriesList = reagentEntries.map(reagentEntry => {          
            return {
                ...reagentEntry, 
                area_shortname: reagentEntry.area.shortname,
                reagent: `${reagentEntry.reagent.accounting_code} | ${reagentEntry.reagent.brand} | ${reagentEntry.reagent.description}`,
                purchase_order_number: reagentEntry.purchase_order?.po_number,
                quotation_code: reagentEntry.quotation?.quotation_code,
                purchase_order_request_date: RCFdateToISOFormat(reagentEntry.purchase_order?.request_date),
                purchase_order_sent_date: RCFdateToISOFormat(reagentEntry.purchase_order?.sent_date),
                unit_price_pen: roundNumber(reagentEntry.unit_price_pen, 2),
                unit_price_usd: roundNumber(reagentEntry.unit_price_usd, 2),
                total_cost: roundNumber(reagentEntry.total_cost, 2),
                usd_pen_day: reagentEntry.usd_pen_day.toFixed(3)

            }
        });
        setModifiedReagentEntries(modifiedReagentEntriesList);

    }, [reagentEntries])


    // Utility functions
    const fetchReagentEntries = (queryParameters) => {
        getAllReagentsEntries(queryParameters)
            .then(data => {
                if (data.success) {
                    setReagentEntries(data.records);
                    // Get column keys that must be displayed in the table
                    let colKeys = data.table.column_keys;
                    setColumnKeys(colKeys);
                }                
            });
    }
    // 
    const fetchAreas = () => {
        getAllAreas()
            .then(data => {
                if (data.success) {
                    setAreas(data.areas);
                }
            });
    }
    // 
    const fetchReagents = () => {
        getAllReagents()
            .then(data => {
                if (data.success) {
                    setReagents(data.reagents);
                }
            });
    }

    // Handlers
    const handleInputsChange = (e) => {
        if (
            e.target.name === 'po_number' || 
            e.target.name === 'po_request_date' ||
            e.target.name === 'po_sent_date' ||
            e.target.name === 'quotation_code'
        ) {
            // Update state for new record
            if (e.target.value === '') {
                // If value is an empty string, remove property
                setNewReagentEntryLogisticsData(state => {
                    delete state[e.target.name]; 
                    return state;
                });
            } else {
                // Update property
                setNewReagentEntryLogisticsData({
                    ...newReagentEntryLogisticsData, [e.target.name]: e.target.value 
                });
            }
        } else {
            // Update state for new record
            if (e.target.value === '') {
                // If value is an empty string, remove property
                setNewReagentEntry(state => {
                    delete state[e.target.name]; 
                    return state;
                });
            } else {
                // Update property
                setNewReagentEntry({ ...newReagentEntry, [e.target.name]: e.target.value });
            }
        }     
    }
    
    // Handler to open 'delete' modal
    const handleOpenModalDelete = (e, id) => {
        e.preventDefault();
        // Load data of 'Reagent Entry' from server
        getReagentEntryById(id)
            .then(data => {
                if (data.success) {
                    setRecordToDeleteData(data.reagent_entry);
                    // Activate delete modal window
                    setModalDeleteRecord(true);
                } else {
                    console.log(data.message);
                }
            })
            .catch(error => console.log);
    }
    // Handler to open 'edit' modal
    const handleOpenModalEdit = (e, id) => {
        e.preventDefault();
        // Empty updatedData and updatedlogisticsData state
        setUpdatedData({});
        setUpdatedLogisticsData({});
        // Reset inputs validation states
        setEditFormValidationStates(EDIT_FORM_VALIDATION_INITIAL_STATE);
        // Fetch 'Reagent Entry' data to show in modal
        getReagentEntryById(id)
            .then(data => {
                if (data.success) {
                    setRecordToEditData(data.reagent_entry);
                    // Activate modal 
                    setModalEditRecord(true);                          
                } else {
                    console.log(data.message);
                }
            })
            .catch(error => console.log)       
    }
    // Handler to delete record on server
    const handleDeleteRecord = () => {
        deleteReagentEntryById(recordToDeleteData.id)
            .then(data => {
                if (data.success) {
                    console.log(data.message);
                    // Close modal
                    setModalDeleteRecord(false)
                    // Fetch records from server to update table
                    fetchReagentEntries(filterState);
                    // Remove deleted record data from its state
                    setRecordToDeleteData({});
                    // TODO: Trigger a success notification
                } else {
                    console.log(data.message);
                    alert("No fue posible eliminar el Ingreso de Reactivo");
                }
            })
            .catch(error => {
                alert("Ocurrió un error en el servidor.");
                console.log(error);
            });
    }

    // Handler to submit edited record's data
    const handleEditInputsChange = (e) => {
        // Update data state that will replace the existing one
        if (
            e.target.name === 'po_number' || 
            e.target.name === 'po_request_date' ||
            e.target.name === 'po_sent_date' ||
            e.target.name === 'quotation_code'
        ) {
            // XXX: When removing an input's content in the Edit Form,
            // it won't send anything regarding that property to the server.
            // Therefore it's not possible to delete any data in the row/record yet            
            // Update data state regarding logistics
            if (e.target.value === '') {
                // if new value is an empty string, remove property
                setUpdatedLogisticsData(state => {
                    delete state[e.target.name];
                    return state;
                });
            } else {
                setUpdatedLogisticsData({
                    ...updatedLogisticsData, [e.target.name]: e.target.value
                });
            }
        } else {
            // XXX: When removing an input's content in the Edit Form,
            // it won't send anything regarding that property to the server.
            // Therefore it's not possible to delete any data in the row/record yet
            if (e.target.value === '') {
                // if new value is an empty string, remove property
                setUpdatedData(state => {
                    delete state[e.target.name];
                    return state;
                });
            } else {
                setUpdatedData({
                    ...updatedData, [e.target.name]: e.target.value
                });
            }
        }
        // Clean validation feedback for current input
        setEditFormValidationStates(state => {
            state[e.target.name] = true;
            return state;
        });
    }

    const handleUpdateRecord = async (e) => {
        let data = Object({...updatedData});
        // Check for changes of related quotation 
        let quotationId;
        if (updatedLogisticsData.hasOwnProperty('quotation_code')) {
            // Get Quotation ID from server if resource exists, otherwise create it
            quotationId = await getQuotationByCode(updatedLogisticsData.quotation_code)
                .then(data => {
                    if (data.success) {
                        return data.quotation.id;
                    } else {
                        console.log(data.message);
                        // Create the resource
                        return addNewQuotation({ quotation_code: updatedLogisticsData.quotation_code })
                            .then(data => {
                                if (data.success){
                                    return data.quotation.id;
                                } else {
                                    console.log(data.message);
                                }
                            });
                    }
                });
            data['quotation_id'] = quotationId;
            if (!Number.isInteger(quotationId)) { alert("Ocurrió un error"); return; }
        }
        
        // Check for changes of related purchase Order
        let purchaseOrderId;
        if (
            updatedLogisticsData.hasOwnProperty('po_number') ||
            updatedLogisticsData.hasOwnProperty('po_request_date') ||
            updatedLogisticsData.hasOwnProperty('po_sent_date')
        ) {
            // Load the updated values if exist, otherwise, load the current values
            let po_number = updatedLogisticsData.hasOwnProperty('po_number') ? updatedLogisticsData.po_number : recordToEditData.purchase_order?.po_number;
            let po_request_date = updatedLogisticsData.hasOwnProperty('po_request_date') ? updatedLogisticsData.po_request_date : recordToEditData.purchase_order?.request_date;
            let po_sent_date = updatedLogisticsData.hasOwnProperty('po_sent_date') ? updatedLogisticsData.po_sent_date : recordToEditData.purchase_order?.po_sent_date;
            // Validate purchase order data
            if (!po_number) {
                setEditFormValidationStates({ ...editFormValidationStates, po_number: false });
                return;
            }
            if (!po_request_date) {
                setEditFormValidationStates({ ...editFormValidationStates, po_request_date: false });
                return;
            }
            let year = Number(po_request_date.slice(0, 4));

            // Get purchase order through PUT request (creates it if it doesn't exist)
            purchaseOrderId = await putPurchaseOrderByNumber(
                po_number,
                year,
                {
                    request_date: po_request_date,
                    sent_date: po_sent_date,
                }
            )
                .then(data => {
                    if (data.success) {
                        return data.purchase_order.id;
                    } else {
                        console.log(data.message);
                    }
                });

            data['purchase_order_id'] = purchaseOrderId;
            if (!Number.isInteger(purchaseOrderId)) { alert("Ocurrió un error"); return; }
        }

        // Request a Reagent Entry modification to the server
        updateReagentEntryById(recordToEditData.id, data)
            .then(data => {
                if (data.success) {
                    // Close modal
                    setModalEditRecord(false);
                    // Fetch reagents usage to update the table
                    fetchReagentEntries(filterState);
                    // Clean data state of updated record
                    setRecordToEditData({});
                } else {
                    alert("No es posible modificar el Ingreso de Reactivo");
                }
            })
            .catch(error => {
                alert("Ocurrió un error en el servidor.");
                console.log(error);
            });
    }   

    const handleClickAddNew = (e) => {
        e.preventDefault();
        addNewRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const handleSubmitNew = async (e) => {
        e.preventDefault();
        // Create Quotation in server if it doesn't exist and get the quotation id
        let quotationId;
        if (newReagentEntryLogisticsData.hasOwnProperty('quotation_code')) {
            quotationId = await getQuotationByCode(newReagentEntryLogisticsData.quotation_code)
                .then(data => {
                    if (data.success) {
                        return data.quotation.id;
                    } else {
                        console.log(data.message);
                        // Create new Quotation
                        return addNewQuotation({ quotation_code: newReagentEntryLogisticsData.quotation_code })
                            .then(data => {
                                if (data.success) {
                                    return data.quotation.id;
                                } else {
                                    console.log(data.message);
                                };
                            });
                    }
                });
            if (!Number.isInteger(quotationId)) { alert("Ocurrió un error"); return }
        }

        // Create Purchase Order in server if it doesn't exist and get the purchase order id
        let purchaseOrderId;
        if (newReagentEntryLogisticsData.hasOwnProperty('po_number')) {
            let po_number = newReagentEntryLogisticsData.po_number
            let po_request_date = newReagentEntryLogisticsData.po_request_date || null;
            let po_sent_date = newReagentEntryLogisticsData.po_sent_date || null;
            
            let po_year;
            if (po_request_date) {
                po_year = new Date(po_request_date).getFullYear();
            } else {
                po_year = new Date().getFullYear();
            }
            purchaseOrderId = await getPurchaseOrderByNumber(
                newReagentEntryLogisticsData.po_number,
                po_year
            )
                .then(data => {
                    if (data.success) {
                        return data.purchase_order.id;
                    } else {
                        console.log(data.message);
                        // Create new Purchase Order
                        return addNewPurchaseOrder({
                            po_number: po_number,
                            request_date: po_request_date,
                            sent_date: po_sent_date
                        })
                            .then(data => {
                                if (data.success) {
                                    return data.purchase_order.id;
                                } else {
                                    console.log(data.message);
                                }
                            });
                    }
                });
            if (!Number.isInteger(purchaseOrderId)) { alert("Ocurrió un error"); return }
        }
        
        // Create new reagent entry
        addNewReagentEntry({
            ...newReagentEntry, 
            purchase_order_id: purchaseOrderId,
            quotation_id: quotationId                                    
        }).then(data => {
            if (data.success) {
                    console.log(data.message);
                    // Alert user of successful action
                    setAlertContent(<SuccessAlert stateSetter={setAlertContent} />);                         
                    // Fetch records from server to update table
                    fetchReagentEntries(filterState);
            } else {
                // Alert the user of unsuccessful action
                setAlertContent(<WarningAlert stateSetter={setAlertContent} />);
                console.log("Server: ", data.message);
            }                                    
        })
        .catch(error => {
            // Alert about the error
            setAlertContent(<DangerAlert stateSetter={setAlertContent} />);      
        });
    }

    const handleSubmitFilter = (e) => {
        e.preventDefault();
        // Fetch reagent entries with new filters
        fetchReagentEntries(filterState);
        // Close filter
        handleFilterCollapseToggle();
    }

    const handleCleanFilter = (e) => {
        e.preventDefault();
        // Reset filter to initial state
        setFilterState(FILTER_INITIAL_STATE);
    }
        
    const handleFilterCollapseToggle = () => {
        setFilterCollapseOpen(!filterCollapseOpen);
    }

    // Header customization variables
    const headerButtons = [
        { content: '+ Nuevo', handler: handleClickAddNew, className: 'btn-neutral', color: 'default'},
        { content: 'Filtros', handler: handleFilterCollapseToggle, className: 'btn-neutral', color: 'default'},
    ];
    // RegisterTable customization variables
        // Dropdown menu
    const dropdownMenu = {
        buttons: [
            { text: "Editar", handler: handleOpenModalEdit, property: 'id' },
        ]
    }    
        // Inline Actions
    const inlineActions = [
        { text: 'Editar', handler: handleOpenModalEdit, property: 'id', icon: 'fas fa-edit' },
        { text: 'Eliminar', handler: handleOpenModalDelete, property: 'id', icon: 'fas fa-trash' },
    ]   
    // Render component
    return (
        <>
            <SimpleHeader 
                name="Ingresos" 
                parentName="Reactivos"
                buttons={headerButtons}
            />
            <Container className="mt--6" fluid>
                {/* Render filters form */}
                <Collapse isOpen={filterCollapseOpen}>
                    <TableFilters
                        state={filterState}
                        stateSetter={setFilterState}
                        onCleanFilter={handleCleanFilter}
                        onSubmitFilter={handleSubmitFilter}
                    />
                </Collapse>
                {/* Render table to display all users */}
                <RegisterTableFull
                    tableTitle="Registro de Ingresos"
                    columnKeys={columnKeys}
                    records={modifiedReagentEntries}
                    tableMapping={tableMapping}
                    inlineActions={inlineActions}
                    dropdownMenu={dropdownMenu}
                    // size='sm'
                />
                <DeleteReagentModal
                    modalDeleteRecord={modalDeleteRecord}
                    setModalDeleteRecord={setModalDeleteRecord}
                    recordData={recordToDeleteData}
                    onClickConfirm={handleDeleteRecord}                
                />                
                <EditReagentModal
                    modalEditRecord={modalEditRecord}
                    setModalEditRecord={setModalEditRecord}
                    recordData={recordToEditData}
                    onClickConfirm={handleUpdateRecord}
                    onInputsChange={handleEditInputsChange}
                    reagentsData={reagents}
                    areasData={areas}
                    formValidationStates={editFormValidationStates}
                />                    
                {/* Render form for adding new users */}
                <Card className="mb-4">
                    <CardHeader>
                        <h3 ref={addNewRef}>Registrar Nuevo Ingreso</h3>
                        <p>
                            Si se está adquiriendo un reactivo nuevo, 
                            primero añadelo aquí: <Link to='/reagents/register'>Registrar reactivo</Link>
                        </p>
                    </CardHeader>
                    <CardBody>
                        <Form onSubmit={e => handleSubmitNew(e)}>
                            <Row>
                                <Col md='4'>
                                    <FormGroup>
                                        {/* <Label
                                            className="form-control-label"
                                            for="po_number"
                                        >
                                            Orden de Compra
                                        </Label> */}
                                        <Input
                                            id="po_number"
                                            placeholder="Orden de Compra"
                                            type="number"
                                            name='po_number'
                                            onChange={handleInputsChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm='4'>
                                    <FormGroup>
                                        {/* <Label
                                            className="form-control-label"
                                            for="po_request_date"
                                        >
                                            Fecha de pedido de OC
                                        </Label>                                         */}
                                        <Input
                                            id="po_request_date"
                                            placeholder="Fecha de pedido de OC"
                                            type="text"
                                            name='po_request_date'
                                            onChange={handleInputsChange}
                                            onFocus={(e) => e.target.type='date'}
                                            onBlur={(e) => e.target.type='text'}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm='4'>
                                    <FormGroup>
                                        {/* <Label
                                            className="form-control-label"
                                            for="po_sent_date"
                                        >
                                            Fecha de envio de OC
                                        </Label>                                         */}
                                        <Input
                                            id="po_sent_date"
                                            placeholder="Fecha de envio de OC"
                                            type="text"
                                            name='po_sent_date'
                                            onChange={handleInputsChange}
                                            onFocus={(e) => e.target.type='date'}
                                            onBlur={(e) => e.target.type='text'}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>                                
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <Input
                                            required
                                            id="provider"
                                            placeholder="Proveedor"
                                            type="text"
                                            name='provider'
                                            onChange={handleInputsChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Input
                                            id="quotation_code"
                                            placeholder="Cotización"
                                            type="text"
                                            name='quotation_code'
                                            onChange={handleInputsChange}
                                        />
                                    </FormGroup>    
                                </Col>                                
                                <Col sm='12' xl="6">
                                    <FormGroup>
                                        <Input 
                                            required
                                            id="reagent_id" 
                                            placeholder=""
                                            type="select"
                                            name='reagent_id'
                                            onChange={handleInputsChange}
                                            defaultValue=''
                                        >   
                                            <option value='' disabled>Selecciona el reactivo</option>
                                            {
                                                reagents.map(reagent => {
                                                    return(
                                                        <option value={reagent.id} key={reagent.id}>{reagent.accounting_code} - {reagent.brand} | {reagent.description}</option>
                                                    );
                                                })
                                            }
                                        </Input>                                                                 
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <FormGroup>
                                        <Input
                                            id="lot_serialnum"
                                            placeholder="Lote/Serie"
                                            type="text"
                                            name='lot_serialnum'
                                            onChange={handleInputsChange}
                                        />
                                    </FormGroup>    
                                </Col>
                                <Col sm='6'>
                                    <FormGroup>
                                        <Input
                                            id="exp_date"
                                            placeholder="Fecha de vencimiento"
                                            type="text"
                                            name='exp_date'
                                            onChange={handleInputsChange}
                                            onFocus={(e) => e.target.type='date'}
                                            onBlur={(e) => e.target.type='text'}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm='6'>
                                    <FormGroup>
                                        <Input
                                            required
                                            id="quantity"
                                            placeholder="Cantidad"
                                            type="number"
                                            name='quantity'
                                            onChange={handleInputsChange}
                                        />
                                    </FormGroup>
                                </Col> 
                            </Row>
                            <Row>
                                <Col sm='6' xl='3'>
                                    <FormGroup>
                                        <Input
                                            step={0.001}
                                            id="unit_price_pen"
                                            placeholder="Precio unit. (S/)"
                                            type="number"
                                            name='unit_price_pen'
                                            onChange={handleInputsChange}
                                        />
                                    </FormGroup>    
                                </Col>
                                <Col sm='6' xl='3'>
                                    <FormGroup>
                                        <Input
                                            step={0.001}
                                            id="unit_price_usd"
                                            placeholder="Precio unit. ($)"
                                            type="number"
                                            name='unit_price_usd'
                                            onChange={handleInputsChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm='6' xl='3'>
                                    <FormGroup>
                                        <Input
                                            required
                                            step={0.001}
                                            id="usd_pen_day"
                                            placeholder="Tipo de cambio"
                                            type="number"
                                            name='usd_pen_day'
                                            onChange={handleInputsChange}
                                        />
                                    </FormGroup>    
                                </Col>
                                <Col sm='6' xl='3'>
                                    <FormGroup>
                                        <Input
                                            id="total_cost"
                                            placeholder="Costo total (sin IGV)"
                                            type="number"
                                            name='total_cost'
                                            onChange={handleInputsChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <h4>Contabilidad</h4>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm='6'>
                                    <FormGroup>
                                        <Input
                                            id="invoice"
                                            placeholder="Factura"
                                            type="text"
                                            name='invoice'
                                            onChange={handleInputsChange}
                                        />
                                    </FormGroup>  
                                </Col>
                                <Col sm='6'>
                                    <FormGroup>
                                        <Input
                                            id="invoice_date"
                                            placeholder="Fecha de factura"
                                            type="text"
                                            name='invoice_date'
                                            onChange={handleInputsChange}
                                            onFocus={(e) => e.target.type='date'}
                                            onBlur={(e) => e.target.type='text'}
                                        />
                                    </FormGroup>
                                </Col>          
                                <Col sm="6">
                                    <FormGroup>
                                        <Input 
                                            required
                                            id="area_id" 
                                            placeholder="Area"
                                            type="select"
                                            name='area_id'
                                            onChange={handleInputsChange}
                                            defaultValue=''
                                        >   
                                            <option value='' disabled>Selecciona el area usuaria</option>
                                            {
                                                areas.map(area => {
                                                    return(
                                                        <option value={area.id} key={area.id}>{area.name}</option>
                                                    );
                                                })
                                            }
                                        </Input>                                                                 
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>   
                                <Col sm='6'>
                                    <FormGroup>
                                        <Input
                                        // TODO: When deleting the content from an input field
                                        // it must not save an empty string to the state
                                            id="date"
                                            placeholder="Fecha de ingreso (recepción)"
                                            type="text"
                                            name='date'
                                            onChange={handleInputsChange}
                                            onFocus={(e) => e.target.type='date'}
                                            onBlur={(e) => e.target.type='text'}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm='6'>
                                    <FormGroup>
                                        <Input 
                                            id="status" 
                                            placeholder="Estado del pedido"
                                            type="select"
                                            name='status'
                                            onChange={handleInputsChange}
                                            defaultValue="Estado actual del pedido"
                                        >   
                                            <option disabled>Estado actual del pedido</option>
                                            {
                                                PURCHASE_ORDER_STATUSES.map((status) => {
                                                    return(
                                                        <option key={"po_status_"+status}>{status}</option>
                                                    )
                                                })
                                            }
                                        </Input>                                         
                                    </FormGroup>
                                </Col>
                                <Col sm='6'>
                                    <FormGroup>
                                        <Input
                                            id="ed_date"
                                            placeholder="Fecha de entrega estimada"
                                            type="text"
                                            name='ed_date'
                                            onChange={handleInputsChange}
                                            onFocus={(e) => e.target.type='date'}
                                            onBlur={(e) => e.target.type='text'}
                                        />
                                    </FormGroup>
                                </Col>                                                    
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="notes"
                                        >
                                            Notas u observaciones
                                        </label>
                                        <Input
                                            id="notes"
                                            rows="3"
                                            type="textarea"
                                            name="notes"
                                            placeholder="Agrega una nota adicional"
                                            onChange={handleInputsChange}
                                        />
                                    </FormGroup>
                                </Col>                                  
                            </Row>
                            <div className="text-left">
                                <Button
                                    color="primary"
                                    type="submit"
                                    // onClick={handleSubmitNew}
                                    >
                                    Registrar
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </Container>        
            {alertContent}               
        </>
    );
}


const DeleteReagentModal = ({
    modalDeleteRecord, setModalDeleteRecord,
    recordData,
    onClickConfirm: handleClickConfirm
}) => {

    return (
        <Modal
            className="modal-dialog-centered"
            // contentClassName="bg-gradient-danger"
            isOpen={modalDeleteRecord}
            toggle={() => setModalDeleteRecord(false)}                
        >
            <div className="modal-header">
                <h3 className="modal-title">
                    ¿Borrar ingreso de reactivo?
                </h3>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalDeleteRecord(false)}
                >
                    <span aria-hidden={true}>×</span>
                </button>                      
            </div>
            <div className="modal-body">
                <p>
                    Vas a eliminar el siguiente <strong>Ingreso de Reactivo</strong>:
                </p>
                <Table responsive>
                    <tbody>
                        <tr>
                            <td>Orden de compra:</td>
                            <td>{recordData.purchase_order?.po_number}</td>
                        </tr>
                        <tr>
                            <td>Proveedor: </td>
                            <td>{recordData.provider}</td>
                        </tr>
                        <tr>
                            <td>Cotización: </td>
                            <td>{recordData.quotation?.quotation_code}</td>
                        </tr>
                        <tr>
                            <td>Reactivo: </td>
                            <td>{recordData.reagent?.accounting_code} - {recordData.reagent?.brand} | {recordData.reagent?.description}</td>
                        </tr>
                        <tr>
                            <td>Cantidad: </td>
                            <td>{recordData.quantity}</td>
                        </tr>
                        <tr>
                            <td>Costo total: </td>
                            <td>S/ {recordData.total_cost}</td>
                        </tr>
                    </tbody>
                </Table>                     
                <p className="mt-4">Esta acción es irreversible.</p>
            </div>
            <div className="modal-footer">
                <Button
                    // className="new-event--add"
                    color="danger"
                    type="button"
                    onClick={handleClickConfirm}
                >
                    Sí, eliminar
                </Button>
                <Button
                    className="ml-auto"
                    color="link"
                    type="button"
                    onClick={() => setModalDeleteRecord(false)}
                >
                    Cancelar
                </Button>
            </div>
        </Modal>   
    );
}


const EditReagentModal = ({
    modalEditRecord, setModalEditRecord,
    recordData,
    onClickConfirm: handleClickConfirm,
    onInputsChange: handleInputsChange,
    reagentsData: reagents,
    areasData: areas,
    formValidationStates: formValidationStates
}) => {

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={modalEditRecord}
            toggle={() => setModalEditRecord(false)}
            size="lg"           
        >
            <div className="modal-header">
                <h3 className="modal-title">
                    Editar datos de Ingreso de Reactivo
                </h3>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalEditRecord(false)}
                >
                    <span aria-hidden={true}>×</span>
                </button>                      
            </div>
            <div className="modal-body">
                <p>
                    Puedes modificar el siguiente <strong>Ingreso de Reactivo</strong>:
                </p>
                <ReagentUsageForm
                    onInputsChange={handleInputsChange}
                    data={recordData}
                    reagentsData={reagents}
                    areasData={areas}
                    validationStates={formValidationStates}
                />             
                {/* <p className="mt-4">Verifica que los datos sean correctos.</p> */}
            </div>
            <div className="modal-footer">
                <Button
                    // className="new-event--add"
                    color="info"
                    type="button"
                    onClick={handleClickConfirm}
                >
                    Guardar
                </Button>
                <Button
                    className="ml-auto"
                    color="link"
                    type="button"
                    onClick={() => setModalEditRecord(false)}
                >
                    Cancelar
                </Button>
            </div>
        </Modal>           
    )
}


const ReagentUsageForm = ({
    onInputsChange: handleInputsChange,
    data: formData,
    validationStates: validationStates,
    reagentsData: reagents,
    areasData: areas
}) => {

    return (
        <Form noValidate>
            <Row>
                <Col md='4'>
                    <FormGroup>
                        <Input
                            id="po_number"
                            placeholder="Orden de Compra"
                            type="number"
                            name='po_number'
                            invalid={!validationStates?.po_number}
                            onChange={handleInputsChange}
                            defaultValue={formData?.purchase_order?.po_number}
                        />
                        <FormFeedback>Falta número de OC.</FormFeedback>
                    </FormGroup>
                </Col>
                <Col sm='4'>
                    <FormGroup>
                        {/* <Label
                            className="form-control-label"
                            for="po_request_date"
                        >
                            Fecha de pedido de OC
                        </Label>                                         */}
                        <Input
                            id="po_request_date"
                            placeholder="Fecha de pedido de OC"
                            type="text"
                            name='po_request_date'
                            invalid={!validationStates?.po_request_date}
                            onChange={handleInputsChange}
                            onFocus={(e) => e.target.type='date'}
                            onBlur={(e) => e.target.type='text'}
                            defaultValue={formData?.purchase_order?.request_date}
                        />
                        <FormFeedback>Falta fecha de pedido de OC.</FormFeedback>
                    </FormGroup>
                </Col>
                <Col sm='4'>
                    <FormGroup>
                        {/* <Label
                            className="form-control-label"
                            for="po_sent_date"
                        >
                            Fecha de envio de OC
                        </Label>                                         */}
                        <Input
                            id="po_sent_date"
                            placeholder="Fecha de envio de OC"
                            type="text"
                            name='po_sent_date'
                            onChange={handleInputsChange}
                            onFocus={(e) => e.target.type='date'}
                            onBlur={(e) => e.target.type='text'}
                            defaultValue={formData?.purchase_order?.sent_date}
                        />
                    </FormGroup>
                </Col>                        
            </Row>                                
            <Row>
                <Col md="4">
                    <FormGroup>
                        <Input
                            id="provider"
                            placeholder="Proveedor"
                            type="text"
                            name='provider'
                            onChange={handleInputsChange}
                            defaultValue={formData?.provider}
                        />
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <Input
                            id="quotation_code"
                            placeholder="Cotización"
                            type="text"
                            name='quotation_code'
                            onChange={handleInputsChange}
                            defaultValue={formData?.quotation?.quotation_code}
                        />
                    </FormGroup>    
                </Col>                                
                <Col sm='12' xl="6">
                    <FormGroup>
                        <Input 
                            id="reagent_id" 
                            placeholder=""
                            type="select"
                            name='reagent_id'
                            onChange={handleInputsChange}
                            defaultValue={formData?.reagent_id}
                        >   
                            <option disabled>Selecciona el reactivo</option>
                            {
                                reagents.map(reagent => {
                                    return(
                                        <option value={reagent.id} key={reagent.id}>{reagent.accounting_code} - {reagent.brand} | {reagent.description}</option>
                                    );
                                })
                            }
                        </Input>                                                                 
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm="6">
                    <FormGroup>
                        <Input
                            id="lot_serialnum"
                            placeholder="Lote/Serie"
                            type="text"
                            name='lot_serialnum'
                            onChange={handleInputsChange}
                            defaultValue={formData?.lot_serialnum}
                        />
                    </FormGroup>    
                </Col>
                <Col sm='6'>
                    <FormGroup>
                        <Input
                            id="exp_date"
                            placeholder="Fecha de vencimiento"
                            type="text"
                            name='exp_date'
                            onChange={handleInputsChange}
                            onFocus={(e) => e.target.type='date'}
                            onBlur={(e) => e.target.type='text'}
                            defaultValue={formData?.exp_date}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm='6'>
                    <FormGroup>
                        <Input
                            id="quantity"
                            placeholder="Cantidad"
                            type="number"
                            name='quantity'
                            onChange={handleInputsChange}
                            defaultValue={formData?.quantity}
                        />
                    </FormGroup>
                </Col> 
            </Row>
            <Row>
                <Col sm='6' xl='3'>
                    <FormGroup>
                        <Input
                            id="unit_price_pen"
                            placeholder="Precio unit. (S/)"
                            type="number"
                            name='unit_price_pen'
                            onChange={handleInputsChange}
                            defaultValue={formData?.unit_price_pen}
                        />
                    </FormGroup>    
                </Col>
                <Col sm='6' xl='3'>
                    <FormGroup>
                        <Input
                            id="unit_price_usd"
                            placeholder="Precio unit. ($)"
                            type="number"
                            name='unit_price_usd'
                            onChange={handleInputsChange}
                            defaultValue={formData?.unit_price_usd}
                        />
                    </FormGroup>
                </Col>
                <Col sm='6' xl='3'>
                    <FormGroup>
                        <Input
                            id="usd_pen_day"
                            placeholder="Tipo de cambio"
                            type="number"
                            name='usd_pen_day'
                            onChange={handleInputsChange}
                            defaultValue={formData?.usd_pen_day}
                        />
                    </FormGroup>    
                </Col>
                <Col sm='6' xl='3'>
                    <FormGroup>
                        <Input
                            id="total_cost"
                            placeholder="Costo total (sin IGV)"
                            type="number"
                            name='total_cost'
                            onChange={handleInputsChange}
                            defaultValue={formData?.total_cost}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <h4>Contabilidad</h4>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm='6'>
                    <FormGroup>
                        <Input
                            id="invoice"
                            placeholder="Factura"
                            type="text"
                            name='invoice'
                            onChange={handleInputsChange}
                            defaultValue={formData?.invoice}
                        />
                    </FormGroup>  
                </Col>
                <Col sm='6'>
                    <FormGroup>
                        <Input
                            id="invoice_date"
                            placeholder="Fecha de factura"
                            type="text"
                            name='invoice_date'
                            onChange={handleInputsChange}
                            onFocus={(e) => e.target.type='date'}
                            onBlur={(e) => e.target.type='text'}
                            defaultValue={formData?.invoice_date}
                        />
                    </FormGroup>
                </Col>          
                <Col sm="6">
                    <FormGroup>
                        <Input 
                            id="area_id" 
                            placeholder="Area"
                            type="select"
                            name='area_id'
                            onChange={handleInputsChange}
                            defaultValue={formData?.area_id}
                        >   
                            <option disabled>Selecciona el area usuaria</option>
                            {
                                areas.map(area => {
                                    return(
                                        <option value={area.id} key={area.id}>{area.name}</option>
                                    );
                                })
                            }
                        </Input>                                                                 
                    </FormGroup>
                </Col>
            </Row>
            <Row>   
                <Col sm='6'>
                    <FormGroup>
                        <Input
                            id="date"
                            placeholder="Fecha de ingreso (recepción)"
                            type="text"
                            name='date'
                            onChange={handleInputsChange}
                            onFocus={(e) => e.target.type='date'}
                            onBlur={(e) => e.target.type='text'}
                            defaultValue={formData?.date}
                        />
                    </FormGroup>
                </Col>
                <Col sm='6'>
                    <FormGroup>
                        <Input 
                            id="status" 
                            placeholder="Estado del pedido"
                            type="select"
                            name='status'
                            onChange={handleInputsChange}
                            defaultValue={formData?.status}
                        >   
                            <option disabled>Estado actual del pedido</option>
                            {
                                PURCHASE_ORDER_STATUSES.map((status) => {
                                    return(
                                        <option key={"po_status_"+status}>{status}</option>
                                    )
                                })
                            }
                        </Input>                                         
                    </FormGroup>
                </Col>
                <Col sm='6'>
                    <FormGroup>
                        <Input
                            id="ed_date"
                            placeholder="Fecha de entrega estimada"
                            type="text"
                            name='ed_date'
                            onChange={handleInputsChange}
                            onFocus={(e) => e.target.type='date'}
                            onBlur={(e) => e.target.type='text'}
                            defaultValue={formData?.ed_date}
                        />
                    </FormGroup>
                </Col>                                                    
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="notes"
                        >
                            Notas u observaciones
                        </label>
                        <Input
                            id="notes"
                            rows="3"
                            type="textarea"
                            name="notes"
                            placeholder="Agrega una nota adicional"
                            onChange={handleInputsChange}
                            defaultValue={formData?.notes}
                        />
                    </FormGroup>
                </Col>                                  
            </Row>
        </Form>
    );
}

const SuccessAlert = ({
    stateSetter: setAlert
}) => {
    return (
        <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Acción exitosa"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="success"
            // confirmBtnText="Ok"
            btnSize=""
            // timeout={2500}
        >
            Ingreso de reactivo registrado satisfactoriamente.
        </ReactBSAlert>
    );
}

const DangerAlert = ({
    stateSetter: setAlert
}) => {
    return (
        <ReactBSAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title="Ocurrió un error"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="danger"
            // confirmBtnText="Ok"
            btnSize=""
        >
            Ocurrió un error en el servidor.
        </ReactBSAlert>
    );
}

const WarningAlert = ({
    stateSetter: setAlert
}) => {
    return (
        <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Advertencia"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="warning"
            // confirmBtnText="Ok"
            btnSize=""
        >
            No se pudo registrar el ingreso de reactivo.
        </ReactBSAlert>
    );
}

const TableFilters = ({
    state: filterState,
    stateSetter: setFilterState,
    onSubmitFilter: handleSubmitFilter,
    onCleanFilter: handleCleanFilter
}) => {
    const size = "sm";
    // Set state depending on filter inputs
    const handleInputsChange = (e) => {
        if (e.target.name === 'statusCompleted' || 
            e.target.name === 'statusPending' || 
            e.target.name === 'statusRequested'
            ) {
            if (e.target.checked) {
                setFilterState({ ...filterState, [e.target.name]: e.target.value });
            } else {
                setFilterState({ ...filterState, [e.target.name]: '0' });
            }
        } else {
            setFilterState({ ...filterState, [e.target.name]: e.target.value });
        }
    }

    // Set special filters (buttons)
    const handleSetFilterRequestDate = (button) => {
        if (button === 'today') {
            setFilterState({
                ...filterState,
                requestStartDate: dateToISOFormat(new Date()),
                requestEndDate: dateToISOFormat(new Date()),
            });
        } else if (button === 'month') {
            setFilterState({
                ...filterState,
                requestStartDate: dateToISOFormat(new Date(new Date().setDate(1))),
                requestEndDate: dateToISOFormat(new Date()),
            });
        } else if (button === 'year') {
            setFilterState({
                ...filterState, 
                requestStartDate: `${new Date().getFullYear()}-01-01`,
                requestEndDate: dateToISOFormat(new Date()),  
            })
        }
    }

    return (
        <Card>
            <CardBody>
                <Form>
                    <h4>Fecha de Pedido</h4>
                    <FormGroup >
                        <ButtonGroup aria-label="Predeterminados" role="group">
                            <Button 
                                outline color="default" type="button" 
                                onClick={() => handleSetFilterRequestDate('today')}
                            >
                                Hoy
                            </Button>
                            <Button 
                                outline color="default" type="button" 
                                onClick={() => handleSetFilterRequestDate('month')}
                            >
                                Este Mes
                            </Button>
                            <Button 
                                outline color="default" type="button" 
                                onClick={() => handleSetFilterRequestDate('year')}
                            >
                                Este Año
                            </Button>
                        </ButtonGroup>
                    </FormGroup>
                    <FormGroup className="row">
                        <Label
                            className="form-control-label"
                            htmlFor="request-filter-start-date"
                            sm="2" lg='1' size={size}
                        >
                            Desde:
                        </Label>
                        <Col sm="6" lg='4'>
                            <Input
                                // defaultValue={filterState.requestDate.start}
                                value={filterState.requestStartDate}
                                min='2000-01-01'
                                name="requestStartDate"
                                id="request-filter-start-date"
                                type="date"
                                onChange={handleInputsChange}
                                size={size}
                            />
                        </Col>
                    </FormGroup>                                        
                    <FormGroup className="row"> 
                        <Label
                            className="form-control-label"
                            htmlFor="request-filter-end-date"
                            sm="2" lg='1' size={size}
                        >
                            Hasta:
                        </Label>
                        <Col sm="6" lg='4'>
                            <Input
                                // defaultValue={filterState.requestDate.end}
                                value={filterState.requestEndDate}
                                min='2000-01-01'
                                max={dateToISOFormat(new Date())}
                                name="requestEndDate"
                                id="request-filter-end-date"
                                type="date"
                                onChange={handleInputsChange}
                                size={size}
                            />
                        </Col>                                        
                    </FormGroup>
                    <hr/>
                    <h4>Estado del Pedido</h4>
                    <FormGroup>
                        <Row>
                            <Col xs='6' sm='3' lg='2'>
                                <div className="custom-control custom-checkbox mb-3">
                                    <input
                                        className="custom-control-input"
                                        name="statusCompleted"
                                        id="statusCompleted"
                                        type="checkbox"
                                        value='1'
                                        onChange={handleInputsChange}
                                        checked={filterState.statusCompleted === '1'}
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor="statusCompleted"
                                    >
                                        Entregado
                                    </label>
                                </div>
                            </Col>
                            <Col xs='6' sm='3' lg='2'>
                                <div className="custom-control custom-checkbox mb-3">
                                    <input
                                        className="custom-control-input"
                                        name="statusPending"
                                        id="statusPending"
                                        type="checkbox"
                                        value='1'
                                        onChange={handleInputsChange}
                                        checked={filterState.statusPending === '1'}
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor="statusPending"
                                    >
                                        Pendiente
                                    </label>
                                </div>
                            </Col>
                            <Col xs='6' sm='3' lg='2'>
                                <div className="custom-control custom-checkbox mb-3">
                                    <input
                                        className="custom-control-input"
                                        name="statusRequested"
                                        id="statusRequested"
                                        type="checkbox"
                                        value='1'
                                        onChange={handleInputsChange}
                                        checked={filterState.statusRequested === '1'}
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor="statusRequested"
                                    >
                                        Solicitado
                                    </label>
                                </div>
                            </Col>
                        </Row>
                    </FormGroup>
                    <Button
                        color="primary"
                        type="button"
                        onClick={handleSubmitFilter}
                        >
                        Aplicar
                    </Button>
                    <Button
                        outline
                        color="danger"
                        type="button"
                        onClick={handleCleanFilter}
                        >
                        <span className="btn-inner--icon mr-1">
                            <i className="fas fa-trash" />
                        </span>
                        <span className="btn-inner--text">Limpiar</span>
                    </Button>
                    
                </Form>
            </CardBody>
        </Card>
    )
}

export default ReagentsEntries;