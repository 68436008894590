/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

function DashboardFooter() {
  return (
    <>
      <Container fluid>
        <footer className="footer pt-0">
          <Row className="align-items-center justify-content-lg-between">
            <Col lg="6">
              <div className="copyright text-center text-lg-left text-muted">
                © {new Date().getFullYear()}{" "}
                <Link to='/' className="font-weight-bold ml-1">
                  Hypatia Management Hub
                </Link>
                {" "}
                v0.1.0
                {/* TODO: Ideally, version info should be loaded from a file 
                that is always updated before a new release */}
              </div>
            </Col>
            <Col lg="6">
              <Nav className="nav-footer justify-content-center justify-content-lg-end">
                <NavItem>
                  <NavLink
                    href="https://hypatia.com.pe/"
                    target="_blank"
                  >
                    Hypatia S.A.
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="https://hypatia.com.pe/acercadenosotros.html"
                    target="_blank"
                  >
                    ¿Quiénes somos?
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </footer>
      </Container>
    </>
  );
}

export default DashboardFooter;
