import React, { useEffect, useState, useRef } from "react";
// Import reactstrap components
import {
    Container,
    Modal,
    Table,
    Card, CardHeader, CardBody,
    Row, Col,
    Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText,
    Button,
    Label
} from "reactstrap";
// Import core components
import SimpleHeader from "components/Headers/SimpleHeader";
import RegisterTable from "components/Tables/RegisterTable";
// Import API services
import { 
    getAllReagents, getReagentById,
    getAllInitialBalances, addNewInitialBalance, getInitialBalanceByReagentId, deleteInitialBalanceByReagentId,
    getAllMeasurementUnits
} from "services/reagents";
import { unitsNamesMap } from "data/measurementUnits";

// Define constants
const COLUMN_NAMES = [
    'reagent_accounting_id',
    'initial_year',
    'amount',
    'unit_price_pen'
]

const TABLE_MAPPING = {
    reagent_accounting_id: { tableHeading: 'Cód. Reactivo' },
    initial_year: { tableHeading: 'Año' },
    amount: { tableHeading: 'Cantidad' },
    amount_units: { tableHeading: 'Unidades' },
    unit_price_pen: { tableHeading: 'Precio unit. S/' },
}

const ReagentsSettings = () => {

    // Declare states
    const [initialBalances, setInitialBalances] = useState([]);
    const [modifiedInitialBalances, setModifiedInitialBalances] = useState([]);
    const [columnFields, setColumnFields] = useState([]);
    // States for adding a new record
    const [newInitialBalance, setNewInitialBalance] = useState({});
    // Complementary data
    const [reagents, setReagents] = useState([]);
    const [measurementUnitsdata, setMeasurementUnitsData] = useState({});
    // Define modal states
        // Delete-modal states
    const [modalDeleteRecord, setModalDeleteRecord] = useState(false);
    const [deleteRecordData, setDeleteRecordData] = useState({});
    
    // Declare references
    const addNewRef = useRef(null);

    // Effect on first rendering
    useEffect(() => {
        // Fetch all initial balances
        fetchInitialBalances();
        // Fetch complementary data
        fetchReagents();
        fetchMeasurementUnits();
        // Set table columns
        setColumnFields(COLUMN_NAMES)
    }, []);

    // Effect on 'initialBalances' change
    useEffect(() => {
        let modifiedInitialBalancesList = initialBalances.map(record => ({
            ...record,
            reagent_id: record.reagent.id,
            reagent_accounting_id: record.reagent.accounting_code,
            amount: `${record.amount} ${unitsNamesMap[record.amount_units].shortName}`,
            amount_units: unitsNamesMap[record.amount_units].shortName,
            unit_price_pen: record.unit_price_pen.toFixed(2)
        }));

        setModifiedInitialBalances(modifiedInitialBalancesList);
    }, [initialBalances])
    
    /**
     * Utility functions
     */
    const fetchInitialBalances = () => {
        getAllInitialBalances()
            .then(data => {
                if (data.success) {
                    setInitialBalances(data.initial_balances);
                } else {
                    console.log(data.message);
                }
            })
    }

    const fetchReagents = () => {
        getAllReagents()
            .then(data => {
                if (data.success) {
                    setReagents(data.reagents);
                } else {
                    console.log(data.message);
                }
            })
    }

    const fetchMeasurementUnits = () => {
        getAllMeasurementUnits()
            .then(data => {
                if (data.success) {
                    setMeasurementUnitsData(data.units);
                } else {
                    console.log(data.message);
                }
            })
    }

    /**
     * Handlers
     */
    // Add New button handler
    const handleClickAddNew = () => {
        // Scroll form into view
        addNewRef?.current.scrollIntoView({ behavior: 'smooth' });
    }
    // Handler for inputs change for 
    const handleInputsChange = (e) => {
        e.preventDefault();
        // Update new initial balance state
        setNewInitialBalance({ ...newInitialBalance, [e.target.name]: e.target.value });
    }

    // Handler to add a new record
    const handleSubmitNew = () => {
        addNewInitialBalance(newInitialBalance)
            .then(data => {
                if (data.success) {
                    console.log(data.message);
                    // Fetch initial balances to update table
                    fetchInitialBalances();
                    // TODO: notify user of successful operation
                } else {
                    console.log(data.message);
                }
            })
    }

    // Handler to open 'delete' modal
    const handleOpenModalDelete = (e, reagentId) => {
        e.preventDefault();
        // Fetch reagent data to show in modal
        getInitialBalanceByReagentId(reagentId)
            .then(data => {
                if (data.success) {
                    setDeleteRecordData(data.initial_balance);
                    // Activate modal 
                    setModalDeleteRecord(true);                    
                } else {
                    console.log(data.message);
                }
            })
            .catch(error => console.log)

    }

    const handleDeleteRecord = (reagentId) => {
        deleteInitialBalanceByReagentId(reagentId)
            .then(data => {
                if (data.success) {
                    // Fetch initial balances again
                    fetchInitialBalances();
                    // Close delete modal
                    setModalDeleteRecord(false);
                    // Clean state of deleted record
                    setDeleteRecordData({});
                } else {
                    console.log(data.message);
                    alert("No es posible eliminar el balance inicial.");
                }
            })
            .catch(error => {
                alert("Ocurrió un error en el servidor.");
                console.log(error);
            })
    }

    // Header customization variables
    const headerButtons = [
        { handler: handleClickAddNew, className: 'btn-neutral', color: 'default', content: '+ Nuevo' },
    ];

    // RegisterTable customization variables
    const inlineActions = [
        // { text: 'Editar', handler: handleOpenModalEdit, property: 'id', icon: 'fas fa-edit' },
        { text: 'Eliminar', handler: handleOpenModalDelete, property: 'reagent_id', icon: 'fas fa-trash' },
    ]
    return (
        <>
        <SimpleHeader 
            name="Reactivos" 
            parentName="Ajustes"
            buttons={headerButtons}
        />
        <Container className="mt--6" fluid>
            <RegisterTable
                tableTitle="Balances Iniciales"
                columnKeys={columnFields}
                records={modifiedInitialBalances}
                tableMapping={TABLE_MAPPING}
                inlineActions={inlineActions}
            />
            <Card className="mb-4">
                <CardHeader>
                    <h3 ref={addNewRef}>Registrar nuevo Balance Inicial</h3>
                </CardHeader>
                <CardBody>
                    <InitialBalanceForm
                        onInputsChange={handleInputsChange}
                        onClickConfirm={handleSubmitNew}
                        reagentsData={reagents}
                        measurementUnitsData={measurementUnitsdata}
                        withButton
                    />
                </CardBody>
            </Card>
            <DeleteInitialBalanceModal
                modalDeleteRecord={modalDeleteRecord}
                setModalDeleteRecord={setModalDeleteRecord}
                recordData={deleteRecordData}
                onClickConfirm={handleDeleteRecord}
            />
        </Container>
        </>
    )
}

const DeleteInitialBalanceModal = ({
    modalDeleteRecord, setModalDeleteRecord,
    recordData,
    onClickConfirm: handleDeleteRecord
}) => {

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={modalDeleteRecord}
            toggle={() => setModalDeleteRecord(false)}                
        >
            <div className="modal-header">
                <h3 className="modal-title">
                    ¿Borrar registro de reactivo?
                </h3>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalDeleteRecord(false)}
                >
                    <span aria-hidden={true}>×</span>
                </button>                      
            </div>
            <div className="modal-body">
                <p>
                    Vas a eliminar el siguiente <strong>Reactivo</strong>:
                </p>
                <Table responsive>
                    <tbody>
                        <tr>
                            <td>Reactivo:</td>
                            <td>{recordData?.reagent?.accounting_code}</td>
                        </tr>
                        <tr>
                            <td>Año de balance: </td>
                            <td>{recordData?.initial_year}</td>
                        </tr>
                        <tr>
                            <td>Cantidad: </td>
                            <td>{recordData?.amount} {unitsNamesMap[recordData?.amount_units]?.shortName}</td>
                        </tr>
                        <tr>
                            <td>Precio unitario: </td>
                            <td>S/ {recordData?.unit_price_pen}</td>
                        </tr>
                    </tbody>
                </Table>                     
                <p className="mt-4">Esta acción es irreversible.</p>
            </div>
            <div className="modal-footer">
                <Button
                    color="danger"
                    type="button"
                    onClick={() => handleDeleteRecord(recordData.reagent.id)}
                >
                    Sí, eliminar
                </Button>
                <Button
                    className="ml-auto"
                    color="link"
                    type="button"
                    onClick={() => setModalDeleteRecord(false)}
                >
                    Cancelar
                </Button>
            </div>
        </Modal>           
    )
}

const InitialBalanceForm = ({
    onInputsChange: handleInputsChange,
    onClickConfirm: handleClickConfirm,
    defaultData = {},
    reagentsData,
    measurementUnitsData,
    withButton = false
}) => {
    // States
    const [selectedReagentId, setSelectedReagentId] = useState(null);
    const [selectedReagentBaseUnit, setSelectedReagentBaseUnit] = useState('');
    
    useEffect(() => {
        if (defaultData.hasOwnProperty('reagent_id')) {
            setSelectedReagentId(defaultData.reagent_id);
        }
    }, []);

    useEffect(() => {
        // Fetch reagent data if selected
        if (selectedReagentId) {
            getReagentById(selectedReagentId)
                .then(data => {
                    if (data.success) {
                        setSelectedReagentBaseUnit(
                            measurementUnitsData[data.reagent.format_size_unit].base_unit
                        )
                    } else {
                        console.log(data.message);
                    }
                })
        }
    }, [selectedReagentId]);

    useEffect(() => {
        if (selectedReagentBaseUnit) {
            // Set measurement units for the new initial balance
            // An onChange event needs to be emulated
            handleInputsChange({
                target: {
                    name: 'amount_units',
                    value: selectedReagentBaseUnit
                },
                preventDefault: () => {}
            });
        }
    }, [selectedReagentBaseUnit]);

    const handleSelectReagent = (e) => {
        setSelectedReagentId(e.target.value);
    }

    return (
        <Form>
            <Row>
                <Col md='8'>
                    <FormGroup>
                        <Label
                            className="form-control-label"
                            for="reagent-id-input"
                        >
                            Reactivo:
                        </Label>
                        <Input
                            name="reagent_id"
                            id="reagent-id-input"
                            type="select"
                            defaultValue={defaultData?.reagent_id || ''}
                            onChange={(e) => {
                                handleInputsChange(e);
                                handleSelectReagent(e);
                            }}
                        >
                            <option disabled value=''>Selecciona un Reactivo</option>
                            {
                                reagentsData.map(reagent => (
                                    <option value={reagent.id} key={reagent.id}>
                                        {reagent.accounting_code} - {reagent.description}
                                    </option>
                                ))
                            }
                        </Input>
                    </FormGroup>                
                </Col>
                <Col sm='6' md='4'>
                    <FormGroup>
                        <Label
                            className="form-control-label"
                            for="initial-year-input"
                        >
                            Año:
                        </Label>
                        <Input
                            type="number"
                            name="initial_year"
                            id="initial-year-input"
                            min={2000}
                            placeholder="Año de inicio"
                            defaultValue={defaultData?.initial_year}
                            onChange={handleInputsChange}
                        />
                    </FormGroup>
                </Col>
                <Col sm='6'>
                    <FormGroup>
                        <Label
                            className="form-control-label"
                            for="amount-input"
                        >
                            Balance:
                        </Label>
                        <InputGroup>
                            <Input
                                type="number"
                                name="amount"
                                id="amount-input"
                                min={0}
                                placeholder="Cantidad al final del año"
                                defaultValue={defaultData?.amount}
                                onChange={handleInputsChange}
                            />
                            <InputGroupAddon addonType="append">
                                <InputGroupText>
                                    <small className="font-weight-bold">
                                        {selectedReagentBaseUnit ? unitsNamesMap[selectedReagentBaseUnit]?.name : '---'}
                                    </small>
                                </InputGroupText>
                            </InputGroupAddon>                            
                        </InputGroup>
                    </FormGroup>
                </Col>      
                <Col sm='6'>
                    <FormGroup>
                        <Label
                            className="form-control-label"
                            for="unit-price-pen-input"
                        >
                            Precio unitario:
                        </Label>
                        <InputGroup
                        >
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <small className="font-weight-bold">S/</small>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type="number"
                                name="unit_price_pen"
                                id="unit-price-pen-input"
                                min={0}
                                placeholder="Precio unit. al final del año"
                                defaultValue={defaultData?.unit_price_pen}
                                onChange={handleInputsChange}
                            />
                        </InputGroup>                   
                    </FormGroup>
                </Col>
            </Row>
            {
                withButton ? (
                    <Button
                        color="primary"
                        type="button"
                        onClick={handleClickConfirm}
                    >
                        Registrar
                    </Button>
                ) : null
            }

        </Form>
    )
}

export default ReagentsSettings;