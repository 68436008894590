import React, { useContext, useState } from "react";

// Import reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Container,
} from "reactstrap";

// My components
import SimpleHeader from "components/Headers/SimpleHeader";

// Import contexts
import { CurrentUserContext } from 'containers/App'

const HomePage = () => {
    // Load current user state from context
    const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
    
    return (
    <>
        <SimpleHeader 
            name="Home" 
            parentName="Hypatia Hub"
        />
        <Container className="mt--6" fluid>
            <Card>
                <CardHeader>
                    <h3>Hypatia Hub Dashboard</h3>
                </CardHeader>
                <CardBody>
                    <p> 
                        Bienvenido {currentUser.firstname}
                    </p>
                </CardBody>
            </Card>
        </Container>
    </>
    );
}

export default HomePage;