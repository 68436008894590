import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

// Import reactstrap components
import {
    Alert,
    Button,
    Card,
    CardHeader,
    CardBody,
    Form,
    Input,
    Table,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
    FormGroup,
    Modal,
} from "reactstrap";
// React Sweet Alert plugin
import ReactBSAlert from "react-bootstrap-sweetalert";
// Core components
import SimpleHeader from "components/Headers/SimpleHeader";
// import RegisterTable from "components/Tables/RegisterTable";
import RegisterTableFull from "components/Tables/RegisterTableFull";
// Import services
import {
    getAllReagents, addNewReagent, addNewReagentsBatch, getReagentById, updateReagentById, deleteReagentById,
    getAllMeasurementUnits
} from "services/reagents";
// Import additional data
import { unitsNamesMap } from "data/measurementUnits";

const tableMapping = {
    accounting_code: { tableHeading: 'Cód.' },
    lab_code: { tableHeading: 'Cód. Lab' },
    brand: { tableHeading: 'Marca' },
    brand_code: { tableHeading: 'Código' },
    description: { tableHeading: 'Descripción' },
    format: { tableHeading: 'Presentación' },
    format_size: { tableHeading: 'Cant. de presentacion' },
    format_size_unit: { tableHeading: 'Unidad' },
    format_size_and_unit: { tableHeading: 'Cant. de presentación' },
}    

// const COLUMN_NAMES_ORDERED = [
//     'accounting_code',
//     'lab_code',
//     'brand',
//     'brand_code',
//     'description',
//     'format',
//     'format_size_and_unit',
// ]

const ReagentsRegister = () => {
    // Load history
    const history = useHistory();

    // Load references
    const addNewRef = useRef(null);

    // Declare states
    const [reagents, setReagents] = useState([]);
    const [modifiedReagents, setModifiedReagents] = useState([]);
    const [newReagent, setNewReagent] = useState({});
    const [columnKeys, setColumnKeys] = useState([]);
    const [measurementUnits, setMeasurementUnits] = useState([]);

    // Modal states
    const [modalImport, setModalImport] = useState(false);
    const [importFile, setImportFile] = useState({});
    const [importFileError, setImportFileError] = useState(false);
        // Delete Record Modal states
    const [modalDeleteRecord, setModalDeleteRecord] = useState(false);
    const [recordToDeleteData, setRecordToDeleteData] = useState({});
        // Edit Record Modal states
    const [modalEditRecord, setModalEditRecord] = useState(false);
    const [recordToEditData, setRecordToEditData] = useState({});
    const [updatedData, setUpdatedData] = useState({});
    // Alert states
    const [alertContent, setAlertContent] = useState(null);

    useEffect(() => {
        // Fetch reagents from server at first render
        fetchReagents();
        fetchMeasurementUnits();
    }, [])

    useEffect(() => {
        let modifiedReagentsList = reagents.map(reagent => {
            return {
                ...reagent,
                format_size_and_unit: `${reagent.format_size} ${reagent.format_size_unit}`
            }
        });
        setModifiedReagents(modifiedReagentsList);
    }, [reagents])

    // Utility functions
    const fetchReagents = () => {
        // Fetch reagents
        getAllReagents()
            .then(data => {
                if (data.success) {
                    setReagents(data.reagents);
                    // Get column keys
                    let colKeys = data.table.column_keys;
                    colKeys.splice(colKeys.indexOf('id'), 1);
                    setColumnKeys(colKeys);
                    // setColumnKeys(COLUMN_NAMES_ORDERED);
                } else {
                    console.log(data.message);
                }
            });
    }

    const fetchMeasurementUnits = () => {
        getAllMeasurementUnits()
            .then(data => {
                if (data.success){
                    setMeasurementUnits(Object.keys(data.units));
                } else {
                    console.log(data.message);
                }
            })
    }
    // Handlers
    const handleInputsChange = (e) => {
        // Update newReagent state
        setNewReagent({ ...newReagent, [e.target.name]: e.target.value });
    }
    
    const handleReagentSubmit = () => {
        // POST newReagent to server   
        addNewReagent(newReagent)
            .then(data => {
                if (data.success) {
                    console.log(data.message);
                    // Alert user of successful action
                    setAlertContent(<SuccessAlert stateSetter={setAlertContent} />);                    
                    // Fetch reagents from server to update table
                    fetchReagents();
                } else {
                    // Alert the user of unsuccessful action
                    setAlertContent(<WarningAlert stateSetter={setAlertContent} />);
                    console.log("Server:", data.message);
                }
            })
            .catch(error => {
                // Alert about the error
                setAlertContent(<DangerAlert stateSetter={setAlertContent} />);
            });
    }

    const handleClickViewDetails = (e, reagent_ac) => {
        e.preventDefault();
        // Navigate to reagent's detail page
        history.push(`/reagent/${reagent_ac}`);
    }
    const handleClickViewAccounting = (e, reagent_ac) => {
        e.preventDefault();
        // Navigate to reagent's accounting page
        history.push(`/reagent/${reagent_ac}/accounting`);
    }
    // Handler for importing file form
    const handleImportFileChange = (e) => {
        // Save selected file in a state
        setImportFile({ name: e.target.name, object: e.target.files[0] });
        // Reset the error state
        setImportFileError(false);
    }
    // Handler for submitting file
    const handleImportFileSubmit = () => {
        if (!importFile.object) {
            // TODO: Add form validation instead of an alert
            alert("No has seleccionado ningun archivo."); 
            return;
        }
        console.log(`Submiting file ${importFile.object.name} ...`);
        const formData = new FormData();
        formData.append(importFile.name, importFile.object);
        // POST file to server
        addNewReagentsBatch(formData)
            .then(data => {
                if (data.success) {
                    console.log(data.message);
                    // Fetch reagents from server to update table
                    fetchReagents();
                    // Close modal windows
                    setModalImport(false);
                    // Notify user
                    alert(`${data.total_added} reactivo(s) añadido(s) al registro.`)
                } else {
                    setImportFileError(true);
                    console.log(data.message);
                }
            })
    }
    // Handler to open 'delete' modal
    const handleOpenModalDelete = (e, id) => {
        e.preventDefault();
        // Fetch reagent data to show in modal
        getReagentById(id)
            .then(data => {
                if (data.success) {
                    setRecordToDeleteData(data.reagent);
                    // Activate modal 
                    setModalDeleteRecord(true);                    
                }
            })
            .catch(error => console.log)

    }
    // Handler to open 'edit' modal
    const handleOpenModalEdit = (e, id) => {
        e.preventDefault();
        // Empty updatedData state
        setUpdatedData({});
        // Fetch reagent data to show in modal
        getReagentById(id)
            .then(data => {
                if (data.success) {
                    setRecordToEditData(data.reagent);
                    // Activate modal 
                    setModalEditRecord(true);                          
                } else {
                    console.log(data.message);
                }
            })
            .catch(error => {
                alert("Ocurrió un error en el servidor.")
                console.log(error);
            })       
    }

    const handleClickAddNew = (e) => {
        e.preventDefault();
        // Scroll into view
        addNewRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const handleDeleteRecord = () => {
        deleteReagentById(recordToDeleteData.id)
            .then(data => {
                if (data.success) {
                    // Close modal
                    setModalDeleteRecord(false);
                    // Fetch reagents to update table
                    fetchReagents();
                    // Clean data state of deleted record                 
                    setRecordToDeleteData({});
                } else {
                    alert("No es posible eliminar el Reactivo");
                }
            })
            .catch(error => {
                alert("Ocurrió un error en el servidor.");
                console.log(error);
            });
    }

    const handleEditInputsChange = (e) => {
        // Update data that will replace the existing one
        setUpdatedData({
            ...updatedData, [e.target.name]: e.target.value
        });
    }

    const handleUpdateRecord = (e) => {
        // Request a modification to the server
        updateReagentById(recordToEditData.id, updatedData)
            .then(data => {
                if (data.success) {
                    // Close modal
                    setModalEditRecord(false);
                    // Fetch reagents to update table
                    fetchReagents();
                    // Clean data state of updated record
                    setRecordToEditData({});
                } else {
                    console.log(data.message);
                    alert("No es posible modificar el Reactivo");
                }
            })
            .catch(error => {
                alert("Ocurrió un error en el servidor.");
                console.log(error);
            });
    }


    // Header customization variables
    const headerButtons = [
        { handler: handleClickAddNew, className: 'btn-neutral', color: 'default', content: '+ Nuevo' },
    ];
    // RegisterTable customization variables
    const dropdownMenu = {
        buttons: [
            { text: "Ver contabilidad", handler: handleClickViewAccounting, property: 'accounting_code' },
            // { text: "Ver detalles", handler: handleClickViewDetails, property: 'accounting_code' },
            { text: "Editar", handler: handleOpenModalEdit, property: 'id' },
            // { text: "Eliminar", handler: handleOpenModalDelete, property: 'id' },
        ]
    }
    // RegisterTable customization variables
    const inlineActions = [
        { text: 'Editar', handler: handleOpenModalEdit, property: 'id', icon: 'fas fa-edit' },
        { text: 'Eliminar', handler: handleOpenModalDelete, property: 'id', icon: 'fas fa-trash' },
    ]

    // Render component
    return (
        <>
        <SimpleHeader 
            name="Registro" 
            parentName="Reactivos"
            buttons={headerButtons}
        />
        <Container className="mt--6" fluid>
            {/* Render table to display all reagents */}
            <RegisterTableFull
                records={modifiedReagents}
                columnKeys={columnKeys}
                tableMapping={tableMapping}
                tableTitle="Lista de Reactivos"
                inlineActions={inlineActions}
                dropdownMenu={dropdownMenu}
            />
            {/* <RegisterTable
                columnKeys={columnKeys}
                records={modifiedReagents}
                tableMapping={tableMapping}
                tableTitle="Lista de Reactivos"
                dropdownMenu={dropdownMenu}
                inlineActions={inlineActions}
                size='sm'
            />             */}
            <DeleteReagentModal
                modalDeleteRecord={modalDeleteRecord}
                setModalDeleteRecord={setModalDeleteRecord}
                recordData={recordToDeleteData}
                onClickConfirm={handleDeleteRecord}
            />
            <EditReagentModal
                modalEditRecord={modalEditRecord}
                setModalEditRecord={setModalEditRecord}
                recordData={recordToEditData}
                onClickConfirm={handleUpdateRecord}
                onInputsChange={handleEditInputsChange}
            />
            {/* Render form for adding new reagents */}
            <Card className="mb-4">
                <CardHeader>
                    <Row>
                        <Col>
                            <h3 ref={addNewRef} >Registrar Nuevo Reactivo</h3>
                        </Col>
                        <Col className="text-right" xs="6">
                            <Button
                                className="btn-round btn-icon"
                                color="primary"
                                id="tooltip443214080"
                                onClick={() => {
                                    setModalImport(true); 
                                    setImportFile({});
                                    setImportFileError(false);
                                }}
                                size="sm"
                            >
                                <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-file-import" />
                                </span>
                                <span className="btn-inner--text">Importar</span>
                            </Button>
                            <UncontrolledTooltip delay={0} target="tooltip443214080">
                                Importación masiva de datos
                            </UncontrolledTooltip>
                            {/* Render modal for batch import file*/}
                            <Modal
                                className="modal-dialog-centered"
                                isOpen={modalImport}
                                toggle={() => setModalImport(false)}
                            >
                                <div className="modal-header">
                                    <h3 className="modal-title">
                                        Importación masiva de reactivos
                                    </h3>
                                    <button
                                        aria-label="Close"
                                        className="close"
                                        data-dismiss="modal"
                                        type="button"
                                        onClick={() => setModalImport(false)}
                                    >
                                        <span aria-hidden={true}>×</span>
                                    </button>                                                                       
                                </div>
                                <div className="modal-body">
                                    <p>
                                        Puedes importar varios reactivos a la vez con un archivo de valores separados por coma (.csv):
                                    </p>
                                    <Form>
                                        <FormGroup>
                                            <div className="custom-file">
                                                <input
                                                    className="custom-file-input"
                                                    id="batchImportFile"
                                                    lang="es"
                                                    type="file"
                                                    name="import-file"
                                                    accept="text/csv"
                                                    onChange={handleImportFileChange}
                                                />
                                                <label
                                                    className="custom-file-label"
                                                    htmlFor="batchImportFile"
                                                >
                                                    Seleccionar archivo
                                                </label>
                                            </div>                                                 
                                        </FormGroup>
                                    </Form>
                                    <Alert isOpen={importFileError} color="danger">
                                        <strong>¡Alerta!</strong> Error al procesar el archivo.
                                    </Alert>

                                </div>
                                <div className="modal-footer">
                                    <Button
                                        className="new-event--add"
                                        color="primary"
                                        type="button"
                                        onClick={handleImportFileSubmit}
                                    >
                                        Subir datos
                                    </Button>
                                    <Button
                                        className="ml-auto"
                                        color="link"
                                        type="button"
                                        onClick={() => setModalImport(false)}
                                    >
                                        Cancelar
                                    </Button>
                                </div>                                    
                            </Modal>
                        </Col>                            
                    </Row>
                </CardHeader>
                <CardBody>
                    <Form>
                        <Row>
                            <Col sm="6">
                                <FormGroup>
                                    <Input
                                        id="accounting_code"
                                        placeholder="Código contable"
                                        type="number"
                                        name='accounting_code'
                                        onChange={handleInputsChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    <Input
                                        id="lab_code"
                                        placeholder="Código de laboratorio"
                                        type="text"
                                        name='lab_code'
                                        onChange={handleInputsChange}
                                    />
                                </FormGroup>    
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='6'>
                                <FormGroup>
                                    <Input
                                        id="brand"
                                        placeholder="Marca"
                                        type="text"
                                        name='brand'
                                        onChange={handleInputsChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm='6'>
                                <FormGroup>
                                    <Input
                                        id="brand_code"
                                        placeholder="Código de la marca"
                                        type="text"
                                        name='brand_code'
                                        onChange={handleInputsChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Input
                                        id="description"
                                        placeholder="Descripción"
                                        type="text"
                                        name='description'
                                        onChange={handleInputsChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="4">
                                <FormGroup>
                                    <Input
                                        id="format"
                                        placeholder="Presentación"
                                        type="text"
                                        name='format'
                                        onChange={handleInputsChange}
                                    />                                
                                </FormGroup>
                            </Col>
                            <Col sm="4">
                                <FormGroup>
                                    <Input
                                        id="format_size"
                                        placeholder="Cantidad de la presentación"
                                        type="number"
                                        name='format_size'
                                        onChange={handleInputsChange}
                                    />                                
                                </FormGroup>
                            </Col>
                            <Col sm="4">
                                <FormGroup>
                                    <Input 
                                        id="size_unit" 
                                        placeholder="Unidad de medida de la presentación"
                                        type="select"
                                        name='format_size_unit'
                                        onChange={handleInputsChange}
                                        defaultValue=''
                                    >
                                        <option value='' disabled>Selecciona la unidad de medida</option>
                                        {
                                            measurementUnits.map(unit => {
                                                return (
                                                    <option key={`unit-${unit}`} value={unit}>
                                                        {unitsNamesMap[unit].shortName} - {unitsNamesMap[unit].name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </Input>                                                                 
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className="text-left">
                            <Button
                                color="primary"
                                type="button"
                                onClick={handleReagentSubmit}
                                >
                                Registrar
                            </Button>
                        </div>
                    </Form>
                </CardBody>
            </Card>
        </Container>
        {alertContent}        
        </>
    );
}

const DeleteReagentModal = ({
    modalDeleteRecord, setModalDeleteRecord,
    recordData,
    onClickConfirm: handleDeleteRecord
}) => {

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={modalDeleteRecord}
            toggle={() => setModalDeleteRecord(false)}                
        >
            <div className="modal-header">
                <h3 className="modal-title">
                    ¿Borrar registro de reactivo?
                </h3>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalDeleteRecord(false)}
                >
                    <span aria-hidden={true}>×</span>
                </button>                      
            </div>
            <div className="modal-body">
                <p>
                    Vas a eliminar el siguiente <strong>Reactivo</strong>:
                </p>
                <Table responsive>
                    <tbody>
                        <tr>
                            <td>Código contable:</td>
                            <td>{recordData?.accounting_code}</td>
                        </tr>
                        <tr>
                            <td>Código de laboratorio: </td>
                            <td>{recordData?.lab_code}</td>
                        </tr>
                        <tr>
                            <td>Marca y Código de proveedor: </td>
                            <td>{recordData?.brand} - {recordData?.brand_code}</td>
                        </tr>
                        <tr>
                            <td>Descripción: </td>
                            <td>{recordData?.description}</td>
                        </tr>
                        <tr>
                            <td>Presentación: </td>
                            <td>{recordData?.format} - {recordData?.format_size} {recordData?.format_size_unit}</td>
                        </tr>
                    </tbody>
                </Table>                     
                <p className="mt-4">Esta acción es irreversible.</p>
            </div>
            <div className="modal-footer">
                <Button
                    // className="new-event--add"
                    color="danger"
                    type="button"
                    onClick={handleDeleteRecord}
                >
                    Sí, eliminar
                </Button>
                <Button
                    className="ml-auto"
                    color="link"
                    type="button"
                    onClick={() => setModalDeleteRecord(false)}
                >
                    Cancelar
                </Button>
            </div>
        </Modal>           
    )
}

const EditReagentModal = ({
    modalEditRecord, setModalEditRecord,
    recordData,
    onClickConfirm: handleClickConfirm,
    onInputsChange: handleInputsChange
}) => {
    const [allowedUnits, setAllowedUnits] = useState([]);

    useEffect(() => {
        // Clear state of allowed units
        // (avoids loading the form with incompatible alloweds units - effect of 'defaultValue')        
        setAllowedUnits([]);
        // Get allowed units for given reagent
        if (recordData.id) {
            getReagentById(recordData.id)
                .then(data => {
                    if (data.success) {
                        setAllowedUnits(data.reagent.allowed_units);
                    } else {
                        console.log(data.message);
                    }
                })
        }
    }, [recordData.id])

    // Handlers
    const handleCloseModal = () => {
        setModalEditRecord(false);
    }

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={modalEditRecord}
            toggle={handleCloseModal}
            size="lg"           
        >
            <div className="modal-header">
                <h3 className="modal-title">
                    Editar datos de Reactivo
                </h3>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={handleCloseModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>                      
            </div>
            <div className="modal-body">
                <p>
                    Puedes modificar el siguiente <strong>Reactivo</strong>:
                </p>
                <ReagentForm
                    onInputsChange={handleInputsChange}
                    data={recordData}
                    measurementUnits={allowedUnits}
                />             
                {/* <p className="mt-4">Verifica que los datos sean correctos.</p> */}
            </div>
            <div className="modal-footer">
                <Button
                    // className="new-event--add"
                    color="info"
                    type="button"
                    onClick={handleClickConfirm}
                >
                    Guardar
                </Button>
                <Button
                    className="ml-auto"
                    color="link"
                    type="button"
                    onClick={handleCloseModal}
                >
                    Cancelar
                </Button>
            </div>
        </Modal>           
    )
}


const ReagentForm = ({
    onInputsChange: handleInputsChange,
    data: formData,
    measurementUnits
}) => {

    if (measurementUnits.length === 0) {
        return (
            <>
                Cargando formulario...
            </>
        )
    }
    return (
        <Form>
            <Row>
                <Col sm="6">
                    <FormGroup>
                        <Input
                            id="accounting_code"
                            placeholder="Código contable"
                            type="number"
                            name='accounting_code'
                            defaultValue={formData?.accounting_code}
                            onChange={handleInputsChange}
                        />
                    </FormGroup>
                </Col>
                <Col sm="6">
                    <FormGroup>
                        <Input
                            id="lab_code"
                            placeholder="Código de laboratorio"
                            type="text"
                            name='lab_code'
                            defaultValue={formData?.lab_code}
                            onChange={handleInputsChange}
                        />
                    </FormGroup>    
                </Col>
            </Row>
            <Row>
                <Col sm='6'>
                    <FormGroup>
                        <Input
                            id="brand"
                            placeholder="Marca"
                            type="text"
                            name='brand'
                            defaultValue={formData?.brand}
                            onChange={handleInputsChange}
                        />
                    </FormGroup>
                </Col>
                <Col sm='6'>
                    <FormGroup>
                        <Input
                            id="brand_code"
                            placeholder="Código de la marca"
                            type="text"
                            name='brand_code'
                            defaultValue={formData?.brand_code}
                            onChange={handleInputsChange}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Input
                            id="description"
                            placeholder="Descripción"
                            type="text"
                            name='description'
                            defaultValue={formData?.description}
                            onChange={handleInputsChange}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm="4">
                    <FormGroup>
                        <Input
                            id="format"
                            placeholder="Presentación"
                            type="text"
                            name='format'
                            defaultValue={formData?.format}
                            onChange={handleInputsChange}
                        />                                
                    </FormGroup>
                </Col>
                <Col sm="4">
                    <FormGroup>
                        <Input
                            id="format_size"
                            placeholder="Cantidad de la presentación"
                            type="number"
                            name='format_size'
                            defaultValue={formData?.format_size}
                            onChange={handleInputsChange}
                        />                                
                    </FormGroup>
                </Col>
                <Col sm="4">
                    <FormGroup>
                        <Input 
                            id="size_unit" 
                            placeholder="Unidad de medida de la presentación"
                            type="select"
                            name='format_size_unit'
                            defaultValue={formData?.format_size_unit}
                            onChange={handleInputsChange}
                        >
                            <option value=''>Selecciona la unidad de medida</option>
                            {
                                measurementUnits.map(unit => {
                                    return (
                                        <option key={`unit-${unit}`} value={unit}>
                                            {unitsNamesMap[unit].shortName} - {unitsNamesMap[unit].name}
                                        </option>
                                    )
                                })
                            }

                        </Input>                                                                 
                    </FormGroup>
                </Col>
            </Row>
        </Form>        
    )
}

const SuccessAlert = ({
    stateSetter: setAlert
}) => {
    return (
        <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Acción exitosa"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="success"
            // confirmBtnText="Ok"
            btnSize=""
            // timeout={2500}
        >
            Reactivo registrado satisfactoriamente.
        </ReactBSAlert>
    );
}

const DangerAlert = ({
    stateSetter: setAlert
}) => {
    return (
        <ReactBSAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title="Ocurrió un error"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="danger"
            // confirmBtnText="Ok"
            btnSize=""
        >
            Ocurrió un error en el servidor.
        </ReactBSAlert>
    );
}

const WarningAlert = ({
    stateSetter: setAlert
}) => {
    return (
        <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Advertencia"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="warning"
            // confirmBtnText="Ok"
            btnSize=""
        >
            No se pudo registrar el reactivo.
        </ReactBSAlert>
    );
}

export default ReagentsRegister;