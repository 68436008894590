/**
 * Data regarding measurement units, to be used by some of the reagents' forms
 */

export const unitsNamesMap = {
    mL: {
        shortName: 'mL',
        name: 'mililitros',
    },
    L: {
        shortName: 'L',
        name: 'Litros',
    },
    g: {
        shortName: 'g',
        name: 'gramos',
    },
    Kg: {
        shortName: 'Kg',
        name: 'Kilogramos',
    },
    unit: {
        shortName: 'unid',
        name: 'unidades'
    },
};