import React, { useState } from "react";

// Import reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
} from "reactstrap";

const RegisterTable = (props) => {
    const {
        columnKeys, 
        tableMapping, 
        records, 
        tableTitle, 
        size = "",
        inlineActions,
        dropdownMenu
    } = props;

    return(
        <Card className="mb-4">
            <CardHeader className="border-0">
                <Row>
                    <Col xs="6">
                        <h3 className="mb-0">{tableTitle}</h3>
                    </Col>
                    <Col className="text-right" xs="6">
                        <Button
                            className="btn-round btn-icon"
                            color="primary"
                            href="#hypatia"
                            id="tooltip443412080"
                            onClick={(e) => e.preventDefault()}
                            size="sm"
                        >
                            <span className="btn-inner--icon mr-1">
                                <i className="fas fa-file-export" />
                            </span>
                            <span className="btn-inner--text">Exportar</span>
                        </Button>
                        <UncontrolledTooltip delay={0} target="tooltip443412080">
                        Exportar tabla
                        </UncontrolledTooltip>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <Table 
                    className="align-items-center table-flush" 
                    responsive 
                    hover 
                    size={size}
                >
                    <thead className="thead-light">
                    <tr>
                        {
                            // Add 'th' spacer for dropdown menu
                            dropdownMenu ? <th /> : null
                        }
                        {
                            // Add table header cells
                            columnKeys.map(column => {
                                // Return the table header for each column
                                return(
                                    <th key={`th${column}`}>
                                        { Object.keys(tableMapping).includes(column) ? tableMapping[column].tableHeading : column }
                                    </th>
                                )
                            })
                        }
                        {
                            // Add 'th' spacer for inline actions
                            inlineActions ? <th />  : null
                        }
                    </tr>
                    </thead>
                    
                    <tbody>
                    {
                        records.map((record) => 
                            // Loop through each data record and return a table row
                            <tr key={`trow${record.id}`}>
                                {
                                    // Add dropdown menu, if required
                                    dropdownMenu ? (
                                    <td className="pl-1 pr-0">
                                        <UncontrolledDropdown>
                                            <DropdownToggle
                                                className="btn-icon-only text-light"
                                                color=""
                                                role="button"
                                                size="sm"
                                            >
                                            <i className="fas fa-ellipsis-v" />
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-arrow" right>
                                                {
                                                    dropdownMenu.buttons.map(button => 
                                                        <DropdownItem
                                                            onClick={(e) => button.handler(e, record[button.property])}
                                                            key={`${button.text}`}
                                                        >
                                                            {button.text}
                                                        </DropdownItem>    
                                                    )
                                                }
                                            </DropdownMenu>
                                        </UncontrolledDropdown>                                    
                                    </td>
                                    ) : null
                                }
                                {
                                    // Add table data contents
                                    columnKeys.map((column, i) => {
                                        // For each table row, loop each column key to get the value dinamically
                                        if (i === 0) {
                                            // The first column's font weight should be bold
                                            return(
                                                <td key={`trow${record.id}-${column}`} className="font-weight-bold">
                                                    {record[column]}
                                                </td>
                                            );
                                        } else {
                                            if (Array.isArray(record[column])) {
                                                // If record value is an array, display the content pipe-separated
                                                return (
                                                    <td key={`trow${record.id}-${column}`}>
                                                        {record[column].join(" | ")}
                                                    </td>
                                                )
                                            } else {
                                                return(
                                                    <td key={`trow${record.id}-${column}`}>
                                                        {record[column]}
                                                    </td>
                                                );
                                            }
                                        }
                                    })

                                }
                                {
                                    // Add inline actions, if required
                                    inlineActions ? (
                                        <td className="table-actions">
                                            {
                                                inlineActions.map((action, i) => 
                                                    <span key={`trow${record.id}-action${i}`}>
                                                        <a
                                                            className="table-action"
                                                            type="button"
                                                            id={`${action.text}-tooltip${record.id}`}
                                                            onClick={(e) => action.handler(e, record[action.property])}
                                                        >
                                                            <i className={action.icon} />
                                                        </a>
                                                        <UncontrolledTooltip delay={0} target={`${action.text}-tooltip${record.id}`}>
                                                            {action.text}
                                                        </UncontrolledTooltip>
                                                    </span>
                                                )
                                            }
                                        </td>
                                    ) : null
                                }
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
}

export default RegisterTable;