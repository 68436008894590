import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { roundNumber } from "utilities/math";
import classNames from "classnames";
// Import custom hooks
import useQuery from "hooks/useQuery";
// Import reactstrap components
import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Table,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
    UncontrolledAlert,
    Form, 
    FormGroup,
    Modal,
    Label,
    Input,
    Progress,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
// Core components
import SimpleHeader from "components/Headers/SimpleHeader";
// Import contexts
import { ServerContext } from "containers/App";
// Import API services
import { getAllReagentsAccountingSummaryByYear, getAllMeasurementUnits } from "services/reagents";
// Import additional data
import { unitsNamesMap } from "data/measurementUnits";
// Import custom CSS styles
import 'views/pages/reagents/ReagentsReports.css';

const ReagentsReports = () => {
    // Load initial year from query parameters
    let query = useQuery()
    let initialYear = query.get("year") ? query.get("year") : String(new Date().getFullYear());
    // Load history
    const history = useHistory();
    // Load contexts
    const serverUrl = useContext(ServerContext);
    // Declare states
    const [selectedYear, setSelectedYear] = useState(initialYear);
    const [annualAccountingSummary, setAnnualAccountingSummary] = useState([]);
    const [accountingSummaryYear, setAccountingSummaryYear] = useState(initialYear);
    const [accountingYearTotals, setAccountingYearTotals] = useState(null);
    const [outdatedAnnualSummaries, setOutdatedAnnualSummaries] = useState([]);
    // Declare modal states
    const [modalUpdate, setModalUpdate] = useState(null);
    // States for accounting update
    const [currentUpdatingReagentId, setCurrentUpdatingReagentId] = useState(null);
    const [updatedSummariesCount, setUpdatedSummariesCount] = useState(0);
    const [outdatedSummariesTotal, setOutdatedSummariesTotal] = useState(0);
    const [updateCompleted, setUpdateCompleted] = useState(false);
    // States for measurement units
    const [measurementsUnitsData, setMeasurementsUnitsData] = useState(null);

    // Load references
    const printSummaryRef = useRef(null);

    useEffect(() => {
        // Load annual accounting summary on first load
        fetchAnnualAccountingSummary(selectedYear);
        // Load measurement units data
        getAllMeasurementUnits()
        .then(data => {
            if (data.success) {
                setMeasurementsUnitsData(data.units);
            } else {
                console.log(data.message);
            }
        })
    }, []);
    
    useEffect(() => {
        setOutdatedAnnualSummaries(
            annualAccountingSummary.filter((summary) => summary.pending_update)
        );
    }, [annualAccountingSummary])

    useEffect(() => {
        // Set 'updateCompleted' flag as true when all outdated summaries get updated
        if (
            updatedSummariesCount === outdatedAnnualSummaries.length && 
            outdatedAnnualSummaries.length !== 0
        ) {
            setUpdateCompleted(true);
        }
    }, [updatedSummariesCount])

    // Utility functions
    const fetchAnnualAccountingSummary = (year) => {
        // Fetch annual accounting summary from server
        getAllReagentsAccountingSummaryByYear(year)
            .then(data => {
                if(data.success){
                    setAnnualAccountingSummary(data.summary);
                    setAccountingYearTotals(data.totals);
                } else {
                    alert("No hay información para este año.");
                }
            });
    }

    const updateReagentAccounting = (reagentId, year) => {
        // PUT
        const requestOptions = {
            method: 'PUT',
            credentials: 'include',            
        }
        return fetch(`${serverUrl}/reagent/${reagentId}/accounting-summary/${year}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    // console.log(data);
                    return data;
                }
            })
            .catch(error => {
                alert("Ocurrió un error en el servidor.")
            });
    }

    // Handlers
    const handleYearChange = (e) => {
        e.preventDefault();
        setSelectedYear(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // Update the navigation history by replacing (with query parameter)
        // NOTE: page won't reload because it's the same url
        history.replace(`/reagents/reports?year=${selectedYear}`); 
        // Delete previous variables
        setAnnualAccountingSummary([]);
        setAccountingYearTotals(null);
        // Fetch annual accounting summary for requested year
        setAccountingSummaryYear(selectedYear);
        fetchAnnualAccountingSummary(selectedYear);
    }

    const handleModalUpdate = (e) => {
        e.preventDefault();
        // Reset count of updated summaries and update-completed flag
        setUpdatedSummariesCount(0);
        setOutdatedSummariesTotal(outdatedAnnualSummaries.length)
        setUpdateCompleted(false);
        // Open modal
        setModalUpdate(true);
    }

    const handleUpdateAccounting = async () => {    
        // Update each outdated annual accounting summary
        for (const element of outdatedAnnualSummaries) {
            setCurrentUpdatingReagentId(element.reagent.accounting_code);
            let data = await updateReagentAccounting(element.reagent.id, accountingSummaryYear);
            if (!data.summary.pending_update) {
                setUpdatedSummariesCount(state => state + 1);
            }
        }
        setCurrentUpdatingReagentId(null);
        // Empty states with old data before fetching new
        setAnnualAccountingSummary([]);
        setAccountingYearTotals(null);
        // Fetch all accounting summaries after updating
        fetchAnnualAccountingSummary(selectedYear);
        setTimeout(() => {
            // Close modal after 1 second
            setModalUpdate(false);
        }, 1000);

    }

    // Renderers
    const renderDropdownMenu = (reagentAccountingCode) => {
        return (
            <UncontrolledDropdown>
                <DropdownToggle
                    className="btn-icon-only text-light"
                    color=""
                    role="button"
                    size="sm"
                >
                <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                    <Link to={{
                        pathname: `/reagent/${reagentAccountingCode}/accounting`,
                        state: { year: accountingSummaryYear }
                    }} >
                        <DropdownItem
                            key={reagentAccountingCode}
                        >
                            Ver contabilidad
                        </DropdownItem>
                    </Link>
                </DropdownMenu>
            </UncontrolledDropdown>                                            
        )
    }    

    return (
        <>
            <SimpleHeader 
                name="Reportes" 
                parentName="Reactivos"
            />
            <Container className="mt--6" fluid>
                <Card className="mb-4">
                    <CardHeader>
                        <Row>
                            <Col>
                                <h3>Resumen Anual</h3>
                                Ingresos, Consumos y Saldos de Reactivos
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col>
                                <Form onSubmit={handleSubmit}>
                                    <FormGroup row>
                                        <Label sm='3' xl='2' className="form-control-label" htmlFor="year">
                                            Seleccionar año:
                                        </Label>
                                        <Col xs='6' sm='3' xl='2'className="mb-2">
                                            <Input 
                                                type="number"
                                                min="2000"
                                                max="9999"
                                                id="year" 
                                                placeholder="Año"
                                                defaultValue={selectedYear}
                                                onChange={handleYearChange}
                                            />
                                        </Col>
                                        <Col xs='12' sm='6'>
                                            <Button
                                                color="primary"
                                            >
                                                Ver
                                            </Button>
                                            <Button
                                                color="primary"
                                                type="button"
                                                onClick={handleModalUpdate}
                                                disabled={(accountingSummaryYear !== selectedYear) || (outdatedAnnualSummaries.length === 0)}
                                            >
                                                Actualizar
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        {
                            outdatedAnnualSummaries.length ? (
                                <Row>
                                    <Col>
                                        <UncontrolledAlert color="warning">
                                        <span className="alert-icon">
                                            <i className="ni ni-notification-70" />
                                        </span>
                                        <span className="alert-text ml-1">
                                            <strong>¡Alerta!</strong> Hay {outdatedAnnualSummaries.length} {outdatedAnnualSummaries.length > 1 ? 'reactivos' : 'reactivo'} con contabilidad pendiente de actualización.
                                            Da click en <strong>Actualizar</strong>.
                                        </span>
                                        </UncontrolledAlert>                            
                                    </Col>
                                </Row>
                            ) : null
                        }
                    </CardHeader>
                    <CardBody>
                        {/* TODO: Improve design*/}
                        <Table
                            responsive
                        >
                            <thead className="thead-light">
                                <tr>
                                    <th>Saldo {accountingSummaryYear-1}</th>
                                    <th></th>
                                    <th>Ingresos</th>
                                    <th></th>
                                    <th>Consumos</th>
                                    <th></th>
                                    <th>Saldo Final</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>S/ {accountingYearTotals?.carryover_stock.toFixed(2)}</td>
                                    <th><i className="fas fa-plus"></i></th>
                                    <td>S/ {accountingYearTotals?.inflow.toFixed(2)}</td>
                                    <th><i className="fas fa-minus"></i></th>
                                    <td>S/ {accountingYearTotals?.outflow.toFixed(2)}</td>
                                    <th><i className="fas fa-equals"></i></th>
                                    <td>S/ {accountingYearTotals?.remaining_stock.toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
                <Card className="mb-4">
                    <div ref={printSummaryRef}>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h4>Resumen de Reactivos - {accountingSummaryYear}</h4>
                                </Col>
                                <Col md='4' className="text-right">
                                    <ReactToPrint
                                        documentTitle={`ResumenReactivosContabilidad${accountingSummaryYear}`}
                                        content={() => printSummaryRef.current}
                                        trigger={() => (
                                            <Button
                                                className="btn-icon"
                                                color="primary"
                                                size="sm"
                                                id="printButton"
                                            >
                                                <span className="btn-inner--icon mr-1">
                                                    <i className="fas fa-print" />
                                                </span>
                                                <span className="btn-inner--text">Imprimir</span>
                                            </Button>
                                        )}
                                    />
                                    <UncontrolledTooltip target='printButton'>
                                        Imprimir Resumen
                                    </UncontrolledTooltip>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            {/* TODO: Add option to export table */}
                            <Table
                                className="align-items-center table-flush"
                                responsive
                                hover
                                size="sm"
                            >
                                <thead className="thead-light">
                                    <tr>
                                        <th className="no-print"></th>
                                        <th>Cód.<br/>cont.</th>
                                        <th>Cód.<br/>lab.</th>
                                        <th>Descripción</th>
                                        <th className="no-print">Saldo {accountingSummaryYear-1}</th>
                                        <th className="no-print">Saldo {accountingSummaryYear-1} (S/)</th>
                                        <th className="no-print">Ingresos</th>
                                        <th className="no-print">Ingresos (S/)</th>
                                        <th className="no-print">Consumos</th>
                                        <th className="no-print">Consumos (S/)</th>
                                        <th>Unidad</th>
                                        <th>Saldo</th>
                                        <th>Saldo (S/)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        annualAccountingSummary.map(reagentSummary => {
                                            let reagentBaseUnitShortName = "";
                                            if (measurementsUnitsData != null) {
                                                let reagentBaseUnit = measurementsUnitsData[reagentSummary.reagent.format_size_unit].base_unit;
                                                reagentBaseUnitShortName = unitsNamesMap[reagentBaseUnit].shortName;
                                            }
                                            return(
                                                <tr
                                                    key={`row${reagentSummary.reagent.accounting_code}`}
                                                    className={classNames({ 'table-warning': reagentSummary.pending_update })}
                                                >
                                                    <th className="no-print">
                                                        {renderDropdownMenu(reagentSummary.reagent.accounting_code)}
                                                    </th>
                                                    <th scope="row">
                                                        {reagentSummary.reagent.accounting_code}
                                                    </th>
                                                    <th scope="row">
                                                        {reagentSummary.reagent.lab_code}
                                                    </th>
                                                    <td>{reagentSummary.reagent.description.slice(0, 40)}{reagentSummary.reagent.description.length > 40 ? '...' : ''}</td>
                                                    {/* Carry-over stock */}
                                                    <td className="no-print">{roundNumber(reagentSummary.carryover_stock_amount, 6)}</td>
                                                    <td className="no-print">S/ {reagentSummary.carryover_stock_total_cost.toFixed(2)}</td>
                                                    {/* Inflow */}
                                                    <td className="no-print">{roundNumber(reagentSummary.inflow_amount, 6)}</td>
                                                    <td className="no-print">S/ {reagentSummary.inflow_total_cost.toFixed(2)}</td>
                                                    {/* Outflow */}
                                                    <td className="no-print">{roundNumber(reagentSummary.outflow_amount, 6)}</td>
                                                    <td className="no-print">S/ {reagentSummary.outflow_total_cost.toFixed(2)}</td>
                                                    {/* Remaining balance */}
                                                    <td>{reagentBaseUnitShortName}</td>
                                                    <td>{roundNumber(reagentSummary.remaining_stock_amount, 6)}</td>
                                                    <td>S/ {reagentSummary.remaining_stock_total_cost.toFixed(2)}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </CardBody>
                    </div>
                </Card>
            </Container>
            <Modal
                className="modal-dialog-centered"
                isOpen={modalUpdate}
                toggle={() => setModalUpdate(false)}
            >
                <div className="modal-header">
                    <h3 className="modal-title">
                        Actualización de Contabilidad de Reactivos - {accountingSummaryYear}
                    </h3>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setModalUpdate(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>                                                                       
                </div>
                <div className="modal-body">
                    <p>
                        Se va a re-calcular la contabilidad anual 
                        de <strong>{outdatedSummariesTotal}</strong> reactivo{outdatedSummariesTotal>1 ? 's' : ''}.
                        Esta acción puede tomar de unos segundos a minutos.
                    </p>            
                    <div className="progress-wrapper">
                        <div className="progress-info">
                            <div className="progress-label">
                                <span>Progreso</span>
                            </div>
                            <div className="progress-percentage">
                                <span>{(updatedSummariesCount / outdatedSummariesTotal * 100).toFixed(0)}%</span>
                            </div>
                        </div>
                        <Progress 
                            animated 
                            max={outdatedSummariesTotal} 
                            value={updatedSummariesCount} 
                            color="default"
                        />
                    </div>
                    {
                        currentUpdatingReagentId ? (
                            <p>Actualizando contabilidad de reactivo {currentUpdatingReagentId}...</p>
                        ) : null
                    }
                    {
                        updateCompleted ? (
                            <p>Actualización completada...</p>
                        ) : null
                    }
                </div>
                <div className="modal-footer">
                    <Button
                        className="new-event--add"
                        color="primary"
                        type="button"
                        onClick={handleUpdateAccounting}
                        disabled={(currentUpdatingReagentId !== null) || updateCompleted}
                    >
                        Ok, actualizar
                    </Button>
                    <Button
                        className="ml-auto"
                        color="link"
                        type="button"
                        onClick={() => setModalUpdate(false)}
                    >
                        Cancelar
                    </Button>
                </div>                                    
            </Modal>            
        </>
    );
}

export default ReagentsReports;