/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card, CardHeader, CardBody,
  FormGroup, Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container, Row, Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
// Import contexts
import { ServerContext, CurrentUserContext } from "containers/App";
import { RequestAccessModalContext } from "layouts/Auth";
// Import services
import { getSession, createSession } from "services/authentication";

function Login() {
    const [focusedEmail, setfocusedEmail] = useState(false);
    const [focusedPassword, setfocusedPassword] = useState(false);
    const [loginCredentials, setLoginCredentials] = useState({});

    // Load contexts
    const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
    const { requestAccessModal, setRequestAccessModal } = useContext(RequestAccessModalContext);
    // Load history and location from react router
    const history = useHistory();
    const location = useLocation();
    let { from } = location.state || { from: { pathname: "/" } }; // Last page visited
    
    useEffect(() => {
        // Check if user is logged in at first render
        getSession()
            .then(data => {
                if(data.success) {
                    setCurrentUser(data.user);
                    console.log(data.message);
                    history.replace(from);
                } else {
                    console.log(data.message);
                }
            });
    }, [])
  
    // Handlers
    const handleRequestAccessModal = (e) => {
        e.preventDefault();
        setRequestAccessModal(true);
    }

    // Function that handles form field data
    const handleInputsChange = (e) => {
        // Update credentials state
        setLoginCredentials({...loginCredentials, [e.target.name]: e.target.value});
    }

    // Function that handles login
    const handleLoginSubmit = (e) => {
        e.preventDefault()
        // Post credentials to auth/login endpoint
        createSession(loginCredentials)
            .then(data => {
                if (data.success) {
                    // Add user to currentUser state
                    console.log(data.message);
                    setCurrentUser(data.user);
                    // Redirect user to previous protected page or to root
                    history.replace(from);
                } else {
                    console.log(data.message);
                    // TODO: Improve alert notification
                    alert("Credenciales incorrectas.");
                }                
            });
    }

    return (
        <>
        <AuthHeader
            title="Bienvenido a HYPATIA Hub"
            lead="Sistema de gestión de Hypatia S.A."
        />
        <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
            <Col lg="5" md="7">
                <Card className="bg-secondary border-0 mb-0">
                <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                    <small>Inicia sesión con tus credenciales</small>
                    </div>
                    <Form role="form">
                    <FormGroup
                        className={classnames("mb-3", {
                        focused: focusedEmail,
                        })}
                    >
                        <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="ni ni-circle-08" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="Nombre de usuario"
                            type="text"
                            name="username"
                            onFocus={() => setfocusedEmail(true)}
                            onBlur={() => setfocusedEmail(false)}
                            onChange={handleInputsChange}
                        />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup
                        className={classnames({
                        focused: focusedPassword,
                        })}
                    >
                        <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="Contraseña"
                            type="password"
                            name="password"
                            onFocus={() => setfocusedPassword(true)}
                            onBlur={() => setfocusedPassword(false)}
                            onChange={handleInputsChange}
                        />
                        </InputGroup>
                    </FormGroup>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                            className="custom-control-input"
                            id=" customCheckLogin"
                            type="checkbox"
                        />
                        <label
                            className="custom-control-label"
                            htmlFor=" customCheckLogin"
                        >
                            <span className="text-muted">Recuérdame</span>
                        </label>
                    </div>
                    <div className="text-center">
                        <Button 
                            className="my-4" 
                            color="info" 
                            type="submit"
                            onClick={handleLoginSubmit}
                        >
                            Entrar
                        </Button>
                    </div>
                    </Form>
                </CardBody>
                </Card>
                <Row className="mt-3">
                <Col xs="6">
                    <a
                        className="text-light"
                        type="button"
                        // href="#"
                        onClick={(e) => handleRequestAccessModal(e)}
                    >
                    <small>¿Olvidaste la contraseña?</small>
                    </a>
                </Col>
                <Col className="text-right" xs="6">
                    <a
                        className="text-light"
                        type="button"
                        // href="#"
                        onClick={(e) => handleRequestAccessModal(e)}
                    >
                    <small>Crear nueva cuenta</small>
                    </a>
                </Col>
                </Row>
            </Col>
            </Row>
        </Container>
        </>
    );
}

export default Login;
