import React, { useContext, useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { useParams, useHistory, useLocation } from "react-router-dom";
// Import reactstrap components
import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Table,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
    Form, 
    FormGroup,
    Modal,
    Label,
    Input,
} from "reactstrap";
// Core components
import SimpleHeader from "components/Headers/SimpleHeader";
import SunatFormat13Dot1 from "components/SunatFormats/Format13Dot1";
// Import contexts
import { ServerContext } from "containers/App";

const ReagentAccountingChart = () => {
    // Load initial year from location state
    const location = useLocation();
    let navigationState = location.state;
    let initialYear = navigationState?.year ? navigationState.year : String(new Date().getFullYear());
    // Load url parameter
    const { accounting_code } = useParams();
    let { history } = useHistory();
    // Load contexts
    const serverUrl = useContext(ServerContext);
    // Declare states for form
    const [formInputYear, setFormInputYear] = useState(initialYear);
    // Declare reagent data-related states
    const [reagent, setReagent] = useState(null);
    const [reagentAccounting, setReagentAccounting] = useState([]);
    const [reagentAccountingYear, setReagentAccountingYear] = useState(null);
    const [reagentAccountingSummary, setReagentAccountingSummary] = useState(null);
    // Declare references
    const tableRef = useRef(null);
    // Run only at first render
    useEffect(() => {
        // Fetch reagent information
        fetchReagentByAccountingCode(accounting_code);
        // Set current year as default for fetching data
        setReagentAccountingYear(formInputYear);
    }, []);
    // Run after loading for the first time or updating reagent
    useEffect(() => {
        if (reagent) {
            // Fetch reagent's accounting data
            fetchReagentAccountingById(reagent.id, formInputYear);
        }
    }, [reagent]);


    // Utility functions
    const fetchReagentByAccountingCode = (accountingCode) => {
        // GET request
        let requestOptions = {
            method: 'GET',
            credentials: 'include',
        }
        fetch(`${serverUrl}/reagent/ac-${accountingCode}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setReagent(data.reagent);
                } else {
                    console.log(data.message);
                    // If reagent doesn't exist redirect user to reagents page
                    history.replace('/reagents/register');
                }
            })
            .catch(error => alert('Ocurrió un error en el servidor.'));
    }

    const fetchReagentAccountingById = (id, year) => {
        /**
         * This function will call the server to re-calculate the corresponding
         * reagent's accounting and save the accounting summary
         */

        // PUT request
        let requestOptions = {
            method: 'PUT',
            credentials: 'include',
        }
        fetch(`${serverUrl}/reagent/${id}/accounting-summary/${year}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setReagentAccounting(data.accounting);
                    setReagentAccountingSummary(data.summary);
                } else {
                    console.log(data.message);
                    alert(`No hay información para el año ${year}.`);
                }
            })
            .catch(error => {
                console.log(error);
                alert('Ocurrió un error en el servidor.');
            });
    }    

    // Handlers
    const handleFormChange = (e) => {
        e.preventDefault();
        setFormInputYear(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // Change requested history year state.
        setReagentAccountingYear(formInputYear);
        // Update reagent's accounting data
        fetchReagentAccountingById(reagent.id, formInputYear);
    }

    return (
        <>
            <SimpleHeader 
                name="Tabla Contable" 
                parentName="Reactivos"
            />
            <Container className="mt--6" fluid>
                <Card className="mb-4">
                    <CardHeader>
                        <Row>
                            <Col>
                                <h3>Tabla de contabilidad SUNAT - Formato 13.1</h3>
                            </Col>
                            <Col md='4' className="text-right">
                                <ReactToPrint
                                    documentTitle={`Reactivo${reagent?.accounting_code}Contabilidad${reagentAccountingYear}`}
                                    content={() => tableRef.current}
                                    trigger={() => (
                                        <Button
                                            className="btn-icon"
                                            color="primary"
                                            size="sm"
                                            id="printButton"
                                        >
                                            <span className="btn-inner--icon mr-1">
                                                <i className="fas fa-print" />
                                            </span>
                                            <span className="btn-inner--text">Imprimir</span>                                                
                                        </Button>               
                                    )}
                                />
                                <UncontrolledTooltip target='printButton'>
                                    Imprimir Formato 13.1
                                </UncontrolledTooltip>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Form onSubmit={handleSubmit}>
                            <FormGroup row>
                                <Label sm='3' xl='2' className="form-control-label" htmlFor="year">
                                    Ver año:
                                </Label>
                                <Col xs='6' sm='3' xl='2'>
                                    <Input 
                                        type="number"
                                        min="2000"
                                        max="9999"
                                        id="year" 
                                        placeholder="Año"
                                        defaultValue={formInputYear}
                                        onChange={handleFormChange}
                                    />
                                </Col>
                                <Col xs='6' sm='3' xl='2'>
                                    <Button
                                        color="primary"
                                        type="submit"
                                    >
                                        Generar
                                    </Button>
                                </Col>
                            </FormGroup>
                        </Form>                        
                    </CardBody>
                </Card>
                {   
                    (reagent && reagentAccountingSummary) ? (
                        <SunatFormat13Dot1
                            reagent={reagent}
                            year={reagentAccountingYear}
                            accountingRecords={reagentAccounting}
                            accountingSummary={reagentAccountingSummary}
                            ref={tableRef}
                        />
                    ) : null
                    // TODO: Show a 'loading' component
                }
            </Container>
        </>
    );
}

export default ReagentAccountingChart;