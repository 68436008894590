const SERVER_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SERVER_URL : 'http://127.0.0.1:5000'

/**
 * Authentication endpoints
 */
export const getSession = async () => {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }

    const response = await fetch(`${SERVER_URL}/auth/login/`, requestOptions);
    return await response.json();
}

export const createSession = async (credentials) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(credentials)
    }

    const response = await fetch(`${SERVER_URL}/auth/login/`, requestOptions)
    return await response.json();
}

export const logout = async () => {
    const requestOptions = {
        method: 'POST',
        credentials: 'include'
      }
      
      const response = await fetch(`${SERVER_URL}/auth/logout/`, requestOptions)
      return await response.json();
}