import React, {useEffect, useState, createContext} from "react";  
// React library for routing
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// Import reactstrap components
import { Modal, Button } from "reactstrap";
// My components
import AuthNavbar from "components/Navbars/AuthNavbar";
import { authRoutes } from "routes";
// Declare contexts
export const RequestAccessModalContext = createContext(null);

const Auth = () => {

    // Declare states
    const [requestAccessModal, setRequestAccessModal] = useState(false);

    const location = useLocation();
    const mainContentRef = React.useRef(null);

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContentRef.current.scrollTop = 0;
        document.body.classList.add("bg-default");
        // Specify how to clean up after this effect:
        return function cleanup() {
            document.body.classList.remove("bg-default");
        };
    });

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContentRef.current.scrollTop = 0;
    }, [location]);

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.layout === "/auth") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };

    return(
        <>
            <div className="main-content" ref={mainContentRef}>
                <AuthNavbar
                    requestAccessModal={requestAccessModal}
                    setRequestAccessModal={setRequestAccessModal}
                />
                <RequestAccessModalContext.Provider value={{requestAccessModal, setRequestAccessModal}}>
                    <Switch>
                        {getRoutes(authRoutes)}
                        <Redirect from="*" to="/auth/login" />
                    </Switch>
                </RequestAccessModalContext.Provider>
            </div>
            <RequestAccessModal
                modalState={requestAccessModal}
                setModalState={setRequestAccessModal}
            />
        </>
    );
}

export default Auth;

const RequestAccessModal = ({
    modalState, setModalState
  }) => {
    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={modalState}
            toggle={()=>setModalState(false)}
            // size="lg" 
        >
            <div className="modal-header">
                <h3 className="modal-title">
                    Acceso a HYPATIA Hub
                </h3>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalState(false)}
                >
                    <span aria-hidden={true}>×</span>
                </button>                      
            </div>
            <div className="modal-body">
                <p>
                    Solicita tu usuario y contraseña a la <strong>Administración</strong>.
                </p>          
            </div>
            <div className="modal-footer">
                <Button
                    color="info"
                    type="button"
                    onClick={() => setModalState(false)}
                >
                    OK
                </Button>
            </div>            
        </Modal>
    )
  }
  