/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

function ProfileHeader({ currentUser }) {

  return (
    <>
        <div
            className="header pb-6 d-flex align-items-center"
            style={{
            minHeight: "360px",
            backgroundImage:
                'url("' + require("assets/img/theme/profile-cover.jpg") + '")',
            backgroundSize: "cover",
            backgroundPosition: "center top",
            }}
        >
        <span className="mask bg-gradient-info opacity-8" />

        <Container className="d-flex align-items-center" fluid>
            <Row>
                <Col md="10">
                    <h1 className="display-2 text-white">Hola {currentUser.firstname}</h1>
                    <p className="text-white mt-0 mb-5">
                        Esta es tu pagina de perfil. Aqui puedes ver tus datos en Hypatia S.A.
                    </p>
                    {/* <Button
                        className="btn-neutral"
                        color="default"
                        href="#"
                        onClick={(e) => e.preventDefault()}
                    >
                        Editar perfil
                    </Button> */}
                </Col>
            </Row>
        </Container>
      </div>
    </>
  );
}

export default ProfileHeader;