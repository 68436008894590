/**
 * Converts a date into ISO format, without changing the timezone
 * @param {Date} date JS Date instance
 * @returns {String}    Date formatted in ISO 'yyyy-MM-dd'
 */
export const dateToISOFormat = (date) => {
    let year = String(date.getFullYear());
    let month = String(date.getMonth() + 1).padStart(2, '0');
    let day = String(date.getDate()).padStart(2, '0');
    
    const formattedDate = `${year}-${month}-${day}`;
    return  formattedDate
}

export const RCFdateToISOFormat = (RFCDate) => {
    if (!RFCDate) {
        return null;
    } 
    return new Date(RFCDate).toISOString().slice(0, 10);
}