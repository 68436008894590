import React, { useEffect, useState } from "react";

// Import reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Form,
    Input,
    Container,
    Row,
    Col,
    FormGroup,
} from "reactstrap";

// Core components
import SimpleHeader from "components/Headers/SimpleHeader";
import RegisterTable from "components/Tables/RegisterTable";
// Import API services
import {
    getAllAreas, 
    getAllUsers, addNewUser, 
    getAllUserRoles 
} from "services/admin";

const usersTableMapping = {
    username: { tableHeading: 'Usuario' },
    firstname: { tableHeading: 'Nombres' },
    lastname: { tableHeading: 'Apellidos' },
    email: { tableHeading: 'E-mail' },
    area_id: { tableHeading: 'Id del area' },
    area_name: { tableHeading: 'Area' },
    position: { tableHeading: 'Cargo' },
    role_id: { tableHeading: 'Id del rol' },
    role_name: { tableHeading: 'Rol' },
}    

const UsersSettings = () => {
    // Declare states
    const [users, setUsers] = useState([]);
    const [modifiedUsers, setModifiedUsers] = useState([]);
    const [newUser, setNewUser] = useState({});
    const [columnKeys, setColumnKeys] = useState([]);
    // Complementary data
    const [areas, setAreas] = useState([]);
    const [formAreaPositions, setFormAreaPositions] = useState([]);
    const [roles, setRoles] = useState([]);
    
    useEffect(() => {
        // Fetch users from server at first render
        fetchUsers();
        // Fetch complementary data
        fetchAreas();
        fetchRoles();
    }, []);
    
    useEffect(() => {
        // Add a 'area_name' property to each user record
        let modifiedUsersList = users.map(user => {
            return({
                ...user,
                area_name: user.area ? user.area.name : null,
                role_name: user.role ? user.role.name : null
            })
        });
        setModifiedUsers(modifiedUsersList);

    }, [users])

    useEffect(() => {
        // Get the 'positions' for the area selected in the form
        if (newUser.area_id) {
            const area_positions = areas.filter(area => area.id === Number(newUser.area_id))[0].positions;
            // If area has 'positions' update state, otherwise pass an empty array
            area_positions ? setFormAreaPositions(area_positions) : setFormAreaPositions([])
        }
    }, [newUser.area_id]);

    // Utility functions
    const fetchUsers = () => {
        getAllUsers()
            .then((data) => {
                if (data.success) {
                    setUsers(data.users);
                    // Get column keys that must be displayed in the table
                    let colKeys = data.table.column_keys;
                    // colKeys.splice(colKeys.indexOf('id'), 1);
                    setColumnKeys(colKeys);
                }
            });
    }
    // 
    const fetchAreas = () => {
        getAllAreas()
            .then(data => {
                if (data.success) {
                    setAreas(data.areas);
                }
            });
    }
    const fetchRoles = () => {
        getAllUserRoles()
            .then(data => {
                if (data.success) {
                    setRoles(data.roles);
                }
            })
    }
    // Handlers
    const handleFormChange = (e) => {
        // Update newUser state
        setNewUser({ ...newUser, [e.target.name]: e.target.value });
    }
    
    const handleUserSubmit = () => {
        // Add new user to server
        addNewUser(newUser)
            .then((data) => {
                if (data.success) {
                        console.log(data.message);
                        // Fetch users from server to update table
                        fetchUsers();
                    }
                })
            .catch(error => alert('Ocurrió un error.'));
    }
        
    // Customization variables
    const headerButtons = [
        { className: 'btn-neutral', color: 'default', content: 'Nuevo', link: '#add-new' },
    ];
    
    // Render component
    return (
        <>
        <SimpleHeader 
            name="Usuarios" 
            parentName="Bases de datos"
            buttons={headerButtons}
        />
        <Container className="mt--6" fluid>
            {/* Render table to display all users */}
            <RegisterTable
                columnKeys={columnKeys}
                records={modifiedUsers}
                tableMapping={usersTableMapping}
                tableTitle="Registro de Usuarios"
            />
            {/* Render form for adding new users */}
            <Card className="mb-4">
                <CardHeader>
                    <h3>Registrar Nuevo Usuario</h3>
                </CardHeader>
                <CardBody>
                    <Form>
                        <Row>
                            <Col sm="6">
                                <FormGroup>
                                    <Input
                                        id="username"
                                        placeholder="Usuario"
                                        type="text"
                                        name='username'
                                        onChange={handleFormChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm='6'>
                                <FormGroup>
                                    <Input
                                        id="email"
                                        placeholder="E-mail (opcional)"
                                        type="email"
                                        name='email'
                                        onChange={handleFormChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <FormGroup>
                                    <Input
                                        id="firstname"
                                        placeholder="Nombre(s)"
                                        type="text"
                                        name='firstname'
                                        onChange={handleFormChange}
                                    />
                                </FormGroup>    
                            </Col>
                            <Col sm='6'>
                                <FormGroup>
                                    <Input
                                        id="lastname"
                                        placeholder="Apellido(s)"
                                        type="text"
                                        name='lastname'
                                        onChange={handleFormChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <FormGroup>
                                    <Input 
                                        id="area_id" 
                                        placeholder="Area"
                                        type="select"
                                        name='area_id'
                                        onChange={handleFormChange}
                                        defaultValue="Selecciona el area"
                                    >   
                                        <option disabled>Selecciona el area</option>
                                        {
                                            areas.map(area => {
                                                return(
                                                    <option value={area.id} key={area.id}>{area.name}</option>
                                                );
                                            })
                                        }
                                    </Input>                                                                 
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    <Input 
                                        id="position" 
                                        placeholder="Puesto en el area"
                                        type="select"
                                        name='position'
                                        onChange={handleFormChange}
                                        defaultValue="Selecciona el cargo"
                                    >
                                        <option disabled>Selecciona el cargo</option>
                                        {
                                            formAreaPositions.map(position => {
                                                return(
                                                    <option key={position}>{position}</option>
                                                )
                                            })

                                        }

                                    </Input>                                                                 
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <FormGroup>
                                    <Input 
                                        id="role_id" 
                                        placeholder="Rol"
                                        type="select"
                                        name='role_id'
                                        onChange={handleFormChange}
                                        defaultValue="Selecciona el rol"
                                    >   
                                        <option disabled>Selecciona el rol</option>
                                        {
                                            roles.map(role => {
                                                return(
                                                    <option value={role.id} key={role.id}>{role.name}</option>
                                                );
                                            })
                                        }
                                    </Input>                                                                 
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className="text-left">
                            <Button
                                color="primary"
                                type="button"
                                onClick={handleUserSubmit}
                            >
                                Registrar
                            </Button>
                        </div>
                    </Form>
                </CardBody>
            </Card>
        </Container>
        </>
    );
}

export default UsersSettings;