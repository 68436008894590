/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import HomePage from 'views/HomePage';
import ReagentsRegister from 'views/pages/reagents/ReagentsRegister';
import ReagentsEntries from 'views/pages/reagents/ReagentsEntries';
import ReagentsUsageLog from 'views/pages/reagents/ReagentsUsageLog';
import ReagentsReports from 'views/pages/reagents/ReagentsReports';
import ReagentPage from "views/pages/reagents/ReagentPage";
import ReagentAccountingTable from 'views/pages/reagents/ReagentAccountingTable';
// Profile pages
import UserProfile from 'views/pages/profile/UserProfile';
// Admin settings pages
import UsersSettings from 'views/pages/settings/UsersSettings';
import AreasSettings from 'views/pages/settings/AreasSettings';
import UserRolesSettings from 'views/pages/settings/UserRolesSettings';
import ReagentsSettings from 'views/pages/settings/ReagentsSettings';
import Login from "views/pages/auth/Login.js";

// Routes for Dashboard layout that will render in the Sidebar
const routes = [
    {
        path: 'dashboard',
        name: 'Home',
        icon: 'ni ni-shop text-primary',
        component: HomePage,
        layout: '/'
    
    },
    {
        collapse: true,
        name: 'Reactivos',
        icon: 'ni ni-atom text-primary',
        state: 'reagentsCollapse',
        views: [
            {
                path: 'reagents/register',
                name: 'Códigos de Reactivos',
                miniName: 'L',
                component: ReagentsRegister,
                layout: '/',                
            },
            {
                path: 'reagents/entries',
                name: 'Registrar Ingreso',
                miniName: 'R',
                component: ReagentsEntries,
                layout: '/',                
            },
            {
                path: 'reagents/usage-log',
                name: 'Registrar Uso',
                miniName: 'R',
                component: ReagentsUsageLog,
                layout: '/',                
            },
            {
                path: 'reagents/reports',
                name: 'Reportes',
                miniName: 'R',
                component: ReagentsReports,
                layout: '/',                
            },
        ]

    },
    {
        collapse: true,
        name: 'Ajustes',
        icon: 'ni ni-settings text-primary',
        state: 'settingsCollapse',
        views: [
            {
                path: 'settings/areas',
                name: 'Areas',
                miniName: 'A',
                component: AreasSettings,
                layout: '/',                
            },
            {
                path: 'settings/users',
                name: 'Personal',
                miniName: 'P',
                component: UsersSettings,
                layout: '/',                
            },
            {
                path: 'settings/roles',
                name: 'Roles de usuario',
                miniName: 'R',
                component: UserRolesSettings,
                layout: '/',                
            },
            {
                path: 'settings/reagents',
                name: 'Reactivos',
                // icon: 'ni ni-atom text-primary',
                miniName: 'R',
                component: ReagentsSettings,
                layout: '/',                
            },
        ]

    },
    {
        path: 'profile',
        name: 'Mi Perfil',
        icon: 'ni ni-circle-08 text-primary',
        component: UserProfile,
        layout: '/'
    
    },    
]
// Routes for Dashboard layout (won't render on sidebar)
export const dashboardRoutes = [
    {
        path: 'reagent/:accounting_code/accounting',
        component: ReagentAccountingTable,
        layout: '/'
    },
    {
        path: 'reagent/:accounting_code',
        component: ReagentPage,
        layout: '/'
    },
]

// Routes for Auth layout
export const authRoutes = [
    {
        collapse: true,
        name: "Auth",
        icon: 'ni ni-ungroup text-orange',
        state: 'authCollapse',
        views: [
            {
                path: '/login',
                name: 'Iniciar Sesion',
                miniName: 'IS',
                component: Login,
                layout: '/auth'
            }
        ]
    }
]

export default routes;