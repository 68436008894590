const SERVER_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SERVER_URL : 'http://127.0.0.1:5000'

/********************
* Purchase Order' functions
*/ 
export const addNewPurchaseOrder = async (data) => {
    const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    }
    const response = await fetch(`${SERVER_URL}/logistics/purchase-orders/`, requestOptions);
    return await response.json();
}

export const getPurchaseOrderByNumber = async (purchaseOrderNumber, year) => {
    const requestOptions = {
        method: 'GET',
        credentials: 'include',
    }
    const query = new URLSearchParams({year})
    const response = await fetch(`${SERVER_URL}/logistics/purchase-order/num-${purchaseOrderNumber}?${query}`, requestOptions);
    return await response.json();
}

export const putPurchaseOrderByNumber = async (purchaseOrderNumber, year, data) => {
    let requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(data)
    }
    const query = new URLSearchParams({year})
    const response = await fetch(`${SERVER_URL}/logistics/purchase-order/num-${purchaseOrderNumber}?${query}`, requestOptions);
    return await response.json();
}


/********************
* Quotations' functions
*/ 
export const addNewQuotation = async (data) => {
    const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    }
    const response = await fetch(`${SERVER_URL}/logistics/quotations/`, requestOptions);
    return await response.json();
}

export const getQuotationById = async (id) => {
    const requestOptions = {
        method: 'GET',
        credentials: 'include',
    }
    const response = await fetch(`${SERVER_URL}/logistics/quotation/${id}`, requestOptions);
    return await response.json();
}

export const getQuotationByCode = async (quotationCode) => {
    const requestOptions = {
        method: 'GET',
        credentials: 'include',
    }
    const query = new URLSearchParams({quotationCode})
    const response = await fetch(`${SERVER_URL}/logistics/quotation-by-code?${query}`, requestOptions);
    return await response.json();
}

export const putQuotationByCode = async (quotationCode) => {
    let requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ quotation_code: quotationCode })
    }
    const response = await fetch(`${SERVER_URL}/logistics/quotation-by-code/`, requestOptions);
    return await response.json();
}