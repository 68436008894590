import React from "react";
import ReactDatetime from "react-datetime";
// Import reactstrap components
import {
    Badge,
    Button,
    Form,
    Input,
    ListGroupItem,
    ListGroup,
    Media,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Modal,
} from "reactstrap";


const RangeDatepickerForm = ({ handleOnSubmit }) => {

    // Declare states
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);

    // Handlers
    const handleReactDatetimeChange = (who, date) => {
        if (
            startDate &&
            who === "endDate" &&
            new Date(startDate._d + "") > new Date(date._d + "")
        ) {
            setStartDate(date);
            setEndDate(date);
        } else if (
            endDate &&
            who === "startDate" &&
            new Date(endDate._d + "") < new Date(date._d + "")
        ) {
            setStartDate(date);
            setEndDate(date);
        } else {
            if (who === "startDate") {
                setStartDate(date);
            } else {
                setEndDate(date);
            }
        }
    };

    // this function adds on the day tag of the date picker
    // middle-date className which means that this day will have no border radius
    // start-date className which means that this day will only have left border radius
    // end-date className which means that this day will only have right border radius
    // this way, the selected dates will look nice and will only be rounded at the ends
    const getClassNameReactDatetimeDays = (date) => {
        if (startDate && endDate) { }
        if (startDate && endDate && startDate._d + "" !== endDate._d + "") {
            if (
                new Date(endDate._d + "") > new Date(date._d + "") &&
                new Date(startDate._d + "") < new Date(date._d + "")
            ) {
                return " middle-date";
            }
            if (endDate._d + "" === date._d + "") {
                return " end-date";
            }
            if (startDate._d + "" === date._d + "") {
                return " start-date";
            }
        }
        return "";
    };    

    return(
        <Form onSubmit={(e) => handleOnSubmit(e, startDate, endDate)}>
            <Row className="input-daterange datepicker align-items-center">
                <Col sm='4'>
                    <FormGroup>
                        <label className=" form-control-label">
                            Desde:
                        </label>
                        <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="ni ni-calendar-grid-58" />
                                </InputGroupText>
                            </InputGroupAddon>                            
                            <ReactDatetime
                                inputProps={{
                                    placeholder: "Fecha inicial",
                                }}
                                value={startDate}
                                dateFormat='DD/MM/YYYY'
                                timeFormat={false}
                                onChange={(e) =>
                                    handleReactDatetimeChange("startDate", e)
                                }
                                renderDay={(props, currentDate, selectedDate) => {
                                    let classes = props.className;
                                    classes +=
                                    getClassNameReactDatetimeDays(currentDate);
                                    return (
                                        <td {...props} className={classes}>
                                            {currentDate.date()}
                                        </td>
                                    );
                                }}
                            />
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col sm='4'>
                    <FormGroup>
                        <label className=" form-control-label">
                            Hasta:
                        </label>
                        <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="ni ni-calendar-grid-58" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <ReactDatetime
                                inputProps={{
                                    placeholder: "Fecha final",
                                }}
                                value={endDate}
                                dateFormat='DD/MM/YYYY'
                                timeFormat={false}
                                onChange={(e) =>
                                    handleReactDatetimeChange("endDate", e)
                                }
                                renderDay={(props, currentDate, selectedDate) => {
                                    let classes = props.className;
                                    classes +=
                                    getClassNameReactDatetimeDays(currentDate);
                                    return (
                                        <td {...props} className={classes}>
                                            {currentDate.date()}
                                        </td>
                                    );
                                }}
                            />
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col xs='12' sm='4'>
                    <Button
                        color="primary"
                    >
                        Aplicar
                    </Button>
                </Col>
            </Row>
        </Form>      
    )

}

export default RangeDatepickerForm;