import React, { useEffect, useContext, useState, useRef } from "react";
// Import reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Form,
    Input,
    Container,
    Row,
    Col,
    FormGroup,
    Collapse,
    ButtonGroup,
    Label,
    Modal,
    Table
} from "reactstrap";
// Additional components
// React plugin used to create dropdowns
import Select from "react-select"; // TODO: Improve dropdown for reagent selection
// React Sweet Alert plugin
import ReactBSAlert from "react-bootstrap-sweetalert";
// Core components
import SimpleHeader from "components/Headers/SimpleHeader";
// import RegisterTable from "components/Tables/RegisterTable";
import RegisterTableFull from "components/Tables/RegisterTableFull";
// Import context
import { CurrentUserContext } from "containers/App";
// Import utility functions
import { dateToISOFormat } from "utilities/datetime";
import {
    getReagentById, getAllReagents, 
    getAllReagentsUsages, addNewReagentUsage, getReagentUsageById, updateReagentUsageById, deleteReagentUsageById
} from "services/reagents";
import { getAllAreas } from "services/admin";
// Import additional data
import { unitsNamesMap } from "data/measurementUnits";

const tableColumnMap = {
    product_code: { tableHeading: 'Cod. producto' },
    amount_used: { tableHeading: 'Cantidad usada' },
    amount_used_units: { tableHeading: 'Unidades' },
    date_used: { tableHeading: 'Fecha de uso' },
    usage_notes: { tableHeading: 'Notas' },
    user: { tableHeading: 'Usuario' },
    reagent: { tableHeading: 'Reactivo usado' },
    log_timestamp: { tableHeading: 'Fecha y hora de registro' }
}    

// Define initial state for table filtering
const FILTER_INITIAL_STATE = {  
    usageStartDate: `${new Date().getFullYear()}-01-01`, 
    usageEndDate: dateToISOFormat(new Date()),
}

const ReagentsUsageLog = () => {
    // Load contexts
    const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
    // Declare references
    const addNewRef = useRef(null);
    // Declare states
    const [reagentUsageLogs, setReagentUsageLogs] = useState([]);
    const [modifiedReagentUsageLogs, setModifiedReagentUsageLogs] = useState([]);
    const [newUsageLog, setNewUsageLog] = useState({});
    const [newUsageAllowedUnits, setNewUsageAllowedUnits] = useState([]);
    const [columnKeys, setColumnKeys] = useState([]);
    // Complementary data
    const [areas, setAreas] = useState([]);
    const [reagents, setReagents] = useState([]);
    // Filter states
    const [filterCollapseOpen, setFilterCollapseOpen] = useState(false);
    const [filterState, setFilterState] = useState(FILTER_INITIAL_STATE);
    // Modal states
        // Delete Record Modal states
    const [modalDeleteRecord, setModalDeleteRecord] = useState(false);
    const [recordToDeleteData, setRecordToDeleteData] = useState({});
        // Edit Record Modal states
    const [modalEditRecord, setModalEditRecord] = useState(false);
    const [recordToEditData, setRecordToEditData] = useState({});    
    const [updatedData, setUpdatedData] = useState({});
    // Alert states
    const [alertContent, setAlertContent] = useState(null);
    
    useEffect(() => {
        // Fetch records from server at first render
        fetchReagentsUsageLog(filterState);
        // Fetch complementary data
        fetchAreas();
        fetchReagents();
    }, []);
    
    useEffect(() => {
        // Add a 'user' and 'reagent' property to each record
        const modifiedReagentUsageLogsList = reagentUsageLogs.map(usageLog => {
            return {
                ...usageLog, 
                user: `${usageLog.user.firstname} ${usageLog.user.lastname}`,
                reagent: `${usageLog.reagent.accounting_code} - ${usageLog.reagent.lab_code} | ${usageLog.reagent.brand} | ${usageLog.reagent.description}`
            }
        });
        setModifiedReagentUsageLogs(modifiedReagentUsageLogsList);

    }, [reagentUsageLogs]);

    useEffect(() => {
        // Set allowed units of measurement depending on reagent
        if (newUsageLog.hasOwnProperty('reagent_id')) {
            getReagentById(newUsageLog.reagent_id)
                .then(data => {
                    if (data.success) {
                        setNewUsageAllowedUnits(data.reagent.allowed_units);
                    }
                });
        }
    }, [newUsageLog.reagent_id]);

    // Utility functions
    const fetchReagentsUsageLog = (queryParameters) => {
        getAllReagentsUsages(queryParameters)
            .then(data => {
                if (data.success) {
                    setReagentUsageLogs(data.records);
                    // Get column keys that must be displayed in the table
                    let colKeys = data.table.column_keys;
                    setColumnKeys(colKeys);                    
                }
            });
    }
    // 
    const fetchAreas = () => {
        getAllAreas()
            .then(data => {
                if (data.success) {
                    setAreas(data.areas);
                }
            });
    }
    //
    const fetchReagents = () => {
        getAllReagents()
            .then(data => {
                if (data.success) {
                    setReagents(data.reagents);
                }
            });
    }

    // Handlers
    const handleInputsChange = (e) => {
        // Update state for new record
        if (e.target.name === 'reagent_id') {
            // Reset measuremt units value from state and form input
            setNewUsageLog(state => {
                const newState = {...state};
                delete newState['amount_used_units'];
                newState[e.target.name] = e.target.value;
                newState['user_id'] = currentUser.id;
                return newState;
            });
        } else {
            setNewUsageLog({
                ...newUsageLog, 
                [e.target.name]: e.target.value, 
                user_id: currentUser.id 
            });
        }
    }

    const handleClickAddNew = (e) => {
        e.preventDefault();
        addNewRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
    
    const handleSubmitNew = () => {
        // POST record to server
        addNewReagentUsage(newUsageLog)
            .then(data => {
                if (data.success) {
                    console.log(data.message);
                    // Alert user of successful action
                    setAlertContent(<SuccessAlert stateSetter={setAlertContent} />);
                    // Fetch records from server to update table
                    fetchReagentsUsageLog(filterState);                
                } else {
                    // Alert the user of unsuccessful action
                    setAlertContent(<WarningAlert stateSetter={setAlertContent} />);
                    console.log("Server:", data.message);
                }
            })
            .catch(error => {
                // Alert about the error
                setAlertContent(<DangerAlert stateSetter={setAlertContent} />);
            });
    }

    const handleFilterFormChange = (e) => {
        setFilterState({ ...filterState,  [e.target.name]: e.target.value});
    }

    const handleSubmitFilter = (e) => {
        e.preventDefault();
        // Fetch reagent usage with new filters
        fetchReagentsUsageLog(filterState);
        // Close filter
        handleFilterCollapseToggle()
    }

    const handleCleanFilter = (e) => {
        e.preventDefault();
        // Reset filter to initial state
        setFilterState(FILTER_INITIAL_STATE);
    }

    const handleSetFilterUsageDate = (button) => {
        if (button === 'today') {
            setFilterState({
                ...filterState,
                usageStartDate: dateToISOFormat(new Date()),
                usageEndDate: dateToISOFormat(new Date()),
            });
        } else if (button === 'month') {
            setFilterState({
                ...filterState,
                usageStartDate: dateToISOFormat(new Date(new Date().setDate(1))),
                usageEndDate: dateToISOFormat(new Date()),
            });
        } else if (button === 'year') {
            setFilterState({
                ...filterState, 
                usageStartDate: `${new Date().getFullYear()}-01-01`,
                usageEndDate: dateToISOFormat(new Date()),  
            })
        }
    }    

    const handleFilterCollapseToggle = () => {
        setFilterCollapseOpen(!filterCollapseOpen);
    }  
    
    // Handler to open 'delete' modal
    const handleOpenModalDelete = (e, id) => {
        e.preventDefault();
        // Fetch reagent data to show in modal
        getReagentUsageById(id)
            .then(data => {
                if (data.success) {
                    setRecordToDeleteData(data.usage_log);
                    // Activate modal 
                    setModalDeleteRecord(true);                    
                }
            })
            .catch(error => console.log)

    }
    // Handler to open 'edit' modal
    const handleOpenModalEdit = (e, id) => {
        e.preventDefault();
        // Empty updatedData state
        setUpdatedData({});
        // Fetch reagent data to show in modal
        getReagentUsageById(id)
            .then(data => {
                if (data.success) {
                    setRecordToEditData(data.usage_log);
                    // Activate modal 
                    setModalEditRecord(true);                          
                }
            })
            .catch(error => console.log)       
    }
    // Handler to delete record on server
    const handleDeleteRecord = () => {
        deleteReagentUsageById(recordToDeleteData.id)
            .then(data => {
                if (data.success) {
                    // Close modal
                    setModalDeleteRecord(false);
                    // Fetch reagents to update table
                    fetchReagentsUsageLog(filterState);
                    // Clean data state of deleted record
                    setRecordToDeleteData({});
                } else {
                    alert("No es posible eliminar el Reactivo");
                }
            })
            .catch(error => {
                alert("Ocurrió un error en el servidor.");
                console.log(error);
            });
    }
    // Handler to submit edited record's data
    const handleEditInputsChange = (e) => {
        // Update data that will replace the existing one
        setUpdatedData({
            ...updatedData, [e.target.name]: e.target.value
        });
    }

    const handleUpdateRecord = (e) => {
        // Request a modification to the server
        updateReagentUsageById(recordToEditData.id, updatedData)
            .then(data => {
                if (data.success) {
                    // Close modal
                    setModalEditRecord(false);
                    // Fetch reagents usage to update the table
                    fetchReagentsUsageLog(filterState);
                    // Clean data state of updated record
                    setRecordToEditData({});
                } else {
                    alert("No es posible modificar el Reactivo");
                }
            })
            .catch(error => {
                alert("Ocurrió un error en el servidor.");
                console.log(error);
            });
    }        
        
    // Customization variables
    const headerButtons = [
        { content: '+ Nuevo', handler: handleClickAddNew, className: 'btn-neutral', color: 'default' },
        { content: 'Filtros', handler: handleFilterCollapseToggle, className: 'btn-neutral', color: 'default' },
    ];
    // RegisterTable customization variables
        // Dropdown menu
    const dropdownMenu = {
        buttons: [
            { text: "Editar", handler: handleOpenModalEdit, property: 'id' },
        ]
    }    
        // Inline Actions
    const inlineActions = [
        { text: 'Editar', handler: handleOpenModalEdit, property: 'id', icon: 'fas fa-edit' },
        { text: 'Eliminar', handler: handleOpenModalDelete, property: 'id', icon: 'fas fa-trash' },
    ]        
    // Render component
    return (
        <>
            <SimpleHeader 
                name="Registro de Uso" 
                parentName="Reactivos"
                buttons={headerButtons}
            />
            <Container className="mt--6" fluid>
                {/* Render filters form */}
                <Collapse isOpen={filterCollapseOpen}>
                    <Card>
                        <CardBody>
                            <Form>
                                <h4>Fecha de Uso</h4>
                                <FormGroup >
                                    <ButtonGroup aria-label="Predeterminados" role="group">
                                        <Button
                                            outline color="default" type="button" onClick={() => handleSetFilterUsageDate('today')}
                                        >
                                            Hoy
                                        </Button>
                                        <Button
                                            outline color="default" type="button" onClick={() => handleSetFilterUsageDate('month')}
                                        >
                                            Este Mes
                                        </Button>
                                        <Button
                                            outline color="default" type="button" onClick={() => handleSetFilterUsageDate('year')}
                                        >
                                            Este Año
                                        </Button>
                                    </ButtonGroup>
                                </FormGroup>
                                <FormGroup className="row">
                                    <Label
                                        className="form-control-label"
                                        htmlFor="usage-filter-start-date"
                                        sm="2" lg='1'
                                    >
                                        Desde:
                                    </Label>
                                    <Col sm="6" lg='4'>
                                        <Input
                                            value={filterState.usageStartDate}
                                            min='2000-01-01'
                                            name="usageStartDate"
                                            id="usage-filter-start-date"
                                            type="date"
                                            onChange={handleFilterFormChange}
                                        />
                                    </Col>
                                </FormGroup>                                        
                                <FormGroup className="row"> 
                                    <Label
                                        className="form-control-label"
                                        htmlFor="usage-filter-end-date"
                                        sm="2" lg='1'
                                    >
                                        Hasta:
                                    </Label>
                                    <Col sm="6" lg='4'>
                                        <Input
                                            value={filterState.usageEndDate}
                                            min='2000-01-01'
                                            max={dateToISOFormat(new Date())}
                                            name="usageEndDate"
                                            id="usage-filter-end-date"
                                            type="date"
                                            onChange={handleFilterFormChange}
                                        />
                                    </Col>                                        
                                </FormGroup>
                                <hr/>
                                <h4>Usuario</h4>
                                <FormGroup>
                                    <Row>
                                        <Col xs='6' sm='3' lg='2'>
                                            <div className="custom-control custom-radio mb-3">
                                                <input
                                                    className="custom-control-input"
                                                    name="user"
                                                    id="checkboxUserMe"
                                                    type="radio"
                                                    value={currentUser.username}
                                                    onChange={handleFilterFormChange}
                                                    checked={filterState.user === currentUser.username}
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="checkboxUserMe"
                                                >
                                                    Yo ({currentUser.firstname})
                                                </label>
                                            </div>
                                        </Col>
                                        <Col xs='6' sm='3' lg='2'>
                                            <div className="custom-control custom-radio mb-3">
                                                <input
                                                    className="custom-control-input"
                                                    name="user"
                                                    id="checkboxUserAll"
                                                    type="radio"
                                                    value='all'
                                                    onChange={handleFilterFormChange}
                                                    checked={filterState.user === 'all'}
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="checkboxUserAll"
                                                >
                                                    Todos
                                                </label>
                                            </div>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <Button
                                    color="primary"
                                    type="button"
                                    onClick={handleSubmitFilter}
                                    >
                                    Aplicar
                                </Button>
                                <Button
                                    outline
                                    color="danger"
                                    type="button"
                                    onClick={handleCleanFilter}
                                    >
                                    <span className="btn-inner--icon mr-1">
                                        <i className="fas fa-trash" />
                                    </span>
                                    <span className="btn-inner--text">Limpiar</span>
                                </Button>
                                
                            </Form>
                        </CardBody>
                    </Card>
                </Collapse>                     
                {/* Render table to display all users */}
                <RegisterTableFull
                    columnKeys={columnKeys}
                    records={modifiedReagentUsageLogs}
                    tableMapping={tableColumnMap}
                    tableTitle="Uso de Reactivos"
                    inlineActions={inlineActions}
                    dropdownMenu={dropdownMenu}
                    // size='sm'
                />
                <DeleteReagentModal
                    modalDeleteRecord={modalDeleteRecord}
                    setModalDeleteRecord={setModalDeleteRecord}
                    recordData={recordToDeleteData}
                    onClickConfirm={handleDeleteRecord}                
                />
                <EditReagentModal
                    modalEditRecord={modalEditRecord}
                    setModalEditRecord={setModalEditRecord}
                    recordData={recordToEditData}
                    onClickConfirm={handleUpdateRecord}
                    onInputsChange={handleEditInputsChange}
                    reagentsData={reagents}
                />                
                {/* Render form for adding new users */}
                <Card className="mb-4">
                    <CardHeader>
                        <h3 ref={addNewRef}>Registrar Nuevo Uso de Reactivo</h3>
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <Row>
                                <Col sm='6'>
                                    <FormGroup>
                                        <Input 
                                            id="reagent_id" 
                                            placeholder=""
                                            type="select"
                                            name='reagent_id'
                                            onChange={handleInputsChange}
                                            defaultValue="Selecciona el reactivo"
                                        >   
                                            <option disabled>Selecciona el reactivo</option>
                                            {
                                                reagents.map(reagent => {
                                                    return(
                                                        <option value={reagent.id} key={reagent.id}>
                                                            {reagent.accounting_code} - {reagent.lab_code} | {reagent.brand} - {reagent.description.slice(0, 50)} | {reagent.format} {reagent.format_size}{reagent.format_size_unit}
                                                        </option>
                                                    );
                                                })
                                            }
                                        </Input>                                                                 
                                    </FormGroup>
                                </Col>                                    
                                <Col sm="6">
                                    <FormGroup>
                                        <Input
                                            id="product_code"
                                            placeholder="Codigo de producto"
                                            type="text"
                                            name='product_code'
                                            onChange={handleInputsChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <FormGroup>
                                        <Input
                                            id="amount_used"
                                            placeholder="Cantidad usada"
                                            type="number"
                                            name='amount_used'
                                            onChange={handleInputsChange}
                                        />
                                    </FormGroup>    
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Input
                                            id="amount_used_units"
                                            type="select"
                                            name='amount_used_units'
                                            onChange={handleInputsChange}
                                            value={newUsageLog.amount_used_units || ''}
                                            // defaultValue={''}
                                        >
                                            <option value='' disabled>Selecciona la unidad de medida</option>
                                            {
                                                newUsageAllowedUnits.map(unit => {
                                                    return (
                                                        <option key={`unit-${unit}`} value={unit}>
                                                            {unitsNamesMap[unit].shortName} - {unitsNamesMap[unit].name}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </Input>
                                    </FormGroup>    
                                </Col>
                            </Row>
                            <Row>     
                                <Col sm='6'>
                                    <FormGroup>
                                        <Input
                                            id="date_used"
                                            placeholder="Fecha de uso"
                                            type="text"
                                            name='date_used'
                                            onChange={handleInputsChange}
                                            onFocus={(e) => e.target.type='date'}
                                            onBlur={(e) => e.target.type='text'}
                                        />
                                    </FormGroup>
                                </Col>                                     
                                {/* <Col sm="6">
                                    <FormGroup>
                                        <Input 
                                            id="area_id" 
                                            placeholder="Area"
                                            type="select"
                                            name='area_id'
                                            onChange={handleFormChange}
                                            defaultValue="Selecciona el area"
                                        >   
                                            <option disabled>Selecciona el area</option>
                                            {
                                                areas.map(area => {
                                                    return(
                                                        <option value={area.id} key={area.id}>{area.name}</option>
                                                    );
                                                })
                                            }
                                        </Input>                                                                 
                                    </FormGroup>
                                </Col> */}
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="usage_notes"
                                        >
                                            Notas u observaciones
                                        </label>
                                        <Input
                                            id="usage_notes"
                                            rows="3"
                                            type="textarea"
                                            name="usage_notes"
                                            placeholder="Agrega una nota adicional"
                                            onChange={handleInputsChange}
                                        />
                                    </FormGroup>
                                </Col>                               
                            </Row>
                            <div className="text-left">
                                <Button
                                    color="primary"
                                    type="button"
                                    onClick={handleSubmitNew}
                                    >
                                    Registrar
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </Container>
            {alertContent}
        </>
    );
}


const DeleteReagentModal = ({
    modalDeleteRecord, setModalDeleteRecord,
    recordData,
    onClickConfirm: handleClickConfirm
}) => {

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={modalDeleteRecord}
            toggle={() => setModalDeleteRecord(false)}                
        >
            <div className="modal-header">
                <h3 className="modal-title">
                    ¿Borrar registro de uso de reactivo?
                </h3>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalDeleteRecord(false)}
                >
                    <span aria-hidden={true}>×</span>
                </button>                      
            </div>
            <div className="modal-body">
                <p>
                    Vas a eliminar el siguiente <strong>Uso de Reactivo</strong>:
                </p>
                <Table responsive>
                    <tbody>
                        <tr>
                            <th>Reactivo:</th>
                            <td>{recordData?.reagent?.accounting_code} - {recordData?.reagent?.lab_code} | {recordData?.reagent?.brand} | {recordData?.reagent?.description}</td>
                        </tr>
                        <tr>
                            <th>Usuario:</th>
                            <td>{recordData?.user?.firstname}</td>
                        </tr>
                        <tr>
                            <th>Cód. de producto:</th>
                            <td>{recordData?.product_code}</td>
                        </tr>
                        <tr>
                            <th>Fecha de uso: </th>
                            <td>{recordData?.date_used}</td>
                        </tr>
                        <tr>
                            <th>Cantidad usada: </th>
                            <td>{recordData?.amount_used} {recordData?.amount_used_units}</td>
                        </tr>
                        <tr>
                            <th>Notas: </th>
                            <td>{recordData?.usage_notes}</td>
                        </tr>
                    </tbody>
                </Table>                     
                <p className="mt-4">Esta acción es irreversible.</p>
            </div>
            <div className="modal-footer">
                <Button
                    // className="new-event--add"
                    color="danger"
                    type="button"
                    onClick={handleClickConfirm}
                >
                    Sí, eliminar
                </Button>
                <Button
                    className="ml-auto"
                    color="link"
                    type="button"
                    onClick={() => setModalDeleteRecord(false)}
                >
                    Cancelar
                </Button>
            </div>
        </Modal>           
    )
}


const EditReagentModal = ({
    modalEditRecord, setModalEditRecord,
    recordData,
    onClickConfirm: handleClickConfirm,
    onInputsChange: handleInputsChange,
    reagentsData: reagents
}) => {

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={modalEditRecord}
            toggle={() => setModalEditRecord(false)}
            size="lg"           
        >
            <div className="modal-header">
                <h3 className="modal-title">
                    Editar Datos de Uso de Reactivo
                </h3>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalEditRecord(false)}
                >
                    <span aria-hidden={true}>×</span>
                </button>                      
            </div>
            <div className="modal-body">
                <p>
                    Puedes modificar el siguiente <strong>Uso de Reactivo</strong>:
                </p>
                <ReagentUsageForm
                    onInputsChange={handleInputsChange}
                    data={recordData}
                    reagentsData={reagents}
                />             
                {/* <p className="mt-4">Verifica que los datos sean correctos.</p> */}
            </div>
            <div className="modal-footer">
                <Button
                    // className="new-event--add"
                    color="info"
                    type="button"
                    onClick={handleClickConfirm}
                >
                    Guardar
                </Button>
                <Button
                    className="ml-auto"
                    color="link"
                    type="button"
                    onClick={() => setModalEditRecord(false)}
                >
                    Cancelar
                </Button>
            </div>
        </Modal>           
    )
}


const ReagentUsageForm = ({
    onInputsChange: handleInputsChange,
    data: formData,
    reagentsData: reagents
}) => {
    const [allowedUnits, setAllowedUnits] = useState([]);

    useEffect(() => {
        if (formData.reagent_id){
            // Fetch reagent data when reagent_id changes
            getReagentById(formData.reagent_id)
                .then(data => {
                    if (data.success) {
                        setAllowedUnits(data.reagent.allowed_units);
                    }
                })
        }
    }, [formData.reagent_id])
    

    if (allowedUnits.length === 0) {
        return (
            <>
                Cargando formulario...
            </>
        )
    }

    return (
        <Form>
            <Row>
                <Col sm='6'>
                    <FormGroup>
                        <Input 
                            id="reagent_id" 
                            placeholder=""
                            type="select"
                            name='reagent_id'
                            onChange={handleInputsChange}
                            defaultValue={formData?.reagent_id}
                            disabled
                        >   
                            <option disabled>Selecciona el reactivo</option>
                            {
                                reagents.map(reagent => {
                                    return(
                                        <option value={reagent.id} key={reagent.id}>
                                            {reagent.accounting_code} - {reagent.lab_code} | {reagent.brand} | {reagent.description} | {reagent.format} {reagent.format_size}{reagent.format_size_unit}
                                        </option>
                                    );
                                })
                            }
                        </Input>                                                                 
                    </FormGroup>
                </Col>                                    
                <Col sm="6">
                    <FormGroup>
                        <Input
                            id="product_code"
                            placeholder="Codigo de producto"
                            type="text"
                            name='product_code'
                            defaultValue={formData?.product_code}
                            onChange={handleInputsChange}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm="6">
                    <FormGroup>
                        <Input
                            id="amount_used"
                            placeholder="Cantidad usada"
                            type="number"
                            name='amount_used'
                            defaultValue={formData?.amount_used}
                            onChange={handleInputsChange}
                        />
                    </FormGroup>    
                </Col>
                <Col sm="6">
                    <FormGroup>
                        <Input
                            id="amount_used_units"
                            type="select"
                            name='amount_used_units'
                            defaultValue={formData?.amount_used_units}
                            onChange={handleInputsChange}
                        >
                            <option disabled value=''>Selecciona la unidad de medida</option>
                            {   
                                allowedUnits.map(unit => {
                                    return (
                                        <option 
                                            key={`editUnit-${unit}`} 
                                            value={unit} 
                                        >
                                            {unitsNamesMap[unit].shortName} - {unitsNamesMap[unit].name}
                                        </option>
                                    )
                                })
                            }
                        </Input>
                    </FormGroup>    
                </Col>
            </Row>
            <Row>     
                <Col sm='6'>
                    <FormGroup>
                        <Input
                            id="date_used"
                            placeholder="Fecha de uso"
                            type="text"
                            name='date_used'
                            defaultValue={formData?.date_used}
                            onChange={handleInputsChange}
                            onFocus={(e) => e.target.type='date'}
                            onBlur={(e) => e.target.type='text'}
                        />
                    </FormGroup>
                </Col>                                     
                {/* <Col sm="6">
                    <FormGroup>
                        <Input 
                            id="area_id" 
                            placeholder="Area"
                            type="select"
                            name='area_id'
                            onChange={handleInputsChange}
                            defaultValue="Selecciona el area"
                        >   
                            <option disabled>Selecciona el area</option>
                            {
                                areas.map(area => {
                                    return(
                                        <option value={area.id} key={area.id}>{area.name}</option>
                                    );
                                })
                            }
                        </Input>                                                                 
                    </FormGroup>
                </Col> */}
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="usage_notes"
                        >
                            Notas u observaciones
                        </label>
                        <Input
                            id="usage_notes"
                            rows="3"
                            type="textarea"
                            name="usage_notes"
                            placeholder="Agrega una nota adicional"
                            defaultValue={formData.usage_notes}
                            onChange={handleInputsChange}
                        />
                    </FormGroup>
                </Col>                               
            </Row>
        </Form>     
    )
}


const SuccessAlert = ({
    stateSetter: setAlert
}) => {
    return (
        <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Acción exitosa"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="success"
            // confirmBtnText="Ok"
            btnSize=""
            // timeout={2500}
        >
            Uso de reactivo registrado satisfactoriamente.
        </ReactBSAlert>
    );
}

const DangerAlert = ({
    stateSetter: setAlert
}) => {
    return (
        <ReactBSAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title="Ocurrió un error"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="danger"
            // confirmBtnText="Ok"
            btnSize=""
        >
            Ocurrió un error en el servidor.
        </ReactBSAlert>
    );
}

const WarningAlert = ({
    stateSetter: setAlert
}) => {
    return (
        <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Advertencia"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="warning"
            // confirmBtnText="Ok"
            btnSize=""
        >
            No se pudo registrar el uso del reactivo.
        </ReactBSAlert>
    );
}

export default ReagentsUsageLog;