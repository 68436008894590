import React, { useEffect, useState } from 'react';
// Import reactstrap components
import {
    Card, CardHeader, CardBody,
    Col,
    UncontrolledTooltip, UncontrolledDropdown,
    DropdownMenu, DropdownItem, DropdownToggle
} from 'reactstrap';
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

const RegisterTableFull = ({
    records,
    columnKeys,
    tableMapping,
    tableTitle,
    inlineActions = [],
    dropdownMenu = { buttons: [] }
}) => {

    const [columnsData, setColumnsData] = useState([]);
    const [recordsData, setRecordsData] = useState([]);

    useEffect(() => {
        if (columnKeys.length > 0) {
            let columns = columnKeys;
            // Append dropdown menu column
            if (dropdownMenu.buttons.length > 0) {
                columns = ['dropdownMenu'].concat(columns);
            }
            // Append inline action column to the columns list
            if (inlineActions.length > 0) {
                columns = columns.concat('inlineActions');
            }
            setColumnsData(columns.map(field => {
                if (field === 'inlineActions' || field === 'dropdownMenu') {
                    return {
                        dataField: field,
                        text: '',
                        sort: false
                    }
                }

                return {
                    dataField: field,
                    text: tableMapping[field]?.tableHeading,
                    sort: true
                }
            }));
        }
    }, [columnKeys]);

    useEffect(() => {
        setRecordsData(records.map(record => {
            let newRecord = { ...record }
            // Add inline actions to records
            newRecord['inlineActions'] = renderInlineActions(record);
            // Add dropdown menu to records
            newRecord['dropdownMenu'] = renderDropdownMenu(record);
            return newRecord;
        }))
    }, [records]);


    const renderInlineActions = (record) => {
        return (
            <>
                {
                    inlineActions.map((action, i) => {
                        return (
                            <span key={`trow${record.id}-action${i}`}>
                                <a
                                    className="table-action"
                                    type="button"
                                    id={`${action.text}-tooltip${record.id}`}
                                    onClick={(e) => action.handler(e, record[action.property])}
                                >
                                    <i className={action.icon} />
                                </a>
                                <UncontrolledTooltip delay={0} target={`${action.text}-tooltip${record.id}`}>
                                    {action.text}
                                </UncontrolledTooltip>
                            </span>  
                        )
                    })
                }
            </>
        );        
    }

    const renderDropdownMenu = (record) => {
        return (
            <UncontrolledDropdown>
                <DropdownToggle
                    className="btn-icon-only text-light"
                    color=""
                    role="button"
                    size="sm"
                >
                <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                    {
                        dropdownMenu.buttons.map(button => 
                            <DropdownItem
                                onClick={(e) => button.handler(e, record[button.property])}
                                key={`${button.text}`}
                            >
                                {button.text}
                            </DropdownItem>    
                        )
                    }
                </DropdownMenu>
            </UncontrolledDropdown>                                            
        )
    }

    // Define pagination functionality
    const pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Mostrar{" "}
                    {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value={records.length}>Todo</option>
                    </select>
                    }{" "}
                    filas.
                </label>
            </div>
        ),
        paginationTotalRenderer: (from, to, size) => (
            <div className="react-bootstrap-table-pagination-total pl-4">
                {' '}Mostrando { from } a { to } de { size } resultados.
            </div>            
        )
    });

    const { SearchBar } = Search;
    if (columnsData.length == 0) {
        return null;
    }

    return (
        <>
            <Card className='mb-4'>
                <CardHeader className='border-0'>
                    <Col xs="6">
                        <h3 className="mb-0">{tableTitle}</h3>
                    </Col>
                </CardHeader>
                <CardBody>
                    <ToolkitProvider
                        data={recordsData}
                        keyField='id'
                        columns={columnsData}
                        search
                    >
                        {(props) => (
                            <div className="table-responsive">
                                <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb-1"
                                >
                                    <label>
                                        Buscar:
                                        <SearchBar
                                            className="form-control-sm"
                                            placeholder=" "
                                            {...props.searchProps}
                                        />
                                    </label>
                                </div>
                                <BootstrapTable
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    pagination={pagination}
                                    bordered={false}
                                    classes='align-items-center'
                                    condensed
                                    hover
                                />
                            </div>
                        )}
                    </ToolkitProvider>
                </CardBody>
            </Card>
        </>
    )
}

export default RegisterTableFull;