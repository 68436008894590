import { forwardRef, useEffect, useState } from "react";
// Import reactstrap components
import {
    Table,
    Card, CardHeader, CardBody, 
} from "reactstrap"
// Import services
import { getAllMeasurementUnits } from "services/reagents";
// Import custom css styles
import 'components/SunatFormats/Format13Dot1.css';

const operationTypeSunatTable12 = {
    'Ingreso': '02',
    'Consumo': '99 - Consumo',
    'Saldo': '16',
}
const unitCodeSunatTable6 = {
    'Kg': {
        code: '01',
        description: 'KILOS'
    } ,
    'L': {
        code: '08',
        description: 'LITROS'
    } ,
    'unit': {
        code: '07',
        description: 'UNIDADES'
    } 
}

const SunatFormat13Dot1 = ({ reagent, accountingRecords, year, accountingSummary }, printRef) => {

    const [measurementUnitsData, setMeasurementsUnitsData] = useState({});

    useEffect(() => {
        getAllMeasurementUnits()
            .then(data => {
                if (data.success) {
                    setMeasurementsUnitsData(data.units);
                } else {
                    console.log(data.message);
                }
            })
    }, []);

    // Utility functions
    const getBaseUnits = (units) => {
        return measurementUnitsData[units]?.base_unit;
    }

    return (
        <div ref={printRef}>
            <Card className="mb-4">
                <CardHeader>
                    <h5>
                        FORMATO 13.1: "REGISTRO DE INVENTARIO PERMANENTE VALORIZADO - DETALLE DEL INVENTARIO VALORIZADO"
                    </h5>
                </CardHeader>
                <CardBody>
                    <Table
                        className="align-items-center mb-4"
                        size="sm"
                        borderless
                        responsive
                    >
                        <tbody>
                            <tr>
                                <th scope="row">PERÍODO:</th>
                                <td className="font-weight-bold">{year}</td>
                            </tr>
                            <tr>
                                <th scope="row">RUC:</th>
                                <td>20385909528</td>
                            </tr>
                            <tr>
                                <th scope="row">RAZÓN SOCIAL:</th>
                                <td>HYPATIA S.A.</td>
                            </tr>
                            <tr>
                                <th scope="row">ESTABLECIMIENTO:</th>
                                <td>Av. Sanchez Carrión 410 - Magdalena del Mar</td>
                                <th scope="row">CÓDIGO DEL PROVEEDOR:</th>
                                <td>{reagent.brand} {reagent.brand_code}</td>
                            </tr>
                            <tr>
                                <th scope="row">CÓDIGO DE LA EXISTENCIA:</th>
                                <td>
                                    <span className="p-1 px-3 border">{reagent.accounting_code}</span>
                                    <span className="p-1 px-3 border">{reagent.lab_code}</span>
                                </td>
                                <th scope="row">PRESENTACIÓN:</th>
                                <td>{reagent.format} {reagent.format_size} {reagent.format_size_unit}</td>
                            </tr>
                            <tr>
                                <th scope="row">TIPO (TABLA 5):</th>
                                <td>05 - SUMINISTROS DIVERSOS</td>
                            </tr>
                            <tr>
                                <th scope="row">DESCRIPCIÓN:</th>
                                <td className="font-weight-bold">{reagent.description}</td>
                            </tr>
                            <tr>
                                <th scope="row">CÓD. DE UNIDAD DE MEDIDA:</th>
                                <td>
                                    {unitCodeSunatTable6[getBaseUnits(reagent.format_size_unit)]?.code}
                                    {' - '}
                                    {unitCodeSunatTable6[getBaseUnits(reagent.format_size_unit)]?.description}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">MÉTODO DE VALUACIÓN:</th>
                                <td>PROMEDIO</td>
                            </tr>
            
                        </tbody>
                    </Table>
                    <Table
                        className="align-items-center"
                        id="format13dot1-table"
                        size="sm"
                        responsive
                        hover
                        bordered
                    >
                        <thead className="thead-light" style={{textAlign: 'center'}}>
                            <tr>
                                <th colSpan={4} rowSpan={2}>
                                    DOCUM. DE TRASLADO, COMPROB. DE PAGO, <br/>
                                    DOCUMENTO INTERNO O SIMILAR
                                </th>
                                <th rowSpan={3}>TIPO DE <br/> OPERACION <br/> (TABLA 12)</th>
                                <th colSpan={3}>ENTRADAS</th>
                                <th colSpan={3}>SALIDAS</th>
                                <th colSpan={3}>SALDO FINAL</th>
                            </tr>
                            <tr>
                                <th rowSpan={2}>CANTIDAD</th>
                                <th rowSpan={2}>COSTO<br/> UNITARIO</th>
                                <th rowSpan={2}>COSTO<br/> TOTAL</th>
                                <th rowSpan={2}>CANTIDAD</th>
                                <th rowSpan={2}>COSTO<br/> UNITARIO</th>
                                <th rowSpan={2}>COSTO<br/> TOTAL</th>
                                <th rowSpan={2}>CANTIDAD</th>
                                <th rowSpan={2}>COSTO<br/> UNITARIO</th>
                                <th rowSpan={2}>COSTO<br/> TOTAL</th>
                            </tr>
                            <tr>
                                <th>Fecha</th>
                                <th>Tipo</th>
                                <th>Serie</th>
                                <th>Numero</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                accountingRecords.map(record => {
                                    if (record.operation_type === "Saldo") {
                                        return (
                                            <tr>
                                                <td className="bg-default text-white text-white-print">Saldo {year - 1}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>{operationTypeSunatTable12[record.operation_type]}</td>
                                                {/* Entries */}
                                                <td>{record.amount.toFixed(3)}</td>
                                                <td>{record.entry_unit_cost.toFixed(2)}</td>
                                                <td>{record.entry_total_cost.toFixed(2)}</td>
                                                {/* Usage/expenses */}
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                {/* Balance */}
                                                <td>{record.balance.toFixed(3)}</td>
                                                <td>{record.unit_cost.toFixed(2)}</td>
                                                <td>{(record.unit_cost * record.balance).toFixed(2)}</td>
                                            </tr>
                                        )
                                    } else if (record.operation_type === "Ingreso") {
                                        return (
                                            <tr>
                                                <td>{record.date}</td>
                                                <td>{1}</td>
                                                <td>{record.invoice ? record.invoice.split('-')[0] : null}</td>
                                                <td>{record.invoice ? record.invoice.split('-')[1] : null}</td>
                                                <td>{operationTypeSunatTable12[record.operation_type]}</td>
                                                {/* Entries */}
                                                <td>{record.amount.toFixed(3)}</td>
                                                <td>{record.entry_unit_cost.toFixed(2)}</td>
                                                <td>{record.entry_total_cost.toFixed(2)}</td>
                                                {/* Usage/expenses */}
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                {/* Balance */}
                                                <td>{record.balance.toFixed(3)}</td>
                                                <td>{record.unit_cost.toFixed(2)}</td>
                                                <td>{(record.unit_cost * record.balance).toFixed(2)}</td>
                                            </tr>
                                        )
                                    } else if (record.operation_type === "Consumo"){
                                        return (
                                            <tr>
                                                <td>{record.date}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>{operationTypeSunatTable12[record.operation_type]}</td>
                                                {/* Entries */}
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                {/* Usage/expenses */}
                                                <td>{record.amount.toFixed(3)}</td>
                                                <td>{record.unit_cost.toFixed(2)}</td>
                                                <td>{(record.amount * record.unit_cost).toFixed(2)}</td>
                                                {/* Balance */}
                                                <td>{record.balance.toFixed(3)}</td>
                                                <td>{record.unit_cost.toFixed(2)}</td>
                                                <td>{(record.unit_cost * record.balance).toFixed(2)}</td>
                                            </tr>
                                        )
                                    } else if (record.operation_type === "Balance Inicial") {
                                        return (
                                            <tr>
                                                <td className="bg-default text-white">Balance Inicial</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                {/* Entries */}
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                {/* Usage/expenses */}
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                {/* Balance */}
                                                <td>{record.balance.toFixed(3)}</td>
                                                <td>{record.unit_cost.toFixed(2)}</td>
                                                <td>{(record.unit_cost * record.balance).toFixed(2)}</td>
                                            </tr>
                                        )
                                    } else {
                                        return (
                                            <tr>
                                                <td>{record.date}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>-</td>
                                                {/* Entries */}
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                {/* Usage/expenses */}
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                {/* Balance */}
                                                <td>{record.balance.toFixed(3)}</td>
                                                <td>{record.unit_cost.toFixed(2)}</td>
                                                <td>{(record.unit_cost * record.balance).toFixed(2)}</td>                                                
                                            </tr>
                                        )
                                    }
                                })
                            }
                            <tr className="font-weight-bold">
                                <td colSpan={4}></td>
                                <th style={{fontSize: '0.7rem', letterSpacing: '1px', textAlign: 'center'}}>TOTALES</th>
                                {/* Total entries (carryover + new entries) */}
                                <td>{(accountingSummary.inflow_amount + accountingSummary.carryover_stock_amount).toFixed(3)}</td>
                                <td className="bg-lighter"></td>
                                <td>{(accountingSummary.inflow_total_cost + accountingSummary.carryover_stock_total_cost).toFixed(2)}</td>
                                {/* Usage */}
                                <td>{(accountingSummary.outflow_amount).toFixed(3)}</td>
                                <td className="bg-lighter"></td>
                                <td>{accountingSummary.outflow_total_cost.toFixed(2)}</td>
                                <td colSpan={3} className="bg-lighter"></td>
                            </tr>
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </div>
    );
}
// Wraps functional component with fordwardRef to be able to pass ref from children
export default forwardRef((props, ref) => SunatFormat13Dot1(props, ref));   