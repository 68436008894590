import React, { useEffect, useContext, useState } from "react";
import { useParams, Redirect, useHistory } from "react-router-dom";
import classNames from "classnames";
// Import reactstrap components
import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardBody,
    Table,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
    FormGroup,
    Modal,
} from "reactstrap";

// Import core components
import SimpleHeader from "components/Headers/SimpleHeader";
import RangeDatepickerForm from "components/Datepickers/RangeDatepickerForm";
// Import Contexts
import { ServerContext } from "containers/App";


const reagentHistoryTableMapping = {
    date: { tableHeading: 'Fecha' },
    operation_type: { tableHeading: 'Operación' },
    amount: { tableHeading: 'Cantidad' },
    balance: { tableHeading: 'Saldo' },
}  


const ReagentPage = () => {
    // Load url parameter
    const { accounting_code } = useParams();
    // Load contexts
    const serverUrl = useContext(ServerContext);
    // React-router states
    let history = useHistory();

    // Declare reagent data-related states
    const [reagent, setReagent] = useState(null);
    const [reagentHistory, setReagentHistory] = useState([]);
    // Declare table states
    const [columnKeys, setColumnKeys] = useState([]);
    // Declare date states for filtering historical data
    const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10));
    const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));

    // Fetch reagent from server at first render
    useEffect(() => {
        fetchReagentByAccountingCode(accounting_code);
    }, []);
    // Fetch reagent history after loading reagent data
    useEffect(() => {
        if (reagent) {
            fetchReagentHistoryById(reagent.id, startDate, endDate);
        }
    }, [reagent, endDate, startDate]);

    // Utility functions
    const fetchReagentByAccountingCode = (accounting_code) => {
        // GET request
        let requestOptions = {
            method: 'GET',
            credentials: 'include',
        }
        fetch(`${serverUrl}/reagent/ac-${accounting_code}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setReagent(data.reagent);
                } else {
                    console.log(data.message);
                    // Redirect user to reagents page
                    history.replace('/reagents/register');
                }
            })
            .catch(error => alert('Ocurrió un error en el servidor.'));
    }

    const fetchReagentHistoryById = (id, startDate, endDate) => {
        // GET request
        let requestOptions = {
            method: 'GET',
            credentials: 'include',
        }
        let queryParams = new URLSearchParams({ startDate, endDate });
        fetch(`${serverUrl}/reagent/${id}/history?${queryParams}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setReagentHistory(data.history);
                    setColumnKeys(data.table.column_keys)
                } else {
                    console.log(data.message);
                }
            })
            .catch(error => alert('Ocurrió un error en el servidor.'));
    }

    const handleSubmitRangeDate = (e, startDate, endDate) => {
        e.preventDefault();
        // Convert Momentum dates into ISO string format without time
        console.log(startDate, endDate);
        // TODO: Add validation when startDate and endDate are 'null' or 'anonymous'
        const startDateJS = new Date(startDate);
        const endDateJS = new Date(endDate);
        // Trigger fetching of historical data for the picked date range
        const startDateIso = startDateJS.toISOString().slice(0, 10);
        const endDateIso = endDateJS.toISOString().slice(0, 10);
        setStartDate(startDateIso);
        setEndDate(endDateIso);
    }
    // Load page if reagent is already fetched
    if (reagent) {
        // Header customization variables
        const headerButtons = [
            // { className: 'btn-neutral', color: 'default', content: 'Nuevo', link: '#add-new' },
        ];
        return(
            <>
                <SimpleHeader 
                    name={`Reactivo ${accounting_code}`}
                    parentName="Reactivos"
                    buttons={headerButtons}
                />
                <Container className="mt--6" fluid>
                    <Card className="mb-4">
                        <CardHeader>
                            <h3> Detalles de Reactivo {reagent.accounting_code}</h3>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <ul>
                                        <li>Código contable: {reagent.accounting_code}</li>
                                        <li>Código de laboratorio: {reagent.lab_code}</li>
                                        <li>Descripción: {reagent.description}</li>
                                    </ul>
                                </Col>
                                <Col>
                                    <ul>
                                        <li>Marca: {reagent.brand}</li>
                                        <li>Presentación: {reagent.format} de {reagent.format_size} {reagent.format_size_unit}</li>
                                    </ul>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card className="mb-4">
                        <CardHeader>
                            <h3>Stock disponible</h3>
                        </CardHeader>
                        <CardBody>
                            <p>En inventario: {reagent.stock} {reagent.format} de {reagent.format_size} {reagent.format_size_unit}</p>
                            <p>Pedidos en camino: </p>
                        </CardBody>
                    </Card>
                    <Card className="mb-4">
                        <CardHeader className="border-0">
                            <h3>Historial - Ingresos y Consumos</h3>
                            <RangeDatepickerForm 
                                handleOnSubmit={handleSubmitRangeDate}
                            />
                        </CardHeader>
                        <CardBody>
                            <Table 
                                className="align-items-center table-flush" 
                                responsive 
                                hover 
                                // size={size}
                            >
                                <thead className="thead-light">
                                    <tr>
                                        {
                                            columnKeys.map(column => {
                                                // Return the table header for each column
                                                return(
                                                    <th key={`th${column}`}>
                                                        { Object.keys(reagentHistoryTableMapping).includes(column) ? reagentHistoryTableMapping[column].tableHeading : column }
                                                    </th>
                                                )
                                            })
                                        }
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        reagentHistory.map(record => 
                                            <tr>
                                                {
                                                    columnKeys.map(column => {
                                                        if (column === 'operation_type') {
                                                            let badgeIconClass = classNames({
                                                                'bg-success': record[column] === 'Ingreso',
                                                                'bg-warning': record[column] === 'Consumo',
                                                            });
                                                            return (
                                                                <td>
                                                                    <Badge color="" className="badge-dot mr-4">
                                                                        <i className={badgeIconClass} />
                                                                        <span className="status">{record[column]}</span>
                                                                    </Badge>                                                                            
                                                                </td>                                                                
                                                            )
                                                        } else {
                                                            return (
                                                                <td>
                                                                    {record[column]}
                                                                </td>                                                                
                                                            )
                                                        }
                                                    })
                                                }
                                            </tr>
                                        )
                                    }
                                </tbody>                              
                            </Table>                            
                        </CardBody>
                    </Card>
                </Container>
            </>
        )
    }
}

export default ReagentPage;