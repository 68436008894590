import React, { useContext } from "react";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardImg,
    CardTitle,
    FormGroup,
    Form,
    Input,
    ListGroupItem,
    ListGroup,
    Progress,
    Container,
    Row,
    Col,
  } from "reactstrap";

// Import components
import ProfileHeader from "components/Headers/ProfileHeader";
// Import contexts
import { ServerContext, CurrentUserContext } from "containers/App";
// Import assets
import blankProfilePic from 'assets/img/theme/blank-profile-picture.jpg'

const UserProfile = () => {
    const { currentUser, setCurrentUser } = useContext(CurrentUserContext);

    const profileAvatar = currentUser.profile_img_url || blankProfilePic

    return(
        <>
            <ProfileHeader currentUser={currentUser} />
            <Container className="mt--6" fluid >
                <Row>
                    <Col className="order-xl-2" xl='4' >
                        <Card className="card-profile">
                            <CardImg
                                alt="..."
                                src={require("assets/img/theme/img-1-1000x600.jpg")}
                                top
                            />
                            <Row className="justify-content-center">
                                <Col className="order-lg-2" lg="3">
                                <div className="card-profile-image">
                                    <a href="#" onClick={(e) => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        className="rounded-circle"
                                        src={profileAvatar}
                                    />
                                    </a>
                                </div>
                                </Col>
                            </Row>
                            <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                                <div className="d-flex justify-content-between">
                                <Button
                                    className="mr-4"
                                    color="info"
                                    href="#"
                                    onClick={(e) => e.preventDefault()}
                                    size="sm"
                                >
                                    Connect
                                </Button>
                                <Button
                                    className="float-right"
                                    color="default"
                                    href="#"
                                    onClick={(e) => e.preventDefault()}
                                    size="sm"
                                >
                                    Mensaje
                                </Button>
                                </div>
                            </CardHeader>
                            <CardBody className="pt-0">
                                <Row>
                                    {/* <div className="col">
                                        <div className="card-profile-stats d-flex justify-content-center">
                                        <div>
                                            <span className="heading">22</span>
                                            <span className="description">Friends</span>
                                        </div>
                                        <div>
                                            <span className="heading">10</span>
                                            <span className="description">Photos</span>
                                        </div>
                                        <div>
                                            <span className="heading">89</span>
                                            <span className="description">Comments</span>
                                        </div>
                                        </div>
                                    </div> */}
                                </Row>
                                <div className="text-center">
                                    <h5 className="h3">
                                        {currentUser.firstname} {currentUser.lastname}
                                        {/* <span className="font-weight-light">, 27</span> */}
                                    </h5>
                                    {/* <div className="h5 font-weight-300">
                                        <i className="ni location_pin mr-2" />
                                        Bucharest, Romania
                                    </div> */}
                                    <div className="h5 mt-4">
                                        <i className="ni business_briefcase-24 mr-2" />
                                        {currentUser.position} en {currentUser.area_id}
                                    </div>
                                    {/* <div>
                                        <i className="ni education_hat mr-2" />
                                        Ingeniero Mecatrónico
                                    </div> */}
                                </div>
                            </CardBody>
                        </Card>                        
                    </Col>
                    <Col className="order-xl-1" xl="8" >
                        <Card>
                        <CardHeader>
                            <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">Edit profile</h3>
                            </Col>
                            <Col className="text-right" xs="4">
                                <Button
                                color="primary"
                                href="#hypatia"
                                onClick={(e) => e.preventDefault()}
                                size="sm"
                                >
                                    Ajustes
                                </Button>
                            </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Form>
                            <h6 className="heading-small text-muted mb-4">
                                Información de usuario
                            </h6>
                            <div className="pl-lg-4">
                                <Row>
                                <Col lg="6">
                                    <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-username"
                                    >
                                        Nombre de usuario
                                    </label>
                                    <Input
                                        defaultValue={currentUser.username}
                                        id="input-username"
                                        placeholder="Username"
                                        type="text"
                                    />
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-email"
                                    >
                                        E-mail
                                    </label>
                                    <Input
                                        defaultValue={currentUser.email}
                                        id="email"
                                        placeholder="Ingresa un email"
                                        type="email"
                                    />
                                    </FormGroup>
                                </Col>
                                </Row>
                                <Row>
                                <Col lg="6">
                                    <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="firstname"
                                    >
                                        Nombre(s)
                                    </label>
                                    <Input
                                        defaultValue={currentUser.firstname}
                                        id="firstname"
                                        placeholder="Nombre(s)"
                                        type="text"
                                    />
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="lastname"
                                    >
                                        Apellido(s)
                                    </label>
                                    <Input
                                        defaultValue={currentUser.lastname}
                                        id="lastname"
                                        placeholder="Apellido(s)"
                                        type="text"
                                    />
                                    </FormGroup>
                                </Col>
                                </Row>
                            </div>
                            <hr className="my-4" />

                            <h6 className="heading-small text-muted mb-4">
                                Información de Contacto
                            </h6>
                            <div className="pl-lg-4">
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-city"
                                        >
                                            Celular
                                        </label>
                                        <Input
                                            defaultValue={currentUser.contact_phone}
                                            id="contact_phone"
                                            placeholder="Ingresa tu número"
                                            type="text"
                                        />
                                        </FormGroup>
                                    </Col>
                                </Row>                                
                                <Row>
                                    <Col md="12">
                                        <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-address"
                                        >
                                            Dirección
                                        </label>
                                        <Input
                                            defaultValue={currentUser.contact_address}
                                            id="contact_address"
                                            placeholder="Ingresa tu dirección"
                                            type="text"
                                        />
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </div>
                            <hr className="my-4" />

                            <h6 className="heading-small text-muted mb-4">Información Adicional</h6>
                            <div className="pl-lg-4">
                                <FormGroup>
                                <label className="form-control-label">Sobre mi</label>
                                <Input
                                    placeholder="Escribe algo sobre ti..."
                                    rows="4"
                                    type="textarea"
                                />
                                </FormGroup>
                            </div>
                            </Form>
                        </CardBody>
                        </Card>
                    </Col>                    
                </Row>
            </Container>
        </>
    )
}

export default UserProfile;

