import { createContext, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// Plugins styles from node_modules
// import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// import "@fullcalendar/common/main.min.css";
// import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// Plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
// Argon Dashboard Pro core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.3";
// Import custom css styles
import 'containers/App.css';
// Layouts
import DashboardLayout from 'layouts/Dashboard';
import AuthLayout from 'layouts/Auth';
import PrivateRoute from "components/Routes/PrivateRoute";

// Load server url depending on environment
const SERVER_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SERVER_URL : 'http://127.0.0.1:5000/'
export const ServerContext = createContext(null);
export const CurrentUserContext = createContext(null);

function App() {
    const [currentUser, setCurrentUser] = useState(null)

    return (
        <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }}>
            <ServerContext.Provider value={SERVER_URL} >
                <BrowserRouter>
                    <Switch>
                        <Route path="/auth" render={(routeProps) => <AuthLayout {...routeProps } />} />
                        <PrivateRoute path="/">
                            <DashboardLayout/>
                        </PrivateRoute>
                        <Redirect from="*" to="/" />
                    </Switch>
                </BrowserRouter>
            </ServerContext.Provider>
        </CurrentUserContext.Provider>
    );
}

export default App;
