/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom"
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";

function SimpleHeader({ name, parentName, buttons }) {
  return (
    <>
      <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6">
                <h6 className="h2 text-white d-inline-block mb-0">
                  {name}
                </h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-lg-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <Link to='/dashboard'>
                      <i className="fas fa-home" />
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col className="mt-3 mt-md-0 text-md-right" lg="6">
                { 
                  // Render header buttons
                  buttons.map((button, i) => {
                    return(
                      <Button 
                        key={`button${i}`}
                        className={button.className} 
                        color={button.color}
                        size="sm"
                        onClick={button.handler}
                        id={button.id}
                      >
                        {button.content}
                      </Button>
                    );
                  })
                }
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

SimpleHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  buttons: PropTypes.array
};

// Default prop values
SimpleHeader.defaultProps = {
  buttons: []
}

export default SimpleHeader;
